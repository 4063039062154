import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import store from "../../redux/store";
import {USER_CLOSE_ALERT} from "../../redux/type";
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import {getPod} from "../../redux/actions/userAction";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


class GetPod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            supplier: 'FEDEX',
            pod_file: null,
            TrackingNumbers: '',
            warehouse: ''
        }
    }
    Close = () => {
        this.setState({open: false})
    }
    Open = () => {
        this.setState({open: true})
    }
    handleSubmit = e => {
        e.preventDefault()
        let TrackingNumbers = this.state.TrackingNumbers.split("\n")
        TrackingNumbers = TrackingNumbers.filter(item => item !== "");
        // const data = new FormData(e.currentTarget);
        this.props.getPod(this.state.supplier, TrackingNumbers)
        this.setState({open: false})
    }
    closeAlert = () => {
        store.dispatch({type: USER_CLOSE_ALERT})
    }
    ChangeFile = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            this.setState({pod_file: event.target.result})
        };
    }
    render() {
        return (
            <span>
                <Button sx={{textTransform: 'none'}} startIcon={<ChromeReaderModeIcon/>} onClick={() => {
                    this.Open()
                }}>获取POD</Button>
                <Dialog disableEscapeKeyDown open={this.state.open} onClose={() => {
                    this.Close()
                }}>
                    <DialogTitle>获取POD</DialogTitle>
                    <DialogContent>
                        <Box component="form" onSubmit={this.handleSubmit}>
                            <FormControl sx={{m: 1, minWidth: 500}}>
                                <InputLabel id="supplier">渠道商</InputLabel>
                                <Select
                                    labelId="supplier"
                                    id="select_ser"
                                    value={this.state.supplier}
                                    onChange={(e) => {
                                        this.setState({supplier: e.target.value})
                                    }}
                                    label="supplier"
                                >
                                    <MenuItem key={1} value={'FEDEX'}>FEDEX</MenuItem>
                                    <MenuItem key={2} value={'UPS'}>UPS</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{m: 1, minWidth: 500}}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    name="TrackingNumbers"
                                    label="TrackingNumbers"
                                    multiline
                                    onChange={(e) => {
                                        this.setState({TrackingNumbers: e.target.value})
                                    }}
                                    value={this.state.TrackingNumbers}
                                    maxRows={5}
                                    rows={5}

                                />
                            </FormControl>
                            {/*<FormControl sx={{m: 1, minWidth: 500, minHeight: 300}}>*/}
                            {/*    <InputLabel id="Warehouse">Fedex仓库(系统单不需要选择)</InputLabel>*/}
                            {/*    <Select*/}
                            {/*        labelId="Warehouse"*/}
                            {/*        id="warehouse"*/}
                            {/*        value={this.state.warehouse}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            this.setState({warehouse: e.target.value})*/}
                            {/*        }}*/}
                            {/*        label="warehouse"*/}
                            {/*    >*/}
                            {/*        <MenuItem key={1} value={''} selected>不选择</MenuItem>*/}
                            {/*        <MenuItem key={2} value={'EWR1A'}>EWR1A</MenuItem>*/}
                            {/*        <MenuItem key={3} value={'LAX1A'}>LAX1A</MenuItem>*/}
                            {/*        <MenuItem key={4} value={'PHL1A'}>PHL1A</MenuItem>*/}
                            {/*        <MenuItem key={5} value={'SAV1A'}>SAV1A</MenuItem>*/}
                            {/*        <MenuItem key={6} value={'HOU1A'}>HOU1A</MenuItem>*/}
                            {/*    </Select>*/}
                            {/*</FormControl>*/}
                            <DialogActions>
                                <Button fullWidth variant="contained" sx={{mt: 3, mb: 2, ml: 45}} onClick={() => {this.Close()}}>Cancel</Button>
                                <Button fullWidth type='submit' variant="contained" sx={{mt: 3, mb: 2}}>Submit</Button>
                            </DialogActions>
                        </Box>
                    </DialogContent>
                </Dialog>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, {getPod})(GetPod);