import {
    LOAD_RATER,
    RATER_LOADING,
    RATER_TABLE_LOADING,
    RATER_MODAL_LOADING,
    RATER_SEARCH,
    RATER_SEARCH_UPDATE,
    RATER_STOP,
    RATER_CLOSE_ALERT,
    RATER_ORDERS,
    RATER_SEARCH_CLEAR,

    LOAD_SHIPPING_FEE,
    SHIPPING_FEE_LOADING,
    SHIPPING_FEE_TABLE_LOADING,
    SHIPPING_FEE_MODAL_LOADING,
    SHIPPING_FEE_STOP,
    SHIPPING_FEE_SEARCH,
    SHIPPING_FEE_UPDATE,
    SHIPPING_FEE_OPEN,
    SHIPPING_FEE_CLOSE,

    OFFER_SHEET_OPEN,
    OFFER_SHEET_CLOSE,
    LOAD_OFFER_SHEET,
    OFFER_SHEET_LOADING,
    OFFER_SHEET_TABLE_LOADING,
    OFFER_SHEET_MODAL_LOADING,
    OFFER_SHEET_CLOSE_ALERT,
    OFFER_SHEET_STOP,
    OFFER_SHEET_ORDERS,
    UPDATE_SURCHARGE_SUCCESS,
    UPDATE_SURCHARGE_ERR,
    UPLOAD_SHIPPING_FEE_SUCCESS,
    UPLOAD_SHIPPING_FEE_ERR,
    FEDEX_GRD_AD,
    FEDEX_HOME_AD,
    FEDEX_MWT_AD,
    FEDEX_SMART_AD,
    UPS_GRD_AD,
    DEL_OFFER_SHEET_SUCCESS,
    DEL_OFFER_SHEET_ERR,
    ADD_OFFER_SHEET_SUCCESS,
    ADD_OFFER_SHEET_ERR

} from "../type";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";

const initialState = {
    isLoading: false,
    TableLoading: false,
    ModalLoading: false,
    alert: false,
    pageSize: 30,
    page: 0,
    rowCount: 0,
    request: false,
    search: null,
    searchClick: false,
    stop: true,
    content: null,
    data: [],
    orderClick: false,
    orders: [],

    ShippingFeeisLoading: false,
    ShippingFeeTableLoading: false,
    ShippingFeeModalLoading: false,
    shipping_fee_pageSize: 150,
    shipping_fee_page: 0,
    shipping_fee_rowCount: 0,
    shipping_fee_list: [],
    shipping_fee_request: false,
    shipping_fee_content: null,
    shipping_fee_search: null,
    shipping_fee_searchClick: false,
    shipping_fee_stop: true,
    shipping_fee_open: false,

    offerSheetisLoading: false,
    offerSheetTableLoading: false,
    offerSheetModalLoading: false,
    offerSheetpageLoading: false,
    offer_sheet_pageSize: 150,
    offer_sheet_page: 0,
    offer_sheet_rowCount: 0,
    offer_sheet_list: [],
    offer_sheet_request: false,
    offer_sheet_content: null,
    offer_sheet_search: null,
    offer_sheet_searchClick: false,
    offer_sheet_orderClick: false,
    offer_sheet_stop: true,
    offer_sheet_open: false,
    offer_sheet_orders: [],
    MODAL_STATUS: 'FEDEX_GRD_AD'

};


export default function (preState = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case LOAD_RATER:
            const rows : GridRowsProp = payload.data
            return {
                ...preState,
                data: rows,
                rowCount: payload.rowCount,
                TableLoading: false,
                page: payload.page || preState.page,
                pageSize: payload.pageSize || preState.pageSize,
                request: false
            }
        case RATER_LOADING:
            return {
                ...preState,
                isLoading: true,
                request: false,
                content: null,
            }
        case RATER_TABLE_LOADING:
            return {
                ...preState,
                TableLoading: true,
                request: false,
            }
        case RATER_MODAL_LOADING:
            return {
                ...preState,
                ModalLoading: true,
                request: false,
                content: null,
            }
        case RATER_CLOSE_ALERT:
            return {
                ...preState,
                alert: false
            }
        case RATER_STOP:
            return {
                ...preState,
                stop: false,
                searchClick: false,
                orderClick: false
            }
        case RATER_ORDERS:
            return {
                ...preState,
                orderClick: true,
                orders: payload
            }
        case RATER_SEARCH:
            return {
                ...preState,
                data: [],
                page: 0,
                pageSize: 30,
                stop: true,
                searchClick: true
            }
        case RATER_SEARCH_CLEAR:
            return {
                ...preState,
                search: []
            }
        case RATER_SEARCH_UPDATE:
            let search;
            if (!preState.search) {
                search = payload;
            } else {
                search = preState.search;
                for (let i in payload){
                    search[i] = payload[i]
                }
            }
            return {
                ...preState,
                search: search,
                searchClick: false
            }

        case LOAD_SHIPPING_FEE:
            const shipping_fee_rows : GridRowsProp = payload.data
            return {
                ...preState,
                shipping_fee_list: shipping_fee_rows,
                shipping_fee_rowCount: payload.rowCount,
                ShippingFeeTableLoading: false,
                shipping_fee_page: payload.page || preState.shipping_fee_page,
                shipping_fee_pageSize: payload.pageSize || preState.shipping_fee_pageSize,
                shipping_fee_request: false
            }
        case SHIPPING_FEE_LOADING:
            return {
                ...preState,
                ShippingFeeisLoading: true,
                shipping_fee_request: false,
                shipping_fee_content: null,
            }
        case SHIPPING_FEE_TABLE_LOADING:
            return {
                ...preState,
                ShippingFeeTableLoading: true,
                shipping_fee_request: false,
            }
        case SHIPPING_FEE_MODAL_LOADING:
            return {
                ...preState,
                ShippingFeeModalLoading: true,
                shipping_fee_request: false,
                shipping_fee_content: null,
            }
        case SHIPPING_FEE_STOP:
            return {
                ...preState,
                shipping_fee_stop: true,
            }
        case SHIPPING_FEE_SEARCH:
            return {
                ...preState,
                shipping_fee_list: [],
                shipping_fee_stop: true,
                shipping_fee_searchClick: true
            }
        case SHIPPING_FEE_UPDATE:
            let shipping_fee_search;
            if (!preState.search) {
                shipping_fee_search = payload;
            } else {
                shipping_fee_search = preState.shipping_fee_search;
                for (let i in payload){
                    shipping_fee_search[i] = payload[i]
                }
            }
            return {
                ...preState,
                shipping_fee_search: shipping_fee_search,
                shipping_fee_searchClick: false
            }
        case SHIPPING_FEE_OPEN:
            let shipping_fee_open;
            if (!preState.search) {
                shipping_fee_open = payload;
            } else {
                shipping_fee_open = preState.shipping_fee_search ? preState.shipping_fee_search : {};
                for (let i in payload){
                    shipping_fee_open[i] = payload[i]
                }
            }
            return {
                ...preState,
                shipping_fee_search: shipping_fee_open,
                shipping_fee_searchClick: false,
                shipping_fee_open: true
            }
        case SHIPPING_FEE_CLOSE:
            return {
                ...preState,
                shipping_fee_open: false
            }
        // ----------------- offer sheet -------------------------------
        case OFFER_SHEET_OPEN:
            let offer_sheet_open;
            if (!preState.search) {
                offer_sheet_open = payload;
            } else {
                offer_sheet_open = preState.offer_sheet_search ? preState.offer_sheet_search : {};
                for (let i in payload){
                    offer_sheet_open[i] = payload[i]
                }
            }
            return {
                ...preState,
                offer_sheet_search: offer_sheet_open,
                offer_sheet_searchClick: false,
                offer_sheet_open: true
            }
        case OFFER_SHEET_CLOSE:
            return {
                ...preState,
                offer_sheet_open: false
            }
        case LOAD_OFFER_SHEET:
            const offer_sheet_rows : GridRowsProp = payload.data
            return {
                ...preState,
                offer_sheet_list: offer_sheet_rows,
                offer_sheet_rowCount: payload.rowCount,
                offer_sheet_request: false,
                offerSheetpageLoading: false
            }
        case OFFER_SHEET_LOADING:
            return {
                ...preState,
                offerSheetpageLoading: true,
                offer_sheet_request: false,
                offer_sheet_content: null,
            }
        case OFFER_SHEET_TABLE_LOADING:
            return {
                ...preState,
                offerSheetTableLoading: true,
                offer_sheet_request: false,
            }
        case OFFER_SHEET_MODAL_LOADING:
            return {
                ...preState,
                offerSheetModalLoading: true,
                offer_sheet_request: false,
                offer_sheet_content: null,
            }
        case OFFER_SHEET_CLOSE_ALERT:
            return {
                ...preState,
                alert: false
            }
        case OFFER_SHEET_STOP:
            return {
                ...preState,
                offer_sheet_stop: false,
                offer_sheet_searchClick: false,
                offer_sheet_orderClick: false
            }
        case OFFER_SHEET_ORDERS:
            return {
                ...preState,
                offer_sheet_orderClick: true,
                offer_sheet_orders: payload
            }
        case UPDATE_SURCHARGE_SUCCESS:
        case UPDATE_SURCHARGE_ERR:
        case DEL_OFFER_SHEET_SUCCESS:
        case DEL_OFFER_SHEET_ERR:
        case ADD_OFFER_SHEET_SUCCESS:
        case ADD_OFFER_SHEET_ERR:
            return {
                ...preState,
                offerSheetpageLoading: false,
                offer_sheet_open: false,
                offer_sheet_request: true,
                alert: true,
                offer_sheet_content: payload,
                shipping_fee_content: null,
                content: null
            }
        case UPLOAD_SHIPPING_FEE_SUCCESS:
            return {
                ...preState,
                ShippingFeeisLoading: false,
                shipping_fee_list: payload['content'],
                shipping_fee_content: payload,
                offer_sheet_content: null,
                content: null,
                alert: true,
            }
        case UPLOAD_SHIPPING_FEE_ERR:
            return {
                ...preState,
                ShippingFeeisLoading: false,
                shipping_fee_content: payload,
                offer_sheet_content: null,
                content: null,
                alert: true,
            }
        case FEDEX_GRD_AD:
        case FEDEX_HOME_AD:
        case FEDEX_MWT_AD:
        case FEDEX_SMART_AD:
        case UPS_GRD_AD:
            return {
                ...preState,
                MODAL_STATUS: type

            }

        default:
            return preState

    }
}