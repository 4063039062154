import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Box,
    Snackbar
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";

const re = {regex: /^(\+|-)?\d+(\.\d{0,2})?$/};

function UpdateCustomerClaim({ data, claim_update_list, updateClaim, Refresh}) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [credit, setCredit] = useState(0);
    const [EFT, setEFT] = useState("");
    const [case_number, setCaseNumber] = useState("");
    const [claimId, setClaimId] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const openDialog = () => {
        if (claim_update_list && claim_update_list.length === 1) {
            data.forEach((item) => {
                if (claim_update_list.includes(item.id)) {
                    let is_open = false;
                    // Submitted 或者 Resubmitted // Paid
                    if (item.status.name === 'Paid') {
                        is_open = true;
                        setCredit(item.Check_amount);
                    }
                    if (item.status.name === "Submitted" || item.status.name === "Resubmitted"){
                        is_open = true;
                        setCaseNumber(item.Case_number);
                    }
                    if (!is_open){
                        if (!snackbar.open) setSnackbar({open: true, message: '该索赔状态无法操作修改信息!', severity: 'info'});
                        return
                    }

                    setClaimId(item.id);
                    setOpen(true);
                }
            });
        } else {
            if (!snackbar.open) setSnackbar({open: true, message: '请勾选一项进行修改！', severity: 'info'});

        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const item = data.find(item => claim_update_list.includes(item.id) && item.status.name === 'Paid');
            let result;

            if (item){
                result = await updateClaim("PUT", {update_field: "Check_amount", value: {'Check_amount': credit, 'EFT': EFT}, status: 'cell'}, claimId);
            }else{
                result = await updateClaim("PUT", {update_field: "Case_number", value : case_number, status: 'cell'}, claimId);
            }
            if (result?.status === 204) {
                setSnackbar({open: true, message: '修改成功！', severity: 'success'});
                handleClose();
            }else {
                setSnackbar({open: false, message: '修改失败！', severity: 'error'});
                handleClose();
            }
        } catch (e) {
            console.error("上传失败", e);
        } finally {
            setLoading(false);
            Refresh()

        }
    };

    // Assuming `re.regex` is defined elsewhere and valid for checking input format
    const handleCreditChange = (event, setFunc) => {
        const { value } = event.target;
        if (re.regex.test(value) || value === '') {
            setCredit(value);
        }
    };
    const handleEFTChange = (event, setFunc) => {
        const { value } = event.target;
        setEFT(value);
    };

    // Assuming `re.regex` is defined elsewhere and valid for checking input format
    const handleCaseNumberChange = (event, setFunc) => {
        const { value } = event.target;
        setCaseNumber(value);
    };
    const renderField = () => {
        // 查找符合条件的第一个项目
        if (!claim_update_list.length > 0){
            return
        }
        const item = data.find(item => claim_update_list.includes(item.id) && item.status.name === 'Paid');
        if (item) {
            return (
                <FormControl sx={{m: 1, minWidth: 500}}>
                    <FormControl variant={"standard"}>
                        <TextField
                            id="Credit"
                            name="Credit"
                            inputMode="decimal"
                            type="number"
                            value={credit}
                            label="Credit"
                            variant="standard"
                            onChange={handleCreditChange}
                            required
                        />
                    </FormControl>
                    <FormControl>
                        <TextField
                            id="EFT"
                            name="EFT"
                            type="text"
                            value={EFT}
                            label="EFT#"
                            variant="standard"
                            onChange={handleEFTChange}
                        />
                    </FormControl>
                </FormControl>
            );
        } else {
            return <FormControl>
                    <TextField
                    id="Case_number"
                    name="Case_number"
                    inputMode="decimal"
                    type="text"
                    value={case_number}
                    label="Case Number"
                    variant="standard"
                    onChange={handleCaseNumberChange}
                    required
                />
            </FormControl>;
        }
    };
    return (
        <span>
            <Button sx={{ textTransform: 'none' }} startIcon={<EditIcon />} onClick={openDialog}>
                修改信息
            </Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>修改信息</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit}>
                        {renderField()}
                        <DialogActions>
                            <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2, ml: 45 }} onClick={handleClose}>
                                取消
                            </Button>
                            <LoadingButton loading={loading} fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                                确认
                            </LoadingButton>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </span>
    );
}
export default UpdateCustomerClaim;