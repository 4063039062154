import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import '../styles.scss'
import Paper from "@mui/material/Paper";
import {RenderButtonField, RenderCloseField, RenderTextField} from "../../components/renderField";
import {useState} from "react";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import APICircularProgress from "../../components/APICircularProgress";

export default function SearchZone({handleSearch, searchData, SetSearchData}) {
    // const [searchObj, setSearchObj] = useState({name: '', address1: '', address2: '', city: '', postal_code: '', state: ''
    // })
    const [searchOpen, setSearchOpen] = useState(true);
    const handleChange = (e, key, value) => {
        SetSearchData(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    // function handleSubmit() {
    //     if (searchObj.user || searchObj.supplier || searchObj.account_user || searchObj.region) {
    //         console.log("aaa")
    //     } else {
    //         console.log("bbb")
    //     }
    // }
    function Reset(e) {
        SetSearchData(prevState => ({
            ...prevState,
            service_name: '', shipper_zip: ''
        }));
    }
    const toggleSearchOpen = () => {
        setSearchOpen(!searchOpen);
    };

    return (
        <span>
            {!searchOpen && (
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<SearchIcon />}
                    onClick={() => toggleSearchOpen()}
                    variant="text">
                    搜索
                </Button>
            )}
            {searchOpen && (
            <Box component="form" noValidate>
                <Paper sx={{p: 1}}>
                <Grid container style={{marginBottom: "1%"}}>
            {/*{Object.entries(searchObj).map(([key, value]) => (*/}

                    {RenderTextField({
                        idAname: "service_name", label: "service_name",
                        value: searchData.service_name,
                        onChangeHandler: (e) => {
                            handleChange(e, "service_name", e.target.value)
                        }
                    })}

                    {RenderTextField({
                        idAname: "shipper_zip", label: "shipper_zip",
                        value: searchData.shipper_zip,
                        onChangeHandler: (e) => {
                            handleChange(e, "shipper_zip", e.target.value)
                        }
                    })}


                    <Grid container style={{paddingTop: "1%"}}>
                    {RenderButtonField({
                        idAname: "Search",
                        variant: "contained",
                        text: "查询",
                        // buttonType: "submit",
                        tip: "条件查询",
                        onClickHandler: (e) => {
                            handleSearch(e)
                        }
                    })}
                        {RenderButtonField({
                            idAname: "Reset",
                            variant: "outlined",
                            text: "清空",
                            // buttonType: "submit",
                            tip: "Reset",
                            onClickHandler: (e) => {
                                Reset(e)
                            }
                        })}
                        <RenderCloseField onClickHandler={() => setSearchOpen(false)} />
                    </Grid>
                    {/*</>*/}

                    {/*))}*/}
                    </Grid>
                    </Paper>
            </Box>
            )}
        </span>
    );
}
