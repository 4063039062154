import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StyledBox from "../../components/StyledBox";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";

import {useDispatch} from "react-redux";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import APIToolBar from "../../components/APIToolBar";
import ToolBar from "../../components/ToolBar";
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";

const LogisticSupplier = "LogisticSupplier";
function LogisticSupplierPage () {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, SetSearchData] = useState({});
    const dispatch = useDispatch();
    const [Visibility, setVisibility] = useState({
        id: false,
    })
    const columns = [
        {id: 1, field: "id", headerName: "ID", flex: 5},
        {id: 2, field: "name", headerName: "物流商名称", flex: 8},
        {id: 3, field: "code", headerName: "物流商代码", flex: 5},
    ];

    const ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
    }
    const fetchData = async (sortData={}) => {
        const queryParams = {
            ...searchData, // 将搜索条件作为查询参数
            ...sortData,
            curr_page: currentPage < 1 ? 1 : currentPage,
            page_size: pageSize,
        };
        let requestUrl = server.logistics_supplier_list;
        requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);
        try {
            await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: 'GET',
                success: (res) => {
                    setData(res.data.rows)
                    setLoading(false);
                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([])
                    setLoading(false)
                }
            });
        } catch (error) {
            console.error('请求异常', error);
        }
    };
    useEffect(() => {
        setLoading(true)


        // 调用上面定义的函数来加载数据
        fetchData();
    }, [currentPage, pageSize, dispatch]);

    const Refresh = () => {
        // setData([]);
        setData([]);
        setLoading(true);
        fetchData();

    }
    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { ordering };
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        await fetchData(sortData);
    };
    return (
        <Box sx={{
            width: '100%'
        }}>
            <Grid container>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection={false}
                            headerHeight={70}
                            loading={loading}
                            rowCount={data.length}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            onSortModelChange={handleSortModelChange}
                            columnBuffer={3}
                            columnThreshold={3}/>
                    </StyledBox>
                </Grid>
            </Grid>
        </Box>
    )
}
export default LogisticSupplier;
export {LogisticSupplierPage};