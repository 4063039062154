import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StyledBox from "../../components/StyledBox";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import {Route} from "react-router-dom";
import {Switch} from "@mui/material";
import {useDispatch} from "react-redux";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import APIToolBar from "../../components/APIToolBar";
import ServiceAndUser from "./serviceAndUser";

const ServiceAndWarehouse = "ServiceAndWarehouse";

function ServiceAndWarehousePage () {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, SetSearchData] = useState({});
    const dispatch = useDispatch();
    const [Visibility, setVisibility] = useState({
        id: false,
    })
    const columns = [
        {id: 1, field: "id", headerName: "ID", flex: 5},
        {id: 2, field: "name", headerName: "服务名", flex: 8, renderCell: (params) => {
                return params.row.name;
            }},
        {id: 3, field: "UID", headerName: "用户UID", flex: 5, renderCell: (params) => {
                return params.row.user.UID;
            }},
        {id: 4, field: "username", headerName: "用户名", flex: 5, renderCell: (params) => {
                return params.row.user.username;
            }},
        {id: 5, field: "is_permit", headerName: "是否启用", flex: 5}
    ];

    const ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
    }

    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.service_and_user_list}?page=${currentPage}&size=${pageSize}`,
                    method: 'GET',
                    success: (res) => {
                        setData(res.data.rows)
                        setLoading(false);
                    },
                    fail: (error) => {
                        console.error('请求失败', error);
                        setData([])
                        setLoading(false)
                    }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };

        // 调用上面定义的函数来加载数据
        fetchData();
    }, [currentPage, pageSize, dispatch]);

    return (
        <Box sx={{
            width: '100%'
        }}>
            <Grid container>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection={false}
                            headerHeight={70}
                            loading={loading}
                            rowCount={data.length}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: APIToolBar,
                            }}
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>
        </Box>
    )
}
export default ServiceAndWarehouse;
export {ServiceAndWarehousePage};