import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import React from "react";
import TextField from "@mui/material/TextField";
import {LocalizationProvider, DesktopDatePicker, TimePicker} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import InputAdornment from "@mui/material/InputAdornment";
import {Autocomplete, Chip, Link, Popper} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LoadingButton from '@mui/lab/LoadingButton';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from '@mui/material/styles';
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';




export function RenderButtonField({idAname, variant, text, buttonType, tip, onClickHandler, ...otherProps}) {
    return (
        <Grid item lg={1.5} md={1.5} xs={1.5}>
            <FormControl variant="standard" style={{width: '80%'}}>
                <Tooltip title={tip}>
                    <LoadingButton
                        type={buttonType}
                        // fullWidth
                        id={idAname}
                        variant={variant}
                        onClick={onClickHandler}
                        sx={{textTransform: 'none'}}
                        {...otherProps}
                    >
                        {text}
                    </LoadingButton>
                </Tooltip>
            </FormControl>
        </Grid>
    )
}

export function RenderTextField({idAname, label, value, onChangeHandler, ...otherProps}) {
    return (
        <Grid item lg={otherProps['width'] || 3} md={otherProps['width'] || 3} xs={otherProps['width'] || 3}>
            <FormControl variant="standard" style={{width: otherProps['form_width'] || '80%'}}>
                <TextField
                    type='text'
                    id={idAname}
                    name={idAname}
                    label={label}
                    variant="standard"
                    value={value}
                    onChange={onChangeHandler}
                    {...otherProps}
                />
            </FormControl>
        </Grid>
    )
}

export function RenderMultilineTextField({idAname, label, value, onChangeHandler, rows = 1, ...otherProps}) {
    return (
        <Grid item lg={3} md={3} xs={3}>
            <FormControl variant="standard" style={{width: '80%'}}>
                <TextField
                    type='text'
                    id={idAname}
                    name={idAname}
                    label={label}
                    variant="standard"
                    value={value}
                    onChange={onChangeHandler}
                    multiline={true}
                    rows={rows}
                    {...otherProps}
                />
            </FormControl>
        </Grid>
    );
}
const CustomPopper = styled(Popper)(({ theme }) => ({
    '& .MuiAutocomplete-listbox': {
        maxHeight: 200, // 限制下拉框高度
        overflow: 'auto', // 添加滚动条
    },
}));
export function RenderSelectField({idAname, label, value, onChangeHandler, select_options, value_name, v, endAdornment={}, ...otherProps}) {
    // const default_value = select_options.find(select_option => select_option[value_name] == value_name) || null
    const width = otherProps['width'] || 3;
    return (
        <Grid item lg={width} md={width} xs={width}>
            <FormControl variant="standard" style={{width: '80%'}}>
                <Autocomplete
                    value={select_options.find(option => option[v] === value) || null}
                    onChange={onChangeHandler}
                    options={select_options}
                    getOptionLabel={(option) => option[value_name] || ''}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            margin="dense"
                            fullWidth
                            type="text"
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                ...endAdornment
                            }}
                            {...otherProps}
                        />
                    )}
                    filterOptions={(options, state) =>
                        options.filter(option =>
                            (option[value_name] || '').toLowerCase().includes((state.inputValue || '').toLowerCase())
                        )
                    }
                    PopperComponent={(props) => <CustomPopper {...props} />}
                />
            </FormControl>
        </Grid>
    )
}
export function RenderSelectOrInputField(
    {idAname, label, value, onChangeHandler, options, ...otherProps}) {
    const [inputValue, setInputValue] = React.useState("");
        return (
            <Grid item lg={3} md={3} xs={12}>
                <Autocomplete
                    style={{width: '80%'}}
                    freeSolo
                    options={options}
                    value={value ? options.find(option => option.UID === value) || "" : ""}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        const UID = newValue && typeof newValue === 'object' ? newValue.UID : newValue;
                        onChangeHandler({ target: { name: idAname, value: UID || "" } });
                    }}
                    getOptionLabel={(option) => typeof option === 'object' ? `${option.username}-${option.UID}` : option}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            onBlur={() => {
                                if (inputValue && !value) {
                                    onChangeHandler({ target: { name: idAname, value: inputValue } });
                                }
                            }}
                            variant="standard"
                            {...otherProps}
                        />
                    )}
                />
            </Grid>
        );
    }
export function SingleSelectField({ id, label, selectedValue, onChangeHandler, options }) {
    return (
        <Grid item lg={3} md={3} xs={3}>
            <FormControl fullWidth variant="outlined" sx={{ width: '80%' }}>
                <InputLabel id={`${id}-label`}>{label}</InputLabel>
                <Select
                    labelId={`${id}-label`}
                    id={id}
                    name={id}
                    value={selectedValue}
                    onChange={onChangeHandler}
                    input={<OutlinedInput label={label} />}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}

export function MultiSelectField({ id, label, selectedValues, onChangeHandler, options }) {
    const theme = useTheme();

    return (
        <Grid item lg={3} md={3} xs={3}>
            <FormControl fullWidth variant="outlined" sx={{ width: '80%' }}>
                <InputLabel id={`${id}-label`}>{label}</InputLabel>
                <Select
                    labelId={`${id}-label`}
                    id={id}
                    name={id}
                    multiple
                    value={selectedValues}
                    onChange={onChangeHandler}
                    input={<OutlinedInput id={`${id}-outlined`} label={label} />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
              <span style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {selected.map(value => options.find(option => option.value === value)?.label || '').join(', ')}
              </span>
                        </Box>
                    )}
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}

export function RenderDesktopDatePickerField({idAname, label, value, onChangeHandler, ...otherProps}) {
    const width = otherProps['width'] || 5;
    return (
        <Grid item lg={width} md={width} xs={width}>
            <FormControl variant="standard">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label={label}
                        inputFormat="MM/dd/yyyy"
                        endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                        value={value}
                        onChange={onChangeHandler}
                        {...otherProps}
                        renderInput={(params) => <TextField
                            id={idAname} name={idAname}
                            size="small" {...params} />}
                    />
                </LocalizationProvider>
            </FormControl>
        </Grid>
    );
}

export function RenderTimePickerField({idAname, label, value, onChangeHandler, ...otherProps}) {
    const width = otherProps['width'] || 5;
    return (
        <Grid item lg={width} md={width} xs={width}>
            <FormControl variant="standard">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                        label={label}
                        inputFormat="hh:mm a"
                        value={value}
                        onChange={onChangeHandler}
                        {...otherProps}
                        renderInput={(params) =>
                            <TextField
                                id={idAname}
                                name={idAname}
                                size="small"
                                {...params}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">AM/PM</InputAdornment>,
                                }}
                            />}
                    />
                </LocalizationProvider>
            </FormControl>
        </Grid>
    );
}
export function RenderDateRangePickerField({ idAname, label, value, onChangeHandler, ...otherProps }) {
    return (
        <Grid item lg={9.7} md={9.7} xs={9.7}>
            <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                        slots={{ field: SingleInputDateRangeField }}
                        sx={{mr: 0, ml: 0, padding: 0}}
                        name="allowedRange"
                        renderInput={(params) => <TextField {...params} fullWidth />}
                        {...otherProps}

                    />
                </LocalizationProvider>
            </FormControl>
        </Grid>
    );
}


export function renderCloseField({onClickHandler}) {
    return (
        <Grid item lg={3} md={3} xs={3}>
            <FormControl variant="standard">
                <Link
                    // fullWidth
                    underline='none'
                    sx={{textTransform: 'none',}}
                >
                    <Button onClick={onClickHandler}>
                        收起搜索栏
                        <KeyboardArrowUpIcon/>
                    </Button>
                </Link>
            </FormControl>
        </Grid>
    )
}

export function RenderCloseField({ onClickHandler }) {
    return (
        <Grid item lg={3} md={3} xs={3}>
            <FormControl variant="standard">
                <Button onClick={onClickHandler} fullWidth variant="text">
                    收起搜索栏
                    <KeyboardArrowUpIcon />
                </Button>
            </FormControl>
        </Grid>
    );
}


export const GridActionIcon = ({ tooltipTitle, Icon, onClick }) => (
    <Tooltip title={tooltipTitle}>
        <span onClick={onClick} style={{ cursor: 'pointer' }}>
            <Icon />
        </span>
    </Tooltip>
);


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export default function InputFileUpload({text="上传图片"}) {
    return (
        <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
        >
            {text}
            <VisuallyHiddenInput type="file" />
        </Button>
    );
}