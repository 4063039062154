import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";
import FormHelperText from "@mui/material/FormHelperText";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import {Snackbar} from "@mui/material";
import Alert from "@mui/material/Alert";

const re = {regex: /^(\+|-)?\d+(\.\d{0,2})?$/};

function AddCredit({user, open, setOpen, Refresh}) {
    const [display, setDisplay] = useState(false);
    const [credit, setCredit] = useState(0);
    const [account, setAccount] = useState([]);
    const [uid, setUid] = useState(0);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    // 模仿 componentDidMount
    useEffect(() => {
        const fetchData = async () => {
            const res = await AxiosSyncDefaultRequest({
                url: server.user_list_url
            });
            setAccount(res.data.UserList);
            setDisplay(true);
            setUid(Object.keys(res.data.UserList)[0]);
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        setUid(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // 确保在异步操作开始前设置加载状态
        try {

            // 准备提交的数据
            const data = {
                user: uid,
                credit: credit,
                remark: e.target.Remark.value
            };

            // 直接调用异步请求函数并处理结果
            await AxiosSyncDefaultRequest({
                url: server.add_credit_url,
                method: 'POST',
                data: data,
                success: (res) => {
                    setSnackbar({ open: true, message: res.data.message, severity: 'success' });
                    setOpen(false);
                    Refresh();
                },
                fail: (error) => {
                    console.error('请求失败', error);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    throw error;
                }
            });

        } catch (e) {
            console.error("Error submitting data:", e);
            // 此处可以添加用户通知或其他错误处理逻辑
        } finally {
            setLoading(false); // 确保在异步操作结束后清除加载状态
        }
    };


    return (
        <span>
            <Button sx={{textTransform: 'none'}} startIcon={<AttachMoneyIcon/>} onClick={setOpen}>客户充值</Button>
            <Dialog disableEscapeKeyDown open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Add Credit</DialogTitle>
            <Box component="form" onSubmit={handleSubmit} sx={{width: '100%'}}>
                <DialogContent>
                    <Grid container spacing={2}>
                        {display &&
                            <Grid item xs={12} sx={{paddingX: 3}}>  {/* 添加左右内边距 */}
                                <FormControl fullWidth sx={{
                                    '.MuiInputBase-root': {height: 38, fontSize: '0.875rem'},
                                    '.MuiInputLabel-root': {fontSize: '0.875rem'},
                                    '.MuiSvgIcon-root': {fontSize: '1rem'}
                                }}>
                                    <InputLabel htmlFor="Account">Account</InputLabel>
                                    <Select
                                        native
                                        value={uid}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Account" id="Account"
                                                              sx={{height: '100%', padding: '6px 12px'}}/>}
                                    >
                                        {Object.entries(account).map(([key, value]) => (
                                            <option key={key} value={key}
                                                    style={{fontSize: '0.875rem'}}>{value}</option>
                                        ))}
                                    </Select>
                                    <FormHelperText>Please select a recharge user</FormHelperText>
                                </FormControl>
                            </Grid>}
                        <Grid item xs={12} sx={{paddingX: 3}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="Credit"
                                    name="Credit"
                                    inputMode="decimal"
                                    type="number"
                                    value={credit}
                                    label="Credit"
                                    size="small"
                                    helperText="Please set the recharge amount, Maximum two decimal places"
                                    onChange={(e) => {
                                        if (re.regex.test(e.target.value) || e.target.value === '') {
                                            setCredit(e.target.value);
                                        }
                                    }}
                                    required
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{paddingX: 3}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="Remark"
                                    name="Remark"
                                    label="Remark"
                                    helperText="Please set a Remark"
                                    size="small"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'flex-end', marginBottom: 1, marginRight: 1}}>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <LoadingButton loading={loading} type="submit" variant="contained">Submit</LoadingButton>
                </DialogActions>
            </Box>
        </Dialog>
        <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar({...snackbar, open: false})}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                   sx={{width: '100%'}}>
                {snackbar.message}
            </Alert>
        </Snackbar>
        </span>

    );
}

export default AddCredit;