import {
    AUTH_ERROR, CHANGE_PASSWORD_LOADING,
    CHANGE_PASSWORD_SUCCESS, IS_USER,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    USER_ERR,
    USER_LOADING
} from "../type";

const initialState = {
    isAuthenticated: null,
    isLoading: false,
    user: null,
    token: null,
    isLoadUser: false
    // sessionid: null
};

export default function (preState=initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case USER_LOADING:
        case CHANGE_PASSWORD_LOADING:
            return {
                ...preState,
                isLoading: true
            }
        case LOGIN_SUCCESS:
            return {
                ...preState,
                isAuthenticated: true,
                isLoading: false,
                user: payload.user,
                token: payload.token
            };
        case AUTH_ERROR:
        case USER_ERR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
            return {
                ...preState,
                isAuthenticated: false,
                isLoading: false,
                token: null,
                user: null
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...preState,
                isAuthenticated: false,
                user: []
            }
        case IS_USER:
            return {
                ...preState,
                isLoadUser: true
            }
        default:
            return preState

    }
}