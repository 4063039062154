import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
    RenderButtonField, RenderCloseField, RenderTextField
} from "../../components/renderField";
import React, {useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ExportPage from "../modal/ExportPageModal";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
export function FBAAddressBar({searchData, setSearchData, search, setSearch, setCurrentPage}) {
    const [searchOpen, setSearchOpen] = useState(true);
    const handleSubmit = (e) => {
        e.preventDefault(); // 阻止表单默认提交行为
        setCurrentPage(0);
        setSearch()
    }

    const handleFieldChange = (fieldName) => (event) => {
        setSearchData({ ...searchData, [fieldName]: event.target.value });
    };

    const handleClearData = () => {
        setSearchData({
            name: "", address1: "", address2: "", city: "", postal_code: "", state: ""
        });
    };

    const toggleSearchOpen = () => {
        setSearchOpen(!searchOpen);
    };

    return (
        <div>
            {!searchOpen && (
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<SearchIcon />}
                    onClick={() => toggleSearchOpen()}
                    variant="text">
                    搜索
                </Button>
            )}
            {searchOpen && (
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <Paper sx={{p: 1}}>
                    <Grid container style={{marginBottom: "1%"}}>
                        <RenderTextField
                            idAname="name"
                            label="仓库名"
                            value={searchData.name}
                            onChangeHandler={handleFieldChange('name')}
                        />
                        <RenderTextField
                            idAname="address1"
                            label="地址一"
                            value={searchData.address1}
                            onChangeHandler={handleFieldChange('address1')}
                        />
                        <RenderTextField
                            idAname="address2"
                            label="地址二"
                            value={searchData.address2}
                            onChangeHandler={handleFieldChange('address2')}
                        />
                        <RenderTextField
                            idAname="city"
                            label="城市"
                            value={searchData.city}
                            onChangeHandler={handleFieldChange('city')}
                        />
                    </Grid>
                    <Grid container style={{marginBottom: "1%"}}>
                        <RenderTextField
                            idAname="postal_code"
                            label="邮编"
                            value={searchData.postal_code}
                            onChangeHandler={handleFieldChange('postal_code')}
                        />
                        <RenderTextField
                            idAname="state"
                            label="州/省简称"
                            value={searchData.state}
                            onChangeHandler={handleFieldChange('state')}
                        />
                    </Grid>
                    <Grid container>
                        <RenderButtonField
                            idAname="Search"
                            variant="contained"
                            text="查询"
                            buttonType="submit"
                            tip="条件查询"
                            onClickHandler={null}
                            loading={search}
                        />
                        <RenderButtonField
                            idAname="Clear"
                            variant="outlined"
                            text="清空"
                            buttonType="button"
                            tip="清空查询信息"
                            onClickHandler={handleClearData}
                        />
                        <RenderCloseField onClickHandler={() => setSearchOpen(false)} />

                    </Grid>
                    </Paper>
                </Box>
            )}
        </div>
    )
}

