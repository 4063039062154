import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";
import LoadingButton from "@mui/lab/LoadingButton";
import {useState} from 'react';
export default function Confirm({open, SetOpen, user, account, cancel_credit, setSnackbar, setOpen}){
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };
    const [loading, SetLoading] = useState(false)
    const handleSave=async (e) => {
        let data = []
        if(user === "0"){
            for(let key in account){
                if(key !== "0") {
                    data.push({id: key, cancel_credit})
                }
            }

        }
        else {
            data.push({id: user, cancel_credit})
        }
        SetLoading(true)
        await AxiosSyncDefaultRequest({
            url: server.cancel_credit,
            method: 'PATCH',
            data: data,
            success: (res) => {
                setSnackbar({ open: true, message: res.data.message, severity: 'success' });
                SetOpen(false)
                // Refresh();
                setOpen(false)
                SetLoading(false)
            },
            fail: (error) => {
                console.error('请求失败', error);
                const errorMessage = JSON.stringify(error.response.data);
                setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                SetLoading(false)
                setOpen(false)
                throw error;
            }
        });

    }

    return(
    <Modal
        open={open}
        onClose={() => SetOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
    >
        <Box sx={{ ...style, width: 400 }}>
            <h2 id="parent-modal-title">操作确认</h2>
            <p id="parent-modal-description">
                {`是否将 ${account[user]} 取消金额重置为 ${cancel_credit}`}
            </p>
            <Button onClick={() => SetOpen(false)} sx={{ml: "61%"}}>取消</Button>
            <LoadingButton variant="contained" onClick={handleSave} loading={loading}>确认</LoadingButton>
        </Box>
    </Modal>
    )
}