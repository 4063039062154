import React, { useState } from 'react';
import {Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button} from '@mui/material';
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

const EditFormDialog = ({ open, setOpen,  onSave}) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        unit_price: 0,
        max: 0,
        note: '',
        operate: false,
    });
    const [formDataStatus, setFormDataStatus] = useState({
        name: {required: true, error: false, message: ''},
        description: {required: true, error: false, message: ''}
    })

    const handleChange = (event) => {
        const { name, value } = event.target;

            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));

    };
    const handleSave = async () => {
        setLoading(true);
        setFormDataStatus({
            name: {required: true, error: false, message: ''},
            description: {required: true, error: false, message: ''},
            // zone: {required: true, error: false, message: ''},
        })
        if(formData.name && formData.description){
            onSave(formData)
        }
        else {
            const newErrors = {};
            for (let field in formDataStatus) {
                if (formDataStatus[field].required && !formData[field]) {
                    newErrors[field] = {
                        ...formDataStatus[field],
                        message: 'This field is required',
                        error: true
                    };
                }
                else{
                    newErrors[field] = {
                        ...formDataStatus[field],
                        message: '',
                        error: false
                    };
                }
            }
            setFormDataStatus({
                ...formDataStatus,
                ...newErrors
            });
        }
    };
    return (
        <>
            <Dialog open={open} onClose={setOpen} maxWidth="sm" fullWidth>
                <DialogTitle>新增附加费</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="name"
                                label="name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                error={formDataStatus.name.error}
                                helperText={formDataStatus.name.message}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>

                            <TextField
                                margin="dense"
                                name="description"
                                label="description"
                                type="text"
                                fullWidth
                                id="outlined-select-currency"
                                // SelectProps={{
                                //     native: true,
                                // }}
                                // variant="standard"
                                variant="standard"
                                value={formData.description}
                                onChange={handleChange}
                                required
                                error={formDataStatus.description.error}
                                helperText={formDataStatus.description.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="unit_price"
                                label="unit_price"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.unit_price}
                                onChange={handleChange}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="max"
                                label="max"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.max}
                                onChange={handleChange}
                            >

                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="note"
                                label="note"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.note}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="operate"
                                label="operate"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.operate}
                                onChange={handleChange}
                                select
                            >
                                <MenuItem key="true" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="2" value={false}>
                                    否
                                </MenuItem>
                            </TextField>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={setOpen}>取消</Button>
                    <Button loading={loading} onClick={handleSave} variant={"contained"}>保存</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditFormDialog;
