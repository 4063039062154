import React, { useState, useEffect } from 'react';
import { Modal, Container, Grid, Paper } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import ToolBar from '../../components/ToolBar'

function UserErrorPage({error_data, open, setOpen}) {
    const [loading, setLoading] = useState(false);
    const [pageSize, setPageSize] = useState(30);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [sortModal, setSortModal] = useState({});
    const [columns, setColumns] = useState([
        {id: 0, field: "id", headerName: "Id"},
        {id: 1, field: "tracking_number", headerName: "tracking_number", width: 365},
        {id: 2, field: "message", headerName: "错误提示", width: 365}
    ]);
    useEffect(() => {
        let start = (page - 1) * pageSize
        let end = page * pageSize
        setData(error_data.slice(start, end))
        setRowCount(error_data.length);
    }, [error_data])
    const [visibility, setVisibility] = useState({});
    // 页面改变处理
    const handlePageChange = (newPage) => {
        // if (newPage === 0){
        //     newPage = 1;
        // }
        // if (page === 1 && newPage === 1){
        //     newPage = 2;
        // }
        setLoading(true);
        setPage(newPage);
        setData([]);
        let start = (newPage) * pageSize
        let end = (newPage + 1) * pageSize
        setData(error_data.slice(start, end))
        setLoading(false)
        // setRowCount(0);
    };

    // 页面大小改变处理
    const handlePageSizeChange = (newSize) => {
        setLoading(true);
        setPageSize(newSize);
        setData([]);
        let start = (page - 1) * newSize
        let end = page * newSize
        setData(error_data.slice(start, end))
        setLoading(false)
    };

    const Refresh = () => {
        setData([]);
        setLoading(true);
        setData(error_data.slice((page - 1) * pageSize + 1), page * pageSize)
        setLoading(false)
    };
    const handleColumnOrderChange = (e) => {
        let newColumns = [...columns];
        const movedItem = newColumns.splice(e.oldIndex, 1)[0];
        newColumns.splice(e.targetIndex, 0, movedItem);
        setColumns(newColumns);
        // localStorage.setItem('yourLocalStorageKey', JSON.stringify(newColumns));
    };

    const handleColumnVisibilityChange = (newModel) => {
        // localStorage.setItem('yourLocalStorageVisibilityKey', JSON.stringify(newModel));
        setVisibility(newModel);
    };

    const handleSortModelChange = async (sortModel) => {
    };

    return (
        <span>
      {/*<Button startIcon={<ListAltIcon/>} onClick={() => setOpen(true)}>充值记录</Button>*/}
      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="add-credit-title" aria-describedby="add-credit-description">
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={9}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 800 }}>
                <DataGridPro
                    rows={data}
                    sx={{ minHeight: 750, overflow: 'auto', display: 'block' }}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[20, 30, 50, 100, 200, 500]}
                    autoHeight={true}
                    loading={loading}
                    rowCount={rowCount}
                    paginationMode='server'
                    columnVisibilityModel={visibility}
                    onColumnVisibilityModelChange={handleColumnVisibilityChange}
                    rowReordering={false}
                    onColumnOrderChange={handleColumnOrderChange}
                    onSortModelChange={handleSortModelChange}
                    components={{
                        LoadingOverlay: LinearProgress,
                        NoRowsOverlay: CustomNoRowsOverlay,
                        Toolbar: () => <ToolBar onRefresh={Refresh} />,
                    }}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    pagination={true}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Modal>
    </span>
    );
}

export default UserErrorPage; // Assuming all props are passed correctly
