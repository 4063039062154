import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';

import Grid from "@mui/material/Grid";
import DnsIcon from '@mui/icons-material/Dns';
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import {useDispatch} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {RenderSelectField} from "../../../../components/renderField";

const CreateServiceAndSpecial = ({onSave}) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [special_services_options, setSpecialServicesOptions] = useState([])
    const [supplier_options, setSupplierOptions] = useState([])
    const [service_options, setServiceOptions] = useState([])
    const [formData, setFormData] = useState({
        service_id: '',
        package_special_service: '',
        supplier: '',


    });
    const [error, setError] = useState({
        service_id: false,
        package_special_service: false,
        supplier: false,
    })
    const onClose = () => setOpenCreateDialog(false);
    const onOpen = () => setOpenCreateDialog(true);
    const dispatch = useDispatch();
    useEffect(() => {
        // setLoading(true)
        const fetchSpecialSevicesData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.page_special_services}`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.results
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setSpecialServicesOptions(options)
                        // setLoading(false)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
                // setLoading(false)
            }
        };
        const fetchServiceData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_service_list}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setServiceOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchSupplierData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_supplier_list}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setSupplierOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        fetchSpecialSevicesData()
        fetchServiceData();
        fetchSupplierData()
    }, [dispatch]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const handleFieldChange = (e, value, key, value_name) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value?value[value_name]:''
        }));
    }
    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

    };

    const handleSave = async () => {
        setError({
            service_id: false,
            package_special_service: false,
            supplier: false,
        })
        if (formData.package_special_service && formData.service_id && formData.supplier) {

            setLoading(true);
            try {
                const result = await onSave(formData, formData.id, 'POST');
                if (result.status === true) {
                    setFormData({
                        service_id: '',
                        package_special_service: '',
                        supplier: '',
                    })
                    // 操作成功，显示成功消息
                    // setSnackbar({open: true, message: '保存成功！', severity: 'success'});
                }
            } catch (error) {
                console.error("保存失败", error);
                // 操作失败，显示错误消息
                // setSnackbar({ open: true, message: '保存失败,请重试.', severity: 'error' });
            } finally {
                setLoading(false);
                onClose(); // 保存操作完成后关闭对话框
            }
        } else {
            for(let key in error){
                if(!formData[key]){
                    setError((prev) => ({
                        ...prev,
                        [key]: true,
                    }));
                }
            }
        }
    };
    return (
        <>

            <Button sx={{textTransform: 'none'}} startIcon={<DnsIcon/>} onClick={() => {
                onOpen()
            }}>
                新增渠道和特殊服务
            </Button>
            <Dialog open={openCreateDialog} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle> 新增渠道和特殊服务</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            {RenderSelectField({
                                idAname: "package_special_service", label: "特殊服务名称",
                                value: formData.package_special_service,
                                select_options: special_services_options,
                                v: "id",
                                value_name: "name",
                                required:true,
                                error: error.package_special_service,
                                onChangeHandler: (e, value) => {
                                    handleFieldChange(e, value, "package_special_service", "id")
                                },
                                sx: {width: 363}
                            })}

                        </Grid>
                        <Grid item xs={12} md={8}>

                            {RenderSelectField({
                                idAname: "service_id", label: "渠道服务",
                                value: formData.service_id,
                                select_options: service_options,
                                v: "id",
                                value_name: "name",
                                onChangeHandler: (e, value) => {
                                    handleFieldChange(e, value, "service_id", "id")
                                },
                                required: true,
                                error: error.service_id,
                                sx: {width: 363}
                            })}

                        </Grid>
                        <Grid item xs={12} md={8}>
                            {RenderSelectField({
                                idAname: "supplier", label: "渠道商",
                                value: formData.supplier,
                                select_options: supplier_options,
                                v: "id",
                                value_name: "name",
                                onChangeHandler: (e, value) => {
                                    handleFieldChange(e, value, "supplier", "id")
                                },
                                required: true,
                                error: error.supplier,
                                sx: {width: 363}
                            })}

                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant={"contained"}>取消</Button>
                    <LoadingButton loading={loading} onClick={handleSave}>保存</LoadingButton>
                </DialogActions>
            </Dialog>
        </>

    );
};

export default CreateServiceAndSpecial;
