import * as React from 'react';
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import store from "../redux/store";
import {loadUser, login} from "../redux/actions/auth";
import {IS_USER, LOGIN_CLOSE_ALERT} from "../redux/type";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import {createTheme, ThemeProvider} from '@mui/material/styles';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Copyright from "../components/Copyright";
import APICircularProgress from "../components/APICircularProgress";
import APIAlert from "../components/APIAlert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const theme = createTheme();

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: localStorage.getItem('username') || '', password: localStorage.getItem('password') || '',
            password_display: false, RememberMe: eval(localStorage.getItem('RememberMe')) || false
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.props.login(this.state.username, this.state.password, this.state.RememberMe);
    };
    closeAlert = () => store.dispatch({type: LOGIN_CLOSE_ALERT})
    handleClickShowPassword = () => this.setState({password_display: !this.state.password_display})
    RememberMe = () => this.setState({RememberMe: !this.state.RememberMe})
    setUsername = e => this.setState({username: e.target.value})
    setPassword = e => this.setState({password: e.target.value})

    // componentDidMount() {
    //     // 只有当用户未加载，并且用户未认证时，尝试加载用户
    //     if (!this.props.isLoadUser && !this.props.isAuthenticated){
    //         this.props.loadUser();
    //     }
    // }
    render() {
        let alert;
        let loading;
        if (this.props.isAuthenticated) {
            return <Redirect to="/index/"/>
        }
        if (this.props.isLoading){
            loading = APICircularProgress('success')
        }
        if (this.props.alert) {
            const {level, title, message, timeout} = this.props.payload
            const alert_p = {level: level, title: title, message: message, options: null,
                closeFunc: this.closeAlert, timeout: timeout || 3000}
            alert = APIAlert(alert_p)
        }
        return (
            <div>{alert}<ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" onSubmit={this.handleSubmit} noValidate sx={{mt: 5}}>
                            <FormControl variant="outlined" sx={{width: '100%', mb: 2}}>
                                <InputLabel htmlFor="username">username</InputLabel>
                                <OutlinedInput
                                    id="username" name="username" required
                                    value={this.state.username}
                                    onChange={(e) => {this.setUsername(e)}}
                                    type="text"
                                    label="username"
                                />
                            </FormControl>
                            <FormControl variant="outlined" sx={{width: '100%'}}>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    id="password" name="password" required
                                    value={this.state.password}
                                    onChange={(e) => {this.setPassword(e)}}
                                    type={this.state.password_display ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={(e) => {this.handleClickShowPassword(e)}}
                                                edge="end"
                                            >
                                                {this.state.password_display ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="newPassword"
                                />
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox onChange={() => {this.RememberMe()}} value="remember"
                                                   color="primary" checked={this.state.RememberMe}/>}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Sign In
                            </Button>
                        </Box>
                    </Box>
                    <Copyright sx={{mt: 8, mb: 4}}/>
                </Container>
            </ThemeProvider>{loading}
            </div>)
    }
}
// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.logInReducer.isLoading,
    alert: state.logInReducer.alert,
    payload: state.logInReducer.content
});

export default connect(mapStateToProps, {login, loadUser})(SignIn);
