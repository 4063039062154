import {AxiosDefaultRequest} from "../../components/default_request";
import server from "../../server";
import {
    SHIPMENT_PREVIEW_ERR,
    LOAD_SHIPMENT,
    SET_CURRENT,
    SHIPMENT,
    SHIPMENT_LOADING,
    SHIPMENT_MODAL_LOADING,
    SHIPMENT_PREVIEW_SUCCESS,
    SHIPMENT_EXPORT,
    SHIPMENT_EXPORT_SUCCESS,
} from "../type";
import store from "../store";
import {sort_order} from "../../components/utils";

export const loadShipment = (offset, limit, setPage = null, setPageSize = null,
                             options = null, reload = null, orders = null) => (dispatch, getState) => {
    /*
    * offset: 开始
    * limit: 结束
    * setPage: 页面条目数量
    * setPageSize: 设置页面条目数
    * options: 当前用于search存储
    * reload: 忘了
    * orders: 排序
    * */
    if (!store.getState().shipment.search) {
        dispatch({type: SHIPMENT_LOADING})
    } else{
        if (store.getState().shipment.search.submitType === "export") {
            dispatch({type: SHIPMENT_EXPORT})
        }else{
            dispatch({type: SHIPMENT_LOADING})
        }
    }
    let url = server.ship_url + "?offset=" + offset + "&limit=" + limit
    let is_search = false
    if(options && options["start_create_time"]){
        let localTime = new Date(options['start_create_time']);
        options['start_create_time'] = localTime.toISOString();
    }
    if(options && options['end_create_time']){
        let localTime = new Date(options['end_create_time']);
        options['end_create_time'] = localTime.toISOString();
    }
    if (options) {
        for (let i in options) {
            if (options[i] === null) {
                continue
                // options[i] = ''
            }
            if (options[i] !== '') {
                if(is_search === false){
                    is_search = true
                    url = url + `&is_search=${is_search}`
                }
                // new Date(e).toLocaleDateString("en")
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    url = url.replace("#", "%23")
    if (orders) {
        url += sort_order(orders);
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            if (store.getState().shipment.search) {
                if (store.getState().shipment.search.submitType === "export"){
                    dispatch({type: SHIPMENT_EXPORT_SUCCESS, payload: {
                            'request': true, 'level': 'success', 'title': 'SUCCESS',
                            'message': "导出成功!"}})
                    return
                }
            }
            const result = {data: res.data.rows, rowCount: res.data.total, display: false, with_holding: res.data.with_holding, refund: res.data.refund, lump_sum: res.data.lump_sum};

            if (String(setPage) && setPage !== null) {
                result['page'] = setPage
            } else {
                result['page'] = store.getState().shipment.page;
            }
            if (String(setPageSize) && setPageSize !== null) {
                result['pageSize'] = setPageSize
            } else {
                result['pageSize'] = store.getState().shipment.pageSize;
            }
            dispatch({
                type: SET_CURRENT,
                payload: {current: SHIPMENT, searchTable: SHIPMENT, currentUrl: "/index/shipment"}
            })
            dispatch({
                type: LOAD_SHIPMENT, payload: result
            })
        }
    })
}

export const previewShipmentFile = id => (dispatch, getState) => {
    dispatch({type: SHIPMENT_MODAL_LOADING})
    let url;
    if (server.test) {
        url = server.preview_ship_url + '?id=' + id
    } else {
        url = server.preview_ship_url + '?id=' + id
    }

    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: res => {
            if (res['data']['status']) {
                let download_url;
                if (server.test){
                    download_url = server.test_host + ":" + server.post + server.download_ship_url + "?download=" + res['data']["download_path"]
                }else{
                    download_url = server.host + server.download_ship_url + "?download=" + res['data']["download_path"]
                }
                dispatch({type: SHIPMENT_PREVIEW_SUCCESS, payload: {preview_pdf_url: download_url}})
            } else {
                let result = {
                    'level': 'error',
                    'title': 'FAILED',
                    'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
                }
                dispatch({type: SHIPMENT_PREVIEW_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: SHIPMENT_PREVIEW_ERR, payload: result})
        }
    })
}

