import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Box,
    Snackbar
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import Alert from "@mui/material/Alert";

function ConfirmRefundClaimA({ data, claim_update_list, confirmRefundClaim, Refresh }) {
    const [open, setOpen] = useState(false);
    const [claimCredit, setClaimCredit] = useState(0);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const openDialog = () => {
        let tempClaimCredit = 0;
        if (claim_update_list && claim_update_list.length >= 1) {
            for (let i = 0; i < claim_update_list.length; i++) {
                for (let x = 0; x < data.length; x++) {
                    if (data[x].id === claim_update_list[i]) {
                        if (!data[x].returned) {
                            tempClaimCredit += Number(data[x].Check_amount);
                        } else {
                            if (!snackbar.open) setSnackbar({open: true, message: '无法对已退款的数据进行操作！', severity: 'error'});
                            return;
                        }
                    }
                }
            }
            setClaimCredit(tempClaimCredit);
            setOpen(true);
        } else {
            if (!snackbar.open) setSnackbar({open: true, message: '请勾选一项或多项进行修改!', severity: 'error'});
        }
    };

    const handleClose = () => setOpen(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            console.log(claim_update_list)
            const result = await confirmRefundClaim("PUT", {ids: claim_update_list, status: 'confirm', invoiceNumber: invoiceNumber}, claim_update_list[0]);
            if (result?.status === 204) {
                setSnackbar({open: true, message: '退款成功!', severity: 'success'});
                handleClose();
            }else {
                setSnackbar({open: false, message: '退款失败!', severity: 'error'});
                handleClose();
            }
        } catch (e) {
            console.error("退款失败!", e);
        } finally {
            setLoading(false);
            Refresh()

        }
    };
    return (
        <span>
            <Button sx={{ textTransform: 'none' }} startIcon={<VerifiedIcon />} onClick={openDialog}>退款</Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>退款</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl sx={{ m: 1, minWidth: 500 }}>
                            即将对此{claim_update_list.length}个数据进行退款
                            <br/><br/><br/>
                            总确认额度: {claimCredit.toFixed(2)}
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 500 }}>
                            <TextField
                                id="Invoice"
                                name="Invoice"
                                type="text"
                                value={invoiceNumber}
                                label="Invoice"
                                variant="standard"
                                helperText="请填写索赔对应账单"
                                onChange={(e) => setInvoiceNumber(e.target.value)}
                                required
                            />
                        </FormControl>
                        <DialogActions>
                            <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2, ml: 45 }} onClick={handleClose}>取消</Button>
                            <Button fullWidth type='submit' variant="contained" sx={{ mt: 3, mb: 2 }}>确认</Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </span>
    );
}

export default ConfirmRefundClaimA;