import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StyledBox from "../../components/StyledBox";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import {useDispatch} from "react-redux";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import APIToolBar from "../../components/APIToolBar";
import ToolBar from "../../components/ToolBar"
import ExportServerAndUser from '../modal/ExportServerandUser'
import LogisticService from "./logisticService";
import CreateServerAndUser from './ServerAndUser/Dialog/createServerAndUser';
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import EditFormDialog from "./ServerAndUser/Dialog/EditDialog";
import Switch from '@mui/material/Switch';
import Alert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";

const ServiceAndUser = "ServiceAndUser";


function ServiceAndUserPage () {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [searchData, SetSearchData] = useState({});
    const dispatch = useDispatch();
    const [Visibility, setVisibility] = useState({
        service: false, user: false
    })
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [checked, setChecked] = React.useState({});
    const handleOpenEditDialog = (row) => {
        setSelectedRow(row);
        setOpenEditDialog(true);
    };
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const handleChange = (event, id, key) => {
        handleSave({[key]: event.target.checked}, id)
        // setChecked((prev) => ({
        //     ...prev,
        //     [chk_key]: {
        //         ...prev.much,
        //         ...prev.international,
        //         ...prev.enable,
        //         [id]: event.target.checked
        //     },
        // }));
    };
    const columns = [
        {id: 1, field: "id", headerName: "ID", flex: 5},
        {id: 2, field: "name", headerName: "服务名", flex: 8, renderCell: (params) => {
                return params.row.name;
            }},
        {id: 3, field: "UID", headerName: "用户UID", flex: 5, renderCell: (params) => {
                return params.row.user.UID;
            }},
        {id: 4, field: "username", headerName: "用户名", flex: 5, renderCell: (params) => {
                return params.row.user.username;
            }},
        {id: 5, field: "is_permit", headerName: "是否启用", flex: 5, renderCell: (params) => {
                return <Switch
                    checked={checked[params.row.id]}
                    onChange={(e) => handleChange(e, params.row.id, "is_permit")}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }},
        {id: 6, field: "service", headerName: "服务", flex: 5},
        {id: 7, field: "user", headerName: "用户", flex: 5},
        {id: 8, type: 'actions', field: 'actions', headerName: "Actions", getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        title={'Edit'}
                        onClick={() => handleOpenEditDialog(params.row)}
                        key={`edit-${params.id}`}
                        color="primary"
                    />,

                ];
            }},
    ];

    const ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
    }
    useEffect(() => {
        setLoading(true)
        // 调用上面定义的函数来加载数据
        fetchData("GET", {}, null, {}, false)
    }, [currentPage, pageSize, dispatch]);

    const Refresh = (open=true) => {
        setData([]);
        setLoading(true);
        fetchData("GET", {}, null, {}, open).then()
        setSearch(false);
    }
    const handleSave = (editedData, rowId, method='PUT') => {
        // 直接返回 fetchData 函数调用的 Promise
        let result = {status: false, message: ''};
        return fetchData(method, editedData, rowId
        ).then((e) => {
            Refresh()
            result.status = e !== undefined;
            return result;
        }).catch((error) => {
            result.status = false;
            result.message = error.response.message;
            return result;
        }).finally(() => {
            return result;
        });
    }
    const fetchData = async (method, data = {}, rowId = null,sortData={}, open= true) => {
        try {
            const queryParams = {
                ...searchData, // 将搜索条件作为查询参数
                ...sortData,
                curr_page: currentPage < 1 ? 1 : currentPage,
                page_size: pageSize,
            };

            let requestUrl = server.service_and_user_list;
            let requestData = {};
            let msg = "获取"
            if (method === 'GET') {
                requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);

            } else if (method === 'PUT' && rowId != null) {
                msg = "修改"
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = data;
            }else if (method === "DELETE") {
                requestUrl = `${requestUrl}${rowId}/`;
            }else if (method === "POST") {
                msg = "创建"
                requestData = data;
                console.log("requestData", requestData)
            }

            return await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                success: (res) => {
                    // setTotalRows(1)

                    if (method === 'GET') {
                        let checked_dic = {}
                        let rows = res.data.rows
                        for (let i in rows){
                            checked_dic[rows[i]["id"]] = rows[i]["is_permit"]
                        }
                        setData(rows);
                        setChecked(checked_dic)
                        setLoading(false);
                        // setTotalRows(res.data.total);
                    }
                    if (open) {
                        setSnackbar({open: true, message: `${msg}成功！`, severity: 'success'});
                    }

                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // throw error
                }
            });
        } catch (error) {
            console.error('请求异常', error);
        }
    };
    const handleSearch = (e) => {

        setSearch(true);
        fetchData("GET")
    }

    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { ordering };
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        await fetchData('GET', {}, null, sortData);
    };
    const onChangeColumnVisibilityModel = (newVisibility) => {
        // if (user.is_open_claim !== "4") {
        //     newVisibility = { ...newVisibility, Check_amount: false };
        // }
        setVisibility(newVisibility);
    };
    return (
        <Box sx={{
            width: '100%'
        }}>
            <Grid container>
                <Grid item md={12} lg={12}>
                    <ExportServerAndUser handleSearch={handleSearch} searchData={searchData} SetSearchData={SetSearchData}></ExportServerAndUser>
                    <CreateServerAndUser onSave={handleSave}></CreateServerAndUser>
                    <StyledBox>
                        {data ? <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection={false}
                            headerHeight={70}
                            loading={loading}
                            rowCount={data.length}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            onSortModelChange={handleSortModelChange}
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>
            <EditFormDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                rowData={selectedRow}
                onSave={handleSave}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}
export default ServiceAndUser;
export {ServiceAndUserPage};