import React, {useState} from "react";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDateFns} from "@mui/x-date-pickers-pro/AdapterDateFns";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import ExportPage from "../modal/ExportPageModal";


export const AddCreditSearchBar = ({searchData, setSearchData, search, setSearch, setCurrentPage, handleExport}) => {
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setSearch(true);
    };
    const handleFieldChange = (fieldName) => (event) => {
        setSearchData({ ...searchData, [fieldName]: event.target.value });
    };

    const handleDateFieldChange = (fieldName) => (newValue) => {
        // 直接设置 Date 对象到状态中
        setSearchData({ ...searchData, [fieldName]: newValue });
    };
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <Grid item lg={12} md={12} xs={12}>
                        <FormControl variant="standard" style={{width: '100%', marginBottom: "1vh"}}>
                            <TextField
                                type='text'
                                id={"username"}
                                name={"username"}
                                label={"username"}
                                size={'small'}
                                value={searchData.username}
                                onChange={handleFieldChange('username')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <FormControl variant="standard" style={{width: '100%', marginBottom: "1vh"}}>
                            <TextField
                                type='text'
                                id={"remark"}
                                name={"remark"}
                                label={"Remark"}
                                size={'small'}
                                value={searchData.remark}
                                onChange={handleFieldChange("remark")}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <FormControl variant="standard" sx={{marginBottom: "1vh"}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label={"start_create_time"}
                                    inputFormat="MM/dd/yyyy"
                                    endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                                    value={searchData.start_create_time}
                                    onChange={handleDateFieldChange("start_create_time")}
                                    renderInput={(params) => <TextField
                                        id={"start_create_time"} name={"start_create_time"}
                                        size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <FormControl variant="standard">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label={"End create time"}
                                    inputFormat="MM/dd/yyyy"
                                    endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                                    value={searchData.end_create_time}
                                    onChange={handleDateFieldChange("end_create_time")}
                                    renderInput={(params) => <TextField
                                        id={"end_create_time"} name={"end_create_time"}
                                        size="small" {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                    >
                        查询
                    </Button>
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{ mt: 2, mb: 2, textTransform: 'none' }}
                        onClick={handleExport}
                    >
                        导出
                    </Button>
                </Box>
            </LocalizationProvider>
        </div>
    );
};


