import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import {AxiosSyncDefaultRequest} from "../../../src/components/default_request";
import server from '../../../src/server'
import {useDispatch} from "react-redux";
import '../styles.scss'
import Paper from "@mui/material/Paper";
import {RenderButtonField, RenderCloseField, RenderSelectField, RenderTextField} from "../../components/renderField";
import {useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";


export default function ExportWarehouseAndAccount({handleSearch,searchData, SetSearchData}) {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const [supplier_options, setSupplierOptions] = useState([])
    const [user_options, setUserOptions] = useState([])
    const [searchOpen, setSearchOpen] = useState(true);

    const handleChange = (e, key, value) => {
        SetSearchData(prevState => ({
            ...prevState,
            [key]: value
        }));
    }
    const handleFieldChange = (e, value, key, value_name) => {
        SetSearchData(prevState => ({
            ...prevState,
            [key]: value?value[value_name]:''
        }));
    }
    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_supplier_list}?page=${currentPage}&size=${pageSize}`,
                    method: 'GET',
                    success: (res) => {
                        let options = [];
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setSupplierOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchUserData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: server.user_list_url,
                    method: 'GET',
                    success: (res) => {
                        let options = [];
                        let UserList = res.data.UserList
                        for (let [key, value] of Object.entries(UserList)) {
                            options.push({id: key, value})
                        }
                        setUserOptions(options)
                    }
                })
            } catch (error) {
                console.error('请求异常用户列表异常', error);
            }
        }
        fetchData();
        fetchUserData()
    }, [currentPage, pageSize, dispatch]);

    function Reset(e) {
        SetSearchData(prevState => ({
            ...prevState,
            user_id: '', supplier_id: '', region: '', account_user: ''
        }));
    }
    const toggleSearchOpen = () => {
        setSearchOpen(!searchOpen);
    };
    return (
        <span>
            {!searchOpen && (
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<SearchIcon />}
                    onClick={() => toggleSearchOpen()}
                    variant="text">
                    搜索
                </Button>
            )}
            {searchOpen && (
            <Box component="form" noValidate>
                <Paper sx={{p: 1}}>
                <Grid container style={{marginBottom: "1%"}}>
            {/*{Object.entries(searchObj).map(([key, value]) => (*/}

                    {RenderSelectField({
                        idAname: "user", label: "可用用户",
                        value: searchData.user_id,
                        select_options: user_options,
                        v: "id",
                        value_name: "value",
                        onChangeHandler: (e, value) => {
                            handleFieldChange(e, value, "user_id", "id")
                        }
                    })}
                    {RenderSelectField({
                        idAname: "supplier", label: "渠道商",
                        value: searchData.supplier_id,
                        select_options: supplier_options,
                        v: "id",
                        value_name: "name",
                        onChangeHandler: (e, value) => {
                            handleFieldChange(e, value, "supplier_id", "id")
                        }
                    })}
                    {RenderTextField({
                        idAname: "region", label: "口岸",
                        value: searchData.region,
                        onChangeHandler: (e) => {
                            handleChange(e, "region", e.target.value)
                        }
                    })}
                    {RenderTextField({
                        idAname: "account_user", label: "账号名",
                        value: searchData.account_user,
                        onChangeHandler: (e) => {
                            handleChange(e, "account_user", e.target.value)
                        }
                    })}
                    <Grid container style={{paddingTop: "1%"}}>
                    {RenderButtonField({
                        idAname: "Search",
                        variant: "contained",
                        text: "查询",
                        // buttonType: "submit",
                        tip: "条件查询",
                        onClickHandler: (e) => {
                            handleSearch(e)
                        }
                    })}
                        {RenderButtonField({
                            idAname: "Reset",
                            variant: "outlined",
                            text: "清空",
                            // buttonType: "submit",
                            tip: "Reset",
                            onClickHandler: (e) => {
                                Reset(e)
                            }
                        })}
                        <RenderCloseField onClickHandler={() => setSearchOpen(false)} />
                    </Grid>
                    {/*</>*/}

                    {/*))}*/}
                    </Grid>
                    </Paper>
            </Box>
            )}
        </span>
    );
}
