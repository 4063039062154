import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Snackbar} from '@mui/material';
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import {useDispatch} from "react-redux";
import {RenderSelectField, RenderTextField} from "../../../../components/renderField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const EditFormDialog = ({ open, onClose, rowData, onSave }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        user: '',
        supplier: '',
        region: 'EWR',
        name: '',
        account_user: '---',
        account_kwargs_1: '---',
        account_kwargs_2: '---',
        account_kwargs_3: '---',
        account_kwargs_4: '---',
        account_kwargs_5: '---',
        account_kwargs_6: '---',
        account_kwargs_7: '---',
        isDebug: 0,
        isDefault: 0,
        isSystemDefault: 0,
        optional: '{}',
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const [statusFormData, setStatusFormData] = useState({
        supplier: {required: true, message: '', status: false},
        region: {required: true, message: '', status: false},
        name: {required: true, message: '', status: false},
        account_user: {required: true, message: '', status: false},
        account_password: {required: true, message: '', status: false},
        account_kwargs_1: {required: false, message: '', status: false},
        account_kwargs_2: {required: false, message: '', status: false},
        account_kwargs_3: {required: false, message: '', status: false},
        account_kwargs_4: {required: false, message: '', status: false},
        account_kwargs_5: {required: false, message: '', status: false},
        account_kwargs_6: {required: false, message: '', status: false},
        account_kwargs_7: {required: false, message: '', status: false},
        isDebug: {required: false, message: '', status: false},
        isDefault: {required: false, message: '', status: false},
        isSystemDefault: {required: false, message: '', status: false},
        optional: {required: false, message: '', status: false},
    })
    const [user_info, setUserInfo] = useState([]);
    const [supplier_options, setSupplierOptions] = useState([]);
    const [region_options, setRegionOptions] = useState([]);
    const dispatch = useDispatch();
    const [supplier_info, setSupplierInfo] = useState({});
    const [password_display, setPasswordDisplay] = useState(false);
    // 当rowData改变时，更新表单数据
    useEffect(() => {
        if (rowData) {
            let row_data = {}
            for(let key in formData) {
                let value = rowData[key]
                if(key === "supplier" || key === "user"){
                    row_data[key] = value["id"]
                }

                else {
                    row_data[key] = value
                }
                }

            setFormData(row_data);
        }
    }, [rowData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name==="user"){
            let Name
            if (value === "1"){
                Name = "System"
            }
            else {
                console.log(user_info)
                Name = user_info[value].split("(")[0]
            }
            setFormData((prev) => ({
                ...prev,
                [name]: value,
                name: Name
            }));
        }

        else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };
    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_supplier_list}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                            setSupplierInfo((prev) => ({
                                ...prev,
                                [JSON.stringify(rows[i].id)]: rows[i].name
                            }));
                        }
                        setSupplierOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchUserData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: server.user_list_url,
                    method: 'GET',
                    success: (res) => {
                        let UserList = res.data.UserList
                        let opt = []
                        for (let [key, value] of Object.entries(UserList)) {
                            opt.push({id: parseInt(key), name: value})
                        }
                        setUserInfo(opt)
                    }
                })
            } catch (error) {
                console.error('请求异常用户列表异常', error);
            }
        }
        const fetchWarehouseData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.warehouse_list_url}`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].name, name: rows[i].name})
                        }
                        setRegionOptions(options)
                    }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        }
        fetchData();
        fetchUserData();
        fetchWarehouseData();
    }, [dispatch]);
    const handleSave = async () => {
        setLoading(true);
        try {
            let form_data = JSON.parse(JSON.stringify(formData));
            delete form_data.id;
            const result = await onSave(form_data, formData.id, 'PUT');
            if (result.status === true) {
                // 操作成功，显示成功消息
                // setSnackbar({ open: true, message: '保存成功！', severity: 'success' });
            }
        } catch (error) {
            console.error("保存失败", error);
            // 操作失败，显示错误消息
            // setSnackbar({ open: true, message: '保存失败，请重试。', severity: 'error' });
        } finally {
            // setLoading(false);
            onClose(); // 保存操作完成后关闭对话框
        }
    };
    const handleFieldChange = (event, value, key, value_key) => {
        setFormData((prev) => ({
            ...prev,
            [key]: value?value[value_key]:'',
        }));

    };
    const handleClickShowPassword = () => setPasswordDisplay(!password_display);
    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>编辑信息</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                idAname="supplier"
                                label="渠道商"
                                value={formData.supplier}
                                select_options={supplier_options}
                                onChangeHandler={(e, value) => {
                                    handleFieldChange(e, value, "supplier", "id")
                                }}
                                v={"id"}
                                value_name={"name"}
                                sx={{width: 350}}
                                required={statusFormData.supplier.required}
                                helperText={statusFormData.supplier.message}
                                error={statusFormData.supplier.status}
                            />
                            <RenderSelectField
                                idAname="region"
                                label="口岸"
                                value={formData.region}
                                select_options={region_options}
                                onChangeHandler={(e, value) => {
                                    handleFieldChange(e, value, "region", "id")
                                }}
                                v={"id"}
                                value_name={"name"}
                                sx={{width: 350}}
                                required={statusFormData.region.required}
                                helperText={statusFormData.region.message}
                                error={statusFormData.region.status}
                            />
                            <TextField
                                margin="dense"
                                name="name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                                required={statusFormData.name.required}
                                helperText={statusFormData.name.message}
                                error={statusFormData.name.status}
                            />
                            <TextField
                                margin="dense"
                                name="account_user"
                                label="账号名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_user}
                                defaultValue={formData.account_user}
                                onChange={handleChange}
                                required={statusFormData.account_user.required}
                                helperText={statusFormData.account_user.message}
                                error={statusFormData.account_user.status}
                            />
                            <TextField
                                margin="dense"
                                name="account_kwargs_1"
                                label="参数1"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_1}
                                onChange={handleChange}
                                required={statusFormData.account_kwargs_1.required}
                                helperText={statusFormData.account_kwargs_1.message}
                                error={statusFormData.account_kwargs_1.status}
                            />
                            <TextField
                                margin="dense"
                                name="account_kwargs_2"
                                label="参数2"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_2}
                                onChange={handleChange}
                                required={statusFormData.account_kwargs_2.required}
                                helperText={statusFormData.account_kwargs_2.message}
                                error={statusFormData.account_kwargs_2.status}
                            />
                            <TextField
                                margin="dense"
                                name="account_kwargs_3"
                                label="参数3"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_3}
                                onChange={handleChange}
                                required={statusFormData.account_kwargs_3.required}
                                helperText={statusFormData.account_kwargs_3.message}
                                error={statusFormData.account_kwargs_3.status}
                            />
                            <TextField
                                margin="dense"
                                name="account_kwargs_4"
                                label="参数4"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_4}
                                onChange={handleChange}
                                required={statusFormData.account_kwargs_4.required}
                                helperText={statusFormData.account_kwargs_4.message}
                                error={statusFormData.account_kwargs_4.status}
                            />
                            <TextField
                                margin="dense"
                                name="account_kwargs_5"
                                label="参数5"
                                type="text"
                                fullWidth
                                variant="standard"
                                defaultValue='---'
                                value={formData.account_kwargs_5}
                                onChange={handleChange}
                                required={statusFormData.account_kwargs_5.required}
                                helperText={statusFormData.account_kwargs_5.message}
                                error={statusFormData.account_kwargs_5.status}
                            />
                            <TextField
                                margin="dense"
                                name="account_kwargs_6"
                                label="参数6"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.account_kwargs_6}
                                onChange={handleChange}
                                required={statusFormData.account_kwargs_6.required}
                                helperText={statusFormData.account_kwargs_6.message}
                                error={statusFormData.account_kwargs_6.status}
                            />
                            <RenderTextField
                                margin={"dense"}
                                idAname={"account_kwargs_7"}
                                label={"Secret Key"}
                                type={password_display ? 'text' : 'password'}
                                width={12}
                                form_width={'100%'}
                                variant={"standard"}
                                value={formData.account_kwargs_7}
                                onChangeHandler={handleChange}
                                required={statusFormData.account_kwargs_7.required}
                                helperText={statusFormData.account_kwargs_7.message}
                                error={statusFormData.account_kwargs_7.status}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={(e) => {handleClickShowPassword()}}
                                                edge="end"
                                            >
                                                {password_display ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                margin="dense"
                                name="isDefault"
                                label="是否默认账号"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.isDefault}
                                defaultValue={formData.isDefault}
                                onChange={handleChange}
                            >
                                <MenuItem key="isDefault_1" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="isDefault_0" value={false} selected>
                                    否
                                </MenuItem>

                            </TextField>
                            <TextField
                                margin="dense"
                                name="isDebug"
                                label="是否测试账号"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.isDebug}
                                onChange={handleChange}
                            >
                                <MenuItem key="isDebug_1" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="isDebug_0" value={false} selected>
                                    否
                                </MenuItem>

                            </TextField>
                            <TextField
                                margin="dense"
                                name="isSystemDefault"
                                label="是否系统默认账号"
                                type="text"
                                fullWidth
                                variant="standard"
                                select
                                value={formData.isSystemDefault}
                                onChange={handleChange}
                            >
                                <MenuItem key="isSystemDefault_1" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="isSystemDefault_0" value={false} selected>
                                    否
                                </MenuItem>

                            </TextField>
                            <TextField
                                margin="dense"
                                name="optional"
                                label="其他参数"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.optional}
                                onChange={handleChange}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>
                    <Button loading={loading} onClick={handleSave} variant={"contained"}>保存</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default EditFormDialog;
