import React from 'react';
import { Button, Box, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


function MultiFileUpload({ files, setFiles,  accept}) {
    const handleFilesChange = (event) => {
        setFiles([...event.target.files]);
    };

    const handleRemoveFile = (index) => {
        // 创建一个新数组，除了需要删除的文件外，包含所有当前文件
        const newFiles = files.filter((_, i) => i !== index);
        setFiles(newFiles);
    };

    return (
        <Box sx={{ mt: 1 }}>
            <input
                accept={accept}
                style={{ display: 'none' }}
                id="file-upload"
                multiple
                type="file"
                onChange={handleFilesChange}
            />
            <label htmlFor="file-upload">
                <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{ mt: 2, mb: 2 }}
                >
                    Upload Files
                </Button>
            </label>
            {files.length > 0 && (
                <List sx={{ mt: 2, width: '100%' }}>
                    {files.map((file, index) => (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(index)}>
                                    <CloseIcon />
                                </IconButton>
                            }
                            sx={{ py: 0.5 }}
                        >
                            <ListItemIcon>
                                <InsertDriveFileIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant="body2">{file.name}</Typography>}
                                secondary={<Typography variant="caption">{formatBytes(file.size)}</Typography>}
                            />
                        </ListItem>
                    ))}
                </List>

            )}
        </Box>
    );
}

export default MultiFileUpload;
