import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import '../styles.scss'
import Paper from "@mui/material/Paper";
import {RenderButtonField, RenderCloseField, RenderTextField} from "../../components/renderField";
import {useState} from "react";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";

export default function ExportServerAndUser({handleSearch, searchData, SetSearchData}) {
    // const [searchObj, setSearchObj] = useState({name: '', username: '', user__UID: ''})
    const [searchOpen, setSearchOpen] = useState(true);
    const handleChange = (e, key, value) => {
        SetSearchData(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    function Reset(e) {
        SetSearchData(prevState => ({
            ...prevState,
            name: '', username: '', user__UID: ''
        }));
    }
    const toggleSearchOpen = () => {
        setSearchOpen(!searchOpen);
    };
    return (
        <span>
            {!searchOpen && (
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<SearchIcon />}
                    onClick={() => toggleSearchOpen()}
                    variant="text">
                    搜索
                </Button>
            )}
            {searchOpen && (
            <Box component="form" noValidate>
                <Paper sx={{p: 1, m: 1}}>
                <Grid container style={{marginBottom: "1%"}}>
            {/*{Object.entries(searchObj).map(([key, value]) => (*/}

                    {RenderTextField({
                        idAname: "name", label: "服务名",
                        value: searchData.name,
                        onChangeHandler: (e) => {
                            handleChange(e, "name", e.target.value)
                        }
                    })}

                    {RenderTextField({
                        idAname: "user__UID", label: "用户UID",
                        value: searchData.user__UID,
                        onChangeHandler: (e) => {
                            handleChange(e, "user__UID", e.target.value)
                        }
                    })}
                    {RenderTextField({
                        idAname: "username", label: "用户名",
                        value: searchData.username,
                        onChangeHandler: (e) => {
                            handleChange(e, "username", e.target.value)
                        }
                    })}


                    <Grid container style={{paddingTop: "1%"}}>
                    {RenderButtonField({
                        idAname: "Search",
                        variant: "contained",
                        text: "查询",
                        // buttonType: "submit",
                        tip: "条件查询",
                        onClickHandler: (e) => {
                            handleSearch(e)
                        }
                    })}
                        {RenderButtonField({
                            idAname: "Reset",
                            variant: "outlined",
                            text: "清空",
                            // buttonType: "submit",
                            tip: "Reset",
                            onClickHandler: (e) => {
                                Reset(e)
                            }
                        })}
                        <RenderCloseField onClickHandler={() => setSearchOpen(false)} />

                    </Grid>
                    {/*</>*/}

                    {/*))}*/}
                    </Grid>
                    </Paper>
            </Box>
            )}
        </span>
    );
}
