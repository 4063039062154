import {AxiosDefaultRequest} from "../../components/default_request";
import server from "../../server";
import {LOAD_BILL, BILL_LOADING, SET_CURRENT, BILL, BILL_EXPORT_SUCCESS, BILL_EXPORT} from "../type";
import store from "../store";
import {sort_order} from "../../components/utils";

export const loadBill = (offset, limit, setPage=null, setPageSize=null, options=null, reload=null, orders) => dispatch => {
    if (!store.getState().BillReducer.search) {
        dispatch({type: BILL_LOADING})
    } else{
        if (store.getState().BillReducer.search.submitType === "export") {
            dispatch({type: BILL_EXPORT})
        }else{
            dispatch({type: BILL_LOADING})
        }
    }

    let url = server.bill_url + "?offset=" + offset + "&limit=" + limit
    if(options && options["start_create_time"]){
        let localTime = new Date(options['start_create_time']);
        options['start_create_time'] = localTime.toISOString();
    }
    if(options && options['end_create_time']){
        let localTime = new Date(options['end_create_time']);
        options['end_create_time'] = localTime.toISOString();
    }
    if (options){
        for (let i in options){
            // create_time 初始化不能为''
            if (options[i] === null) {
                options[i] = ''
            }
            if (options[i] !== ''){
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    if (orders){
        url += sort_order(orders);
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            if (store.getState().BillReducer.search) {
                if (store.getState().BillReducer.search.submitType === "export"){
                    dispatch({type: BILL_EXPORT_SUCCESS, payload: {
                        'request': true, 'level': 'success', 'title': 'SUCCESS',
                        'message': "导出成功!"}})
                    return
                }
            }
            const result = {data: res.data.rows, rowCount: res.data.total, display: false};
            if (String(setPage) && setPage !== null) {
                result['page'] = setPage
            }else{
                result['page'] = store.getState().BillReducer.page;
            }
            if (String(setPageSize) && setPageSize !== null) {
                result['pageSize'] = setPageSize
            }else{
                result['pageSize'] = store.getState().BillReducer.pageSize;
            }
            dispatch({type: SET_CURRENT, payload: {current: BILL, searchTable: BILL, currentUrl: "/index/bill"}})
            dispatch({type: LOAD_BILL, payload: result})
        }
    })
}