import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button} from '@mui/material';
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import {useDispatch} from "react-redux";
import {RenderSelectField} from "../../../../components/renderField";

const EditFormDialog = ({ open, onClose, rowData, onSave }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        service_id: '',
        package_special_service: '',
        supplier: '',
    });
    const [user_options, setUserOptions] = useState([])
    const dispatch = useDispatch();
    const [service_options, setServiceOptions] = useState([])
    const [special_services_options, setSpecialServicesOptions] = useState([])
    const [supplier_options, setSupplierOptions] = useState([])
    // 当rowData改变时，更新表单数据
    useEffect(() => {
        if (rowData) {
            let row_data = {}
            for (let key in formData) {
                let value = rowData[key]
                if (key === "id"){row_data[key] = value}else {
                    row_data[key] = value["id"]
                }


            }
            setFormData(row_data);
        }
    }, [rowData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if(name === "is_permit" && typeof value !== 'boolean'){
            setFormData((prev) => ({
                ...prev,
                [name]: Boolean(value),
            }));
        }
        else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
        if(name === "user"){
            console.log("..user", typeof value)
        }
    };
    useEffect(() => {
        setLoading(true)
        const fetchSpecialSevicesData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.page_special_services}`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.results
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setSpecialServicesOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchServiceData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_service_list}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setServiceOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchSupplierData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_supplier_list}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setSupplierOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        fetchSpecialSevicesData()
        fetchServiceData();
        fetchSupplierData()
    }, [dispatch]);
    const handleSave = async () => {
        setLoading(true);
        try {
            let form_data = JSON.parse(JSON.stringify(formData));
            delete form_data["id"];
            const result = await onSave(form_data, formData.id, 'PUT');
            if (result.status === true) {
                // 操作成功，显示成功消息
                // setSnackbar({ open: true, message: '保存成功！', severity: 'success' });
            }
        } catch (error) {
            console.error("保存失败", error);
            // 操作失败，显示错误消息
            // setSnackbar({ open: true, message: '保存失败，请重试。', severity: 'error' });
        } finally {
            // setLoading(false);
            onClose(); // 保存操作完成后关闭对话框
        }
    };
    const handleFieldChange = (e, value, key, value_name) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value?value[value_name]:''
        }));
    }
    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>编辑信息</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                margin="dense"
                                idAname="package_special_service"
                                label="特殊服务名"
                                type="text"
                                fullWidth
                                select_options={special_services_options}
                                variant="standard"
                                value={formData.package_special_service}
                                v={"id"}
                                value_name={"name"}
                                onChangeHandler={(e, value) => handleFieldChange(e, value, "package_special_service", "id")}
                                required
                                sx={{width: 364}}
                            />
                            {/*    <MenuItem aria-label="None" key={'Warehouse_None'} value={""}>不选择</MenuItem>*/}

                            {/*    {special_services_options.map((option) => (*/}
                            {/*        <MenuItem key={option.id} value={option.id}>*/}
                            {/*            {option.name}*/}
                            {/*        </MenuItem>*/}
                            {/*    ))}*/}

                            {/*</TextField>*/}
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                margin="dense"
                                idAname="service_id"
                                label="渠道服务"
                                type="text"
                                fullWidth
                                select_options={service_options}
                                variant="standard"
                                value={formData.service_id}
                                v={"id"}
                                value_name={"name"}
                                sx={{width: 364}}
                                onChangeHandler={(e, value) => handleFieldChange(e, value, "service_id", "id")}
                            />

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                margin="dense"
                                idAname="supplier"
                                label="渠道商"
                                type="text"
                                fullWidth
                                select_options={supplier_options}
                                variant="standard"
                                value={formData.supplier}
                                v={"id"}
                                value_name={"name"}
                                sx={{width: 364}}
                                onChangeHandler={(e, value) => handleFieldChange(e, value, "supplier", "id")}
                            />

                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>
                    <Button loading={loading} onClick={handleSave} variant={"contained"}>保存</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditFormDialog;
