import React, {useState, useEffect} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Box,
    Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Alert from "@mui/material/Alert";
import AdsClickIcon from '@mui/icons-material/AdsClick';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InputFileUpload from "../../../components/renderField";
import LoadingButton from "@mui/lab/LoadingButton";
import MultiFileUpload from "../../../components/MultiFileUpload";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function RemoveCustomerClaim({removeClaim, Refresh,selectRows }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const openDialog = () => {
        if (selectRows.length < 1){
            setSnackbar({open: true, message: '请选择任意一条数据进行撤销!', severity: 'warning'});
        }else {
            setOpen(true);
        }
    };

    const totalClaimAmount = selectRows.reduce((sum, row) => sum + Number(row.Claim_amount), 0);

    const handleClose = () => setOpen(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // 创建 FormData 对象
        const data = new FormData();
        selectRows.forEach((row, index) => {
            // 假设后端可以通过 'ids[]' 键接收多个id
            data.append('ids[]', row.id);
        });
        try {
            const result = await removeClaim("PATCH", data);
            if (result?.status === 200) {
                setSnackbar({open: true, message: '撤销索赔成功!', severity: 'success'});
            }else {
                setSnackbar({open: true, message: '撤销索赔失败!', severity: 'error'});
            }
        } catch (e) {
            console.error("撤销索赔失败!", e);
        } finally {
            setLoading(false);
            Refresh()
            handleClose();
        }
    };

    return (
        <span>
            <Button sx={{textTransform: 'none'}} startIcon={<AdsClickIcon/>} onClick={openDialog}>撤销索赔</Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
              <DialogTitle>撤销索赔</DialogTitle>
              <DialogContent>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  {selectRows.map((row, index) => (
                      <ListItem key={index}>
                          UID: {row.UID}, Tracking Number: {row.Tracking_number}
                      </ListItem>
                  ))}
                </List>
                <Typography variant="subtitle1" align="right" sx={{ mt: 2 }}>
                  总索赔金额: {totalClaimAmount}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} variant="contained" sx={{ mt: 3, mb: 2 }}>
                  取消
                </Button>
                <LoadingButton loading={loading} onClick={handleSubmit} type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                  确认
                </LoadingButton>
              </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({...snackbar, open: false})}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                       sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </span>
    );
}

export default RemoveCustomerClaim;