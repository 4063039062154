// GridActionsCellItem.js
import React from 'react';
import {connect} from 'react-redux'
import PDFViewer from '../../components/PDFViewer';
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import PreviewIcon from '@mui/icons-material/Preview';
import {previewShipmentFile} from "../../redux/actions/shipment";
import store from "../../redux/store";
import {PREVIEW_SHIPMENT_OPEN} from "../../redux/type";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";


class PreViewModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: props['disabled'],
            ship_id: props['ship_id'],
        };
    }

    handleOpenModal = () => {
        // super.handleDefaultClick();  // 使用父类的方法
        this.props.previewShipmentFile(this.state.ship_id)
    }

    handleCloseModal = () => {
        store.dispatch({type: PREVIEW_SHIPMENT_OPEN})
    }

    render() {
        return (
            <div>
                <Tooltip title={"pre view"}>
                    <IconButton
                        onClick={this.handleOpenModal}
                        aria-label="Preview"
                        disabled={this.state.disabled}
                        color="primary"
                    >
                        <PreviewIcon />
                    </IconButton>
                </Tooltip>
                <PdfModal
                    isOpen={this.props.preview_modal_open}
                    onRequestClose={this.handleCloseModal}
                    pdfUrl={this.props.preview_pdf_url}
                />
            </div>
        );
    }
}

const PdfModal = ({isOpen, onRequestClose, pdfUrl}) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onRequestClose}
            maxWidth={'xl'}
            fullWidth={true}  // 使Dialog占据maxWidth所设置的宽度
            PaperProps={{
                style: { // 这里可以设置具体的高度和宽度
                    width: '60%',
                    height: '100%'
                }
            }}
        >
            <PDFViewer url={pdfUrl}/>
            <Button variant="outlined" onClick={onRequestClose}>
                Close
            </Button>
        </Dialog>
    );
}


const mapStateToProps = (state) => ({
    preview_pdf_url: state.shipment.preview_pdf_url,
    preview_modal_open: state.shipment.preview_modal_open
});

export default connect(mapStateToProps, {previewShipmentFile})(PreViewModal)