import * as React from 'react';
import {connect} from "react-redux";
import store from "../redux/store";
import {loadUser, register} from "../redux/actions/auth";
import {IS_USER, REGISTER_CLOSE_ALERT} from "../redux/type";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import {createTheme, ThemeProvider} from '@mui/material/styles';

import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import Copyright from "../components/Copyright";
import APICircularProgress from "../components/APICircularProgress";
import APIAlert from "../components/APIAlert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import TextField from "@mui/material/TextField";
import {AxiosDefaultRequest} from "../components/default_request";
import server from "../server";
import {Redirect} from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {FormGroup, FormLabel} from "@mui/material";
import Grid from "@mui/material/Grid";

const theme = createTheme();

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '', password: '', UserId: '', Pfn: '0',
            Key: '', srcUser: '', srcUserList: [],
            password_display: false,
            FG: false, FH: false, FS: false, UG: false
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.register(
            this.state.username, this.state.password,
            this.state.UserId, this.state.Key, this.state.srcUser, this.state.Pfn,
            {
                FEDEX_GROUND: this.state.FG,
                GROUND_HOME_DELIVERY: this.state.FH,
                SMART_POST: this.state.FS,
                Ground: this.state.UG,
            }
        );
    };
    closeAlert = () => store.dispatch({type: REGISTER_CLOSE_ALERT})
    handleClickShowPassword = () => this.setState({password_display: !this.state.password_display})

    UNSAFE_componentWillMount() {
        AxiosDefaultRequest({
            url: server.user_url + "?offset=0&limit=10000",
            success: res => {
                let options = [<option aria-label="None" key={'None'}/>];
                for (let i in res.data.rows) {
                    if (res.data.rows[i].id === 1) {
                        continue
                    }
                    options.push(<option aria-label="None" key={res.data.rows[i].id}
                                         value={res.data.rows[i].id}>{res.data.rows[i].username}</option>)
                }
                this.setState({srcUserList: options})
            }
        })
    }

    render() {
        let alert;
        if (!this.props.isAuthenticated) {
            // 1. dispatch 判断是否登录
            if (!store.getState().auth.isLoadUser) {
                this.props.loadUser()
                store.dispatch({type: IS_USER})
            }
        }
        let loading;
        if (this.props.isLoading) {
            loading = APICircularProgress('success')
        }
        if (this.props.alert) {
            const {level, title, message, timeout} = this.props.payload
            const alert_p = {
                level: level, title: title, message: message, options: null,
                closeFunc: this.closeAlert, timeout: timeout || 3000
            }
            alert = APIAlert(alert_p)
        }
        console.log(this.state.FG)
        return (
            <Box sx={{width: '100%'}}>{alert}
                {/*<Container component="main" maxWidth="xs">*/}
                {/*<CssBaseline/>*/}
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{m: 1, bgcolor: 'secondary.main', mr: 2}}>
                        <AppRegistrationIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5" sx={{mr: 2}}>
                        Register
                    </Typography>
                    <Box component="form" onSubmit={this.handleSubmit} sx={{mt: 5}}>
                        <Grid container>
                            <Grid container md={12} xs={12} lg={12}>
                                <Grid container item md={3.3} xs={3.3} lg={3.3}></Grid>
                                <Grid container item md={8} xs={8} lg={8}>
                                    <Grid container item md={4} xs={4} lg={4}>
                                        <FormControl variant="outlined" sx={{width: '100%', mb: 2}} required={true}>
                                            <InputLabel htmlFor="username">username</InputLabel>
                                            <OutlinedInput
                                                id="username" name="username" required
                                                value={this.state.username}
                                                onChange={(e) => {
                                                    this.setState({username: e.target.value})
                                                }}
                                                type="text"
                                                label="username"
                                            />
                                        </FormControl>
                                        {/*<FormControl variant="outlined" sx={{width: '100%'}}>*/}
                                        {/*    <InputLabel htmlFor="password">Password</InputLabel>*/}
                                        {/*    <OutlinedInput*/}
                                        {/*        id="password" name="password" required*/}
                                        {/*        value={this.state.password}*/}
                                        {/*        onChange={(e) => {*/}
                                        {/*            this.setState({password: e.target.value})*/}
                                        {/*        }}*/}
                                        {/*        type={this.state.password_display ? 'text' : 'password'}*/}
                                        {/*        endAdornment={*/}
                                        {/*            <InputAdornment position="end">*/}
                                        {/*                <IconButton*/}
                                        {/*                    onClick={(e) => {*/}
                                        {/*                        this.handleClickShowPassword(e)*/}
                                        {/*                    }}*/}
                                        {/*                    edge="end"*/}
                                        {/*                >*/}
                                        {/*                    {this.state.password_display ? <VisibilityOff/> : <Visibility/>}*/}
                                        {/*                </IconButton>*/}
                                        {/*            </InputAdornment>*/}
                                        {/*        }*/}
                                        {/*        label="password"*/}
                                        {/*    />*/}
                                        {/*</FormControl>*/}
                                        <FormControl variant="outlined" sx={{width: '100%'}} required={true}>
                                            <InputLabel htmlFor="uid">User Id</InputLabel>
                                            <OutlinedInput
                                                id="user_id" name="user_id" required
                                                value={this.state.UserId}
                                                onChange={(e) => {
                                                    this.setState({UserId: e.target.value})
                                                }}
                                                type="text"
                                                label="User Id"
                                            />
                                        </FormControl>
                                        <FormControl variant="outlined" sx={{width: '100%', mt: 2}} required={true}>
                                            <InputLabel htmlFor="Key">API Key</InputLabel>
                                            <OutlinedInput
                                                id="Key" name="Key" required
                                                value={this.state.Key}
                                                onChange={(e) => {
                                                    this.setState({Key: e.target.value})
                                                }}
                                                type="text"
                                                label="API Key"
                                            />
                                        </FormControl>
                                        <FormControl variant="outlined" sx={{width: '100%', mt: 2}} required={true}>
                                            <InputLabel htmlFor="Pfn">PFN</InputLabel>
                                            <OutlinedInput
                                                id="Pfn" name="Pfn" required
                                                value={this.state.Pfn}
                                                onChange={(e) => {
                                                    this.setState({Pfn: e.target.value})
                                                }}
                                                type="text"
                                                label="PFN"
                                            />
                                        </FormControl>
                                        <FormControl sx={{width: '100%', mt: 2}} variant="standard">
                                            <TextField
                                                id="SrcUser" select label="SrcUser" value={this.state.srcUser}
                                                onChange={(e) => {
                                                    this.setState({srcUser: e.target.value})
                                                }}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                helperText="Please select a SrcUser"
                                                variant="filled"
                                            >
                                                {this.state.srcUserList}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid container item md={4} xs={4} lg={4}>
                                        <FormControl sx={{m: 3}} component="fieldset" variant="standard">
                                            <FormLabel component="legend">Services</FormLabel>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox onChange={(e) => {
                                                    this.setState({FG: e.target.checked})
                                                }} checked={this.state.FG}/>} label="FEDEX Ground"/>
                                                <FormControlLabel control={<Checkbox onChange={(e) => {
                                                    this.setState({FH: e.target.checked})
                                                }} checked={this.state.FH}/>} label="FEDEX Ground Home Delivery"/>
                                                <FormControlLabel control={<Checkbox onChange={(e) => {
                                                    this.setState({FS: e.target.checked})
                                                }} checked={this.state.FS}/>} label="FEDEX Smart Post"/>
                                                <FormControlLabel control={<Checkbox onChange={(e) => {
                                                    this.setState({UG: e.target.checked})
                                                }} checked={this.state.UG}/>} label="UPS Ground"/>
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid container item md={5.2} xs={5.2} lg={5.2}></Grid>
                                <Grid container item md={1.5} xs={1.5} lg={1.5}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{mt: 3, mb: 2}}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
                {/*</Container>*/}
                {loading}
            </Box>)
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isLoading: state.RegisterReducer.isLoading,
    alert: state.RegisterReducer.alert,
    payload: state.RegisterReducer.content,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, {register, loadUser})(Register);
