import * as React from 'react';
import {connect} from "react-redux";
import Box from "@mui/material/Box";
import APICircularProgress from "../components/APICircularProgress";
import APIAlert from "../components/APIAlert";
import {OFFER_SHEET_OPEN, RATER_CLOSE_ALERT, RATER_ORDERS, RATER_STOP, SHIPPING_FEE_OPEN} from "../redux/type";
import store from "../redux/store";
import Grid from "@mui/material/Grid";
import StyledBox from "../components/StyledBox";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import APIToolBar from "../components/APIToolBar";
import {GridActionsCellItem, GridColDef} from "@mui/x-data-grid";

import SetVisibility from "../components/SetVisibility";
import SetColumns from "../components/SetColumns";
import './styles.scss'
import {RaterLoad} from "../redux/actions/RaterAction";
import ReorderIcon from '@mui/icons-material/Reorder';
import ShippingFeePage from "./modal/ShippingFeePageModal";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import OfferSheet from "./modal/OfferSheet";
import SearchTable from "../components/searchTable";
// 0:初始化,1:启用,2:停用,3:有异议
const status_chinese = {
    "0": "初始化", "1": "启用", "2": "停用", "3": "有异议"
}
const status_to_string = {
    "0": "init", "1": "enable", "2": "deactivate", "3": "objection"
}

const formatNumber = (value) => {
    if (typeof value === 'number') {
        return value.toFixed(2);
    }
    return value || '';
};

class Rater extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shippingFeeOpen: false,
            offerSheetOpen: false,
            Visibility: this.setVisibility,
            shippingFeeId: null
        }
    }

    columnVisibilityModel = { // 控制是否展示列
        id: false,
        download_file_name: false
    }

    localStorageKey = this.props.user.username + "_" + 'RaterColumns';
    localStorageVisibilityKey = this.props.user.username + "_" + 'RaterVisibility';
    setVisibility = SetVisibility(this.localStorageVisibilityKey, this.columnVisibilityModel)

    ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        // 获取columns
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
        // oldIndex > targetIndex
        // targetIndex < oldIndex
    }
    columns: GridColDef[] = [
        {id: 0, field: "id", headerName: "编号", flex: 5},
        {id: 1, field: "logistic", headerName: "渠道商", flex: 10},
        {id: 2, field: "service_name", headerName: "服务名", flex: 15},
        {id: 4, field: "year", headerName: "年份", flex: 5, hideable: false},
        {id: 5, field: "quarter", headerName: "季度", flex: 5},
        {id: 6, field: "PFN", headerName: "PFN", flex: 5, hideable: false},
        {id: 13, field: "quote_status", headerName: "状态", flex: 7, hideable: false, renderCell: (params) => {
                let chinese_status = status_chinese[params.row.quote_status]
                return (
                    <div className={`RaterCellWithStatus ${status_to_string[params.row.quote_status]}`}>
                        {chinese_status}
                    </div>
                )}
        },
        {id: 10, field: "mtw_dx", headerName: "MWT底销", flex: 10, hideable: false, type: 'number', valueFormatter: (params) => formatNumber(params.value)},
        {id: 11, field: "fedex_jp", headerName: "FEDEX计抛", flex: 10, hideable: false, type: 'number', valueFormatter: (params) => formatNumber(params.value)},
        {id: 12, field: "ups_jp", headerName: "UPS计抛", flex: 55, hideable: false, type: 'number', valueFormatter: (params) => formatNumber(params.value)},
        {
            id: 7, field: 'actions', type: 'actions', headerName: "操作", renderCell: (params) => {
                return [
                    <span key={params.row.id}>
                        <GridActionsCellItem
                            icon={<ReorderIcon/>}
                            label="运费信息"
                            title={"运费展示"}
                            color="primary"
                            sx={{mr: 1}}
                            onClick={() => {
                                store.dispatch({type: SHIPPING_FEE_OPEN, payload: {
                                    id: params.row.id, shippingFeeOpen: true,
                                    service_name: params.row.service_name
                                }})
                            }}
                        />
                        <GridActionsCellItem
                            icon={<MonetizationOnIcon/>}
                            label="附加费信息"
                            color="primary"
                            sx={{mr: 1}}
                            onClick={() => {
                                store.dispatch({type: OFFER_SHEET_OPEN, payload: {
                                    PFN: params.row.PFN, service_name: params.row.service_name, offerSheetOpen: true}})
                            }}
                        />
                    </span>
                ];
            }
        }
    ]
    set_columns = SetColumns(this.localStorageKey, this.columns)

    componentDidMount() { // 预加载
        this.props.RaterLoad(this.props.page, this.props.pageSize, null,
            null, store.getState().RaterReducer.search, null, null);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (store.getState().RaterReducer.stop && nextProps.search && !this.props.isLoading && nextProps.searchClick) {  // search
            store.dispatch({type: RATER_STOP})
            this.props.RaterLoad(this.props.page, this.props.pageSize, null,
                null, store.getState().RaterReducer.search, null, null);
        }
        // order
        if (nextProps.orders && nextProps.orderClick) {
            store.dispatch({type: RATER_STOP})
            this.props.RaterLoad(this.props.page, this.props.pageSize, null,
                null, store.getState().RaterReducer.search, null, nextProps.orders);
        }
        // request
        if (nextProps.request) {
            this.props.RaterLoad(this.props.page, this.props.pageSize, null,
                null, store.getState().RaterReducer.search, null, null);
        }
    }

    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(this.localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }
    useQuery = (page) => {
        this.props.RaterLoad(
            (page * this.props.pageSize), ((page + 1) * this.props.pageSize), page,
            null, store.getState().RaterReducer.search, null, this.props.orders)
    }
    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.RaterLoad(
                (this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, store.getState().RaterReducer.search, null, this.props.orders)
        }, 100);
    }
    closeAlert = () => {
        store.dispatch({type: RATER_CLOSE_ALERT})
    }
    ChangeColumnSortModel = (modal) => {
        // 触发 UNSAFE_componentWillReceiveProps
        store.dispatch({type: RATER_ORDERS, payload: modal})
    }

    render() {
        let loading;
        let alert;
        if (this.props.isLoading || this.props.ModalLoading || this.props.offerSheetpageLoading || this.props.shippingFeeIsLoading) {
            loading = APICircularProgress('success')
        }
        if (this.props.alert && (this.props.payload || this.props.offer_sheet_payload || this.props.shipping_fee_payload)) {
            const {level, title, message} = this.props.payload || this.props.offer_sheet_payload || this.props.shipping_fee_payload
            const alert_p = {level: level, title: title, message: message, options: null, closeFunc: this.closeAlert}
            alert = APIAlert(alert_p)
        }
        return (
            <Box sx={{width: '100%'}}>
                {loading}{alert}
                <Grid container>
                    <Grid container item md={12} lg={12}>
                        <Box sx={{minWidth: '100%'}}>
                            <SearchTable user={this.props.user}/>
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={12}>
                        <StyledBox>
                            {this.props.data ? <DataGridPro
                                className={"custom-centered-datagrid"}
                                rows={this.props.data}
                                columns={this.set_columns}
                                pageSize={this.props.pageSize}
                                rowsPerPageOptions={[20, 30, 50, 100, 200, 500]}
                                loading={this.props.TableLoading}
                                rowCount={this.props.rowCount}
                                paginationMode='server'
                                columnVisibilityModel={this.state.Visibility}
                                onColumnVisibilityModelChange={(newModel) =>
                                    this.ChangeColumnVisibilityModel(newModel)
                                }
                                checkboxSelection
                                headerHeight={70}
                                rowReordering={false}
                                onColumnOrderChange={(e) => {
                                    this.ColumnOrderChange(e)
                                }}
                                initialState={
                                    {pinnedColumns: {right: ['actions']}}
                                }
                                rowHeight={38}
                                components={{
                                    LoadingOverlay: LinearProgress,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    Toolbar: APIToolBar
                                }}
                                onPageChange={(page) => this.useQuery(page)}
                                onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                                pagination={true}

                                sortingMode="server"
                                // sortModel={this.set_sort}
                                onSortModelChange={(newSortModel) => this.ChangeColumnSortModel(newSortModel)}

                                // Number of extra columns to be rendered before/after the visible slice.
                                // 在可见切片之前/之后渲染的额外列数。
                                columnBuffer={3}
                                // Number of rows from the columnBuffer that can be visible before a new slice is rendered.
                                // 在呈现新切片之前可以看到的 columnBuffer 中的行数。
                                columnThreshold={3}
                            /> : ''}
                        </StyledBox>
                    </Grid>
                </Grid>
                {/*------------------------------- recallInvoice ---------------------------------------------*/}
                {this.props.shipping_fee_open ? <ShippingFeePage/> : ''}
                {this.props.offer_sheet_open ? <OfferSheet/> : ''}
                {/*------------------------------- recallInvoice ---------------------------------------------*/}
                <style>
                    {`
                        .custom-centered-datagrid .MuiDataGrid-columnHeaderTitleContainer {
                            justify-content: left !important;
                        }
                    
                        .custom-centered-datagrid .MuiDataGrid-columnHeaderTitle {
                            margin-right: 0 !important;
                            margin-left: 10 !important;
                        }
                        .custom-centered-datagrid .MuiDataGrid-cell {
                            display: flex;
                            align-items: left;
                            justify-content: left;
                        }
                    `}
                </style>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.RaterReducer.isLoading,
    TableLoading: state.RaterReducer.TableLoading,
    ModalLoading: state.RaterReducer.ModalLoading,
    request: state.RaterReducer.request,  // 据此判断是否刷新table
    alert: state.RaterReducer.alert,
    payload: state.RaterReducer.content,
    offer_sheet_payload: state.RaterReducer.offer_sheet_content,
    shipping_fee_payload: state.RaterReducer.shipping_fee_content,
    pageSize: state.RaterReducer.pageSize,
    page: state.RaterReducer.page,
    rowCount: state.RaterReducer.rowCount,
    data: state.RaterReducer.data,
    search: state.RaterReducer.search,
    searchClick: state.RaterReducer.searchClick,
    orders: state.RaterReducer.orders,
    orderClick: state.RaterReducer.orderClick,
    shipping_fee_open: state.RaterReducer.shipping_fee_open,
    shippingFeeIsLoading: state.RaterReducer.ShippingFeeisLoading,
    offerSheetpageLoading: state.RaterReducer.offerSheetpageLoading,

    offer_sheet_open: state.RaterReducer.offer_sheet_open,
    user: state.auth.user,
});

export default connect(mapStateToProps, {RaterLoad})(Rater)