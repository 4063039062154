import React, { useState, useEffect } from 'react';
import { Button, Modal, Container, Grid, Paper } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { DataGridPro } from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import CustomNoRowsOverlay from "../../../components/CustomNoRowsOverlay";
import {AxiosSyncDefaultRequest} from "../../../components/default_request";
import ToolBar from '../../../components/ToolBar'
import server from "../../../server";

const BackendShipRecordDialog =() => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(30);
    const [page, setPage] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [sortModal, setSortModal] = useState({});
    const [searchData, setSearchData] = useState({
        start_create_time: null, end_create_time: null,
    });
    const [columns, setColumns] = useState([
        {id: 0, field: "id", headerName: "Id"},
        {id: 1, field: "create_time", headerName: "录入时间", width: 200},
        {id: 2, field: "last_modify_time", headerName: "更新时间", width: 200},
        {id: 3, field: "customer_ref", headerName: "客户单号", width: 200},
        {id: 4, field: "status", headerName: "状态", width: 80, renderCell: (params) => {
            return JSON.parse(params.value.status_name_json).cn;
        }},
        {id: 5, field: "message", headerName: "信息", width: 250, renderCell: (params) => {
            var str = params.value
            var message;
            try {
                var obj = JSON.parse(str);
                message = obj.message;
            } catch (e) {
                message = str;
            }
            return message;
        }},
    ]);
    const [visibility, setVisibility] = useState({});
    const [search, setSearch] = useState('');
    useEffect(() => {
        if (open){
            fetchData(page, pageSize);
        }
    }, [open]);
    useEffect(() => {
        if (search){
            fetchData(page, pageSize, searchData, )
            setSearch(false);
        }
    }, [search])
    const fetchData = async (newPage=1, newPageSize=30, searchQuery=searchData, sortData=sortModal) => {
        try {
            const response = await AxiosSyncDefaultRequest({
                url: server.backend_ship_record_url,
                params: {
                    curr_page: newPage,
                    page_size: newPageSize,
                    // ...searchQuery,
                    // ...sortData,
                    // create_time_after: searchQuery.start_create_time ? searchQuery.start_create_time.toLocaleDateString("en-US") : null,
                    // create_time_before: searchQuery.end_create_time ? searchQuery.end_create_time.toLocaleDateString("en-US") : null
                }
            });
            setData(response.data.rows);
            setRowCount(response.data.total);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            // 可以在这里处理错误，例如设置错误消息
        }
    };
    const handleDateFieldChange = (fieldName) => (newValue) => {
        // 直接设置 Date 对象到状态中
        setSearchData({ ...searchData, [fieldName]: newValue });
    };
    // 页面改变处理
    const handlePageChange = (newPage) => {
        if (newPage === 0){
            newPage = 1;
        }
        if (page === 1 && newPage === 1){
            newPage = 2;
        }
        setLoading(true);
        setPage(newPage);
        setData([]);
        setRowCount(0);
        fetchData(newPage, pageSize);
    };

    // 页面大小改变处理
    const handlePageSizeChange = (newSize) => {
        setLoading(true);
        setPageSize(newSize);
        setData([]);
        setRowCount(0);
        fetchData(page, newSize);
    };

    // 搜索处理
    const handleSearch = (newSearch) => {
        setLoading(true);
        setData([]);
        setRowCount(0);
        setSearch(newSearch);
    };
    const Refresh = () => {
        setData([]);
        setLoading(true);
        fetchData(page, pageSize)
        setSearch(false);
    };
    const handleColumnOrderChange = (e) => {
        let newColumns = [...columns];
        const movedItem = newColumns.splice(e.oldIndex, 1)[0];
        newColumns.splice(e.targetIndex, 0, movedItem);
        setColumns(newColumns);
        // localStorage.setItem('yourLocalStorageKey', JSON.stringify(newColumns));
    };

    const handleColumnVisibilityChange = (newModel) => {
        // localStorage.setItem('yourLocalStorageVisibilityKey', JSON.stringify(newModel));
        setVisibility(newModel);
    };

    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { ordering };
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        setSortModal(sortData);
        await fetchData(page, pageSize, search, sortData);
    };

    return (
        <span>
      <Button startIcon={<ListAltIcon/>} onClick={() => setOpen(true)}>批量建单记录</Button>
      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="add-credit-title" aria-describedby="add-credit-description">
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {/* <Grid container spacing={3}> */}
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 800 }}>
                <DataGridPro
                    rows={data}
                    sx={{ minHeight: 750, overflow: 'auto', display: 'block' }}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[20, 30, 50, 100, 200, 500]}
                    autoHeight={true}
                    loading={loading}
                    rowCount={rowCount}
                    paginationMode='server'
                    columnVisibilityModel={visibility}
                    onColumnVisibilityModelChange={handleColumnVisibilityChange}
                    rowReordering={false}
                    onColumnOrderChange={handleColumnOrderChange}
                    onSortModelChange={handleSortModelChange}
                    components={{
                        LoadingOverlay: LinearProgress,
                        NoRowsOverlay: CustomNoRowsOverlay,
                        Toolbar: () => <ToolBar onRefresh={Refresh} />,
                    }}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    pagination={true}
                />
              </Paper>

          {/* </Grid> */}
        </Container>
      </Modal>
    </span>
    );
}

export default BackendShipRecordDialog;
