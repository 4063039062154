import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";

export default styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 800,
    '& .MuiFormGroup-options': {
        alignItems: 'center',
        paddingBottom: theme.spacing(1),
        '& > div': {
            minWidth: 100,
            margin: theme.spacing(2),
            marginLeft: 0,
        },
    },
}));