import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar} from '@mui/material';

import Grid from "@mui/material/Grid";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Alert from "@mui/material/Alert";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import {useDispatch, useSelector} from "react-redux";
import {RenderSelectField, RenderTextField} from "../../../../components/renderField";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const CreateWarehouseAndAccount= ({ onSave, setPdfOpen, setPdfUrl }) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [test_loading, setTestLoading] = useState(false);
    const [password_display, setPasswordDisplay] = useState(false);
    const user = useSelector((state) => state.auth.user);
    const [ship, setShip] = useState({"MID":user.UID, "Service": null,"Warehouse": null,"Name":"yishu luo","CompanyName":"yishu luo",
        "Address1":"3205 Wildwood Ct","Address2":"","Email":"lucasluo@awesung.com","City":"Monmouth Junction",
        "State":"NJ","PostalCode":"08852","Country":"US","PhoneNumber":"7245530433","PhoneExtension":"",
        "Reference":"4102-241008-0002","AncillaryEndorsement":"5",
        "Dimension":[{"length":10, "width":10, "height":10, "weight":26.448,"qty":1,"goodsName":""}],"Zip":false,"ImageType":"PDF"})
    const [formData, setFormData] = useState({
        supplier: null,
        region: '',
        name: 'System',
        account_user: '',
        // account_password: '',
        account_kwargs_1: '',
        // account_kwargs_2: '',
        // account_kwargs_3: '',
        // account_kwargs_4: '',
        // account_kwargs_5: '',
        account_kwargs_6: '',
        account_kwargs_7: '',
        isDebug: false,
        isDefault: false,
        isSystemDefault: false,
        optional: '{}',

    });
    const [statusFormData, setStatusFormData] = useState({
        supplier: {required: true, message: '', status: false},
        region: {required: true, message: '', status: false},
        name: {required: true, message: '', status: false},
    })
    const [FUstatusFormData, setFUStatusFormData] = useState({
        account_user: {required: true, message: '', status: false},
        account_kwargs_6: {required: true, message: '', status: false},
        account_kwargs_7: {required: true, message: '', status: false},
        isDebug: {required: true, message: '', status: false},
        isDefault: {required: true, message: '', status: false},
        isSystemDefault: {required: true, message: '', status: false},
        optional: {required: true, message: '', status: false},
    })
    const onClose = () => setOpenCreateDialog(false);
    const onOpen = () => setOpenCreateDialog(true);
    const [supplier_options, setSupplierOptions] = useState([]);
    const [region_options, setRegionOptions] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_supplier_list}`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setSupplierOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchWarehouseData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.warehouse_list_url}`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({warehouse_number: rows[i].warehouse_number, name: rows[i].name})
                        }
                        setRegionOptions(options)
                    }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        }
        fetchData();
        fetchWarehouseData();
    }, [dispatch]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const handleFieldChange = (e, value, key, value_name) => {

            if(key === "supplier"){
                if(value){
                    if(value[value_name] === 1){
                    setFormData((prev) => ({
                        ...prev,
                        [key]: value[value_name],
                        account_kwargs_6: "l73ac7086503044b9a9f4804fbd4d86634",
                        account_kwargs_7: "3dfe38436680486aa2ac9fd602520a7c"
                    }));
                    setShip((prev) => ({
                        ...prev,
                        Service: "4",
                    }));
                    }
                    else if(value[value_name] === 2){
                        setFormData((prev) => ({
                            ...prev,
                            [key]: value[value_name],
                            account_kwargs_6: "",
                            account_kwargs_7: ""
                        }));
                        setShip((prev) => ({
                            ...prev,
                            Service: "6",
                        }));
                    }
                    else {
                        setFormData((prev) => ({
                            ...prev,
                            [key]: value[value_name],
                        }));
                        setShip((prev) => ({
                            ...prev,
                            Service: null,
                        }));
                    }

                }
                else {
                    setFormData((prev) => ({
                        ...prev,
                        [key]: value?value[value_name]:'',
                    }));
                    setShip((prev) => ({
                        ...prev,
                        Service: null,
                    }));
                }

            }
            else if(key === "region"){
                setShip((prev) => ({
                    ...prev,
                    Warehouse: value?value.warehouse_number:null,
                }));
                setFormData((prev) => ({
                    ...prev,
                    [key]: value?value[value_name]:'',
                }));

            }
            else {
                setFormData(prevState => ({
                    ...prevState,
                    [key]: value?value[value_name]:''
                }));
            }


    }
    const handleChange = (event) => {
        const { name, value } = event.target;


            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));

    };

    const test_ship = async () => {
        let is_save = true
        if(ship.Warehouse === null){
            setStatusFormData((prev) => ({
                ...prev,
                region: {required: statusFormData.region.required, message: "This field is required", status: true},
            }));
            is_save = false
        }
        else {
            setStatusFormData((prev) => ({
                ...prev,
                region: {required: statusFormData.region.required, message: "", status: false},
            }));
        }
        if (ship.Service === null){
            setStatusFormData((prev) => ({
                ...prev,
                supplier: {required: statusFormData.supplier.required, message: "This field is required", status: true},
            }));
            is_save = false
        }
        else {
            setStatusFormData((prev) => ({
                ...prev,
                supplier: {required: statusFormData.supplier.required, message: "", status: false},
            }));
        }
        if(!is_save){
            return
        }
        setTestLoading(true)
        try {

         await AxiosSyncDefaultRequest({
            url: "/V1/get-label",
            method: "POST",
            data: ship,
            headers: {
                "WEB-USER": user.username
            },
            success: (res) => {
                let data = res.data
                setTestLoading(false)
                if(data.code !== 2000){
                    setSnackbar({open: true, message: data.message, severity: "error"})
                }
                else {
                    let download_url = data.download_url
                    let new_download_url
                    if(server.test){
                        new_download_url = download_url.replace("http://awesung-api.com", `${server.test_host}:${server.post}`)
                    }
                    else {
                        new_download_url = download_url.replace("http://awesung-api.com", `${server.host}`)
                    }
                    setPdfUrl(new_download_url)
                    setPdfOpen(true)
                }
            },
             fail: (error) => {
                 console.error('请求失败', error);
                 setSnackbar({open: true, message: error, severity: "error"});
                 setTestLoading(false);
             }
        })
        }
        catch (error){
            setTestLoading(false);
            setSnackbar({open: true, message: error, severity: "error"});
        }
    }

    const handleSave = async () => {
        let data = JSON.parse(JSON.stringify(formData));
        Object.entries(formData).forEach(([key, value]) => {
            if(value === '' || value === null){
                delete data[key]
            }
        });

        const newErrors = {};
        let is_valid = true;

        // 检查所有必填字段是否已填写
        for (let field in statusFormData) {
            if (statusFormData[field].required && !formData[field]) {
                newErrors[field] = {
                    ...statusFormData[field],
                    message: 'This field is required',
                    status: true
                };
                is_valid = false;
            } else {
                newErrors[field] = {
                    ...statusFormData[field],
                    message: '',
                    status: false
                };
            }

        }
        // 如果有错误，更新状态并停止保存操作
        setStatusFormData({
            ...statusFormData,
            ...newErrors
        });
        if(formData.supplier === 1 || formData.supplier === 2){
            const FUnewErrors = {};
            for (let field in FUstatusFormData) {
                if (field.includes("is")){
                    if(typeof formData[field] !== 'boolean'){
                        FUnewErrors[field] = {
                            ...FUstatusFormData[field],
                            message: 'This field is required',
                            status: true
                        };
                        is_valid = false;
                    }
                    else {
                        FUnewErrors[field] = {
                            ...FUstatusFormData[field],
                            message: '',
                            status: false
                        };
                    }

                }
                else {
                    if (FUstatusFormData[field].required && !formData[field]) {
                        FUnewErrors[field] = {
                            ...FUstatusFormData[field],
                            message: 'This field is required',
                            status: true
                        };
                        is_valid = false;
                    } else {
                        FUnewErrors[field] = {
                            ...FUstatusFormData[field],
                            message: '',
                            status: false
                        };
                    }
                }
            }
            // 如果有错误，更新状态并停止保存操作
            setFUStatusFormData({
                ...FUstatusFormData,
                ...FUnewErrors
            });
        }

        if (!is_valid) {
            return;
        }
        let form_data = {}
            if(formData.supplier === 1){
                form_data = data
            }
            else if(formData.supplier === 2){
                delete data["account_kwargs_1"]
                form_data = data
            }
            else {
                form_data["supplier"] = formData.supplier
                form_data["region"] = formData.region
                form_data["name"] = formData.name
            }

            setLoading(true);
            try {

                const result = await onSave(form_data, null, 'POST');
                if (result.status === true) {
                    setFormData({
                        supplier: null,
                        region: '',
                        name: 'System',
                        account_user: '',
                        // account_password: '',
                        account_kwargs_1: '',
                        // account_kwargs_2: '',
                        // account_kwargs_3: '',
                        // account_kwargs_4: '',
                        // account_kwargs_5: '',
                        account_kwargs_6: '',
                        account_kwargs_7: '',
                        isDebug: false,
                        isDefault: false,
                        isSystemDefault: false,
                        optional: '{}',
                    })
                    setShip((prev) => ({
                        ...prev,
                        Warehouse: null,
                        Service: null,
                    }));
                    // 操作成功，显示成功消息
                    // setSnackbar({open: true, message: '保存成功！', severity: 'success'});
                }
            } catch (error) {
                console.error("保存失败", error);
                // 操作失败，显示错误消息
                // setSnackbar({ open: true, message: '保存失败,请重试.', severity: 'error' });
            } finally {
                setLoading(false);
                onClose(); // 保存操作完成后关闭对话框
            }


    };
    const commentFormFields = () => {
        return (
            <>
                <
                    TextField
                    margin="dense"
                    name="account_user"
                    label="账号名"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formData.account_user}
                    onChange={handleChange}
                    required={FUstatusFormData.account_user.required}
                    helperText={FUstatusFormData.account_user.message}
                    error={FUstatusFormData.account_user.status}
                />
                <TextField
                    margin="dense"
                    name="account_kwargs_6"
                    label="API Key"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formData.account_kwargs_6}
                    onChange={handleChange}
                    required={FUstatusFormData.account_kwargs_6.required}
                    helperText={FUstatusFormData.account_kwargs_6.message}
                    error={FUstatusFormData.account_kwargs_6.status}
                />
                <RenderTextField
                    margin={"dense"}
                    idAname={"account_kwargs_7"}
                    label={"Secret Key"}
                    type={password_display ? 'text' : 'password'}
                    width={12}
                    form_width={'100%'}
                    variant={"standard"}
                    value={formData.account_kwargs_7}
                    onChangeHandler={handleChange}
                    required={FUstatusFormData.account_kwargs_7.required}
                    helperText={FUstatusFormData.account_kwargs_7.message}
                    error={FUstatusFormData.account_kwargs_7.status}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={(e) => {handleClickShowPassword()}}
                                    edge="end"
                                >
                                    {password_display ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    margin="dense"
                    name="isDefault"
                    label="是否默认账号"
                    type="text"
                    fullWidth
                    select
                    variant="standard"
                    value={formData.isDefault}
                    onChange={handleChange}
                    required={FUstatusFormData.isDefault.required}
                    helperText={FUstatusFormData.isDefault.message}
                    error={FUstatusFormData.isDefault.status}
                >

                    <MenuItem key="isDefault_1" value={true}>
                        是
                    </MenuItem>
                    <MenuItem key="isDefault_0" value={false} selected>
                        否
                    </MenuItem>
                </TextField>


                <TextField
                    margin="dense"
                    name="isDebug"
                    label="是否测试账号"
                    type="text"
                    fullWidth
                    select
                    variant="standard"
                    value={formData.isDebug}
                    onChange={handleChange}
                    required={FUstatusFormData.isDebug.required}
                    helperText={FUstatusFormData.isDebug.message}
                    error={FUstatusFormData.isDebug.status}
                >

                    <MenuItem key="isDebug_1" value={true}>
                        是
                    </MenuItem>
                    <MenuItem key="isDebug_0" value={false} selected>
                        否
                    </MenuItem>
                </TextField>
                <TextField
                    margin="dense"
                    name="isSystemDefault"
                    label="是否系统默认账号"
                    type="text"
                    fullWidth
                    variant="standard"
                    select
                    value={formData.isSystemDefault}
                    onChange={handleChange}
                    required={FUstatusFormData.isSystemDefault.required}
                    helperText={FUstatusFormData.isSystemDefault.message}
                    error={FUstatusFormData.isSystemDefault.status}
                >

                    <MenuItem key="isSystemDefault_1" value={true}>
                        是
                    </MenuItem>
                    <MenuItem key="isSystemDefault_0" value={false} selected>
                        否
                    </MenuItem>
                </TextField>
                <TextField
                    margin="dense"
                    name="optional"
                    label="其他参数"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formData.optional}
                    onChange={handleChange}
                    required={FUstatusFormData.optional.required}
                    helperText={FUstatusFormData.optional.message}
                    error={FUstatusFormData.optional.status}
                />
            </>
        );
    }
    const renderFormFields = () => {
        if(formData.supplier === 1){
            return(
                <>

                    <TextField
                        margin="dense"
                        name="account_kwargs_1"
                        label="参数1(FEDEX HubID)"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={formData.account_kwargs_1}
                        onChange={handleChange}
                    />
                    {commentFormFields()}
                </>
            )
        }
        else if(formData.supplier === 2){
            return commentFormFields()
        }
    }
    const test_buttion = () => {
        return <LoadingButton onClick={test_ship} variant={"contained"} loading={test_loading}>测试打单</LoadingButton>
    }
    const handleClickShowPassword = () => setPasswordDisplay(!password_display);
    return (
        <>

            <Button sx={{textTransform: 'none'}} startIcon={<WarehouseIcon/>} onClick={() => {onOpen()}}>
                新增渠道账号
            </Button>
            <Dialog open={openCreateDialog} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>新增渠道账号</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                idAname="supplier"
                                label="渠道商"
                                value={formData.supplier}
                                select_options={supplier_options}
                                onChangeHandler={(e, value) => {
                                    handleFieldChange(e, value, "supplier", "id")
                                }}
                                v={"id"}
                                value_name={"name"}
                                sx={{width: 350}}
                                required={statusFormData.supplier.required}
                                helperText={statusFormData.supplier.message}
                                error={statusFormData.supplier.status}
                            />
                            <RenderSelectField
                                idAname="region"
                                label="口岸"
                                value={formData.region}
                                select_options={region_options}
                                onChangeHandler={(e, value) => {
                                    handleFieldChange(e, value, "region", "name")
                                }}
                                v={"name"}
                                value_name={"name"}
                                sx={{width: 350}}
                                required={statusFormData.region.required}
                                helperText={statusFormData.region.message}
                                error={statusFormData.region.status}
                            />
                            <TextField
                                margin="dense"
                                name="name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                                required={statusFormData.name.required}
                                helperText={statusFormData.name.message}
                                error={statusFormData.name.status}
                            />
                            {formData.supplier ? renderFormFields() : ""}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {(ship.Warehouse && ship.Service) ? test_buttion() : ""}
                    <Button onClick={onClose} variant={"contained"}>取消</Button>
                    <LoadingButton loading={loading} onClick={handleSave} type={'submit'}>保存</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>

    );
};

export default CreateWarehouseAndAccount;
