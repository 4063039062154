import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StyledBox from "../../components/StyledBox";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";

import {useDispatch} from "react-redux";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import APIToolBar from "../../components/APIToolBar";
import ToolBar from "../../components/ToolBar";
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";

const UserPackageSpecialServices = "UserPackageSpecialServices";


function UserPackageSpecialServicesPage () {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, SetSearchData] = useState({});
    const dispatch = useDispatch();
    const [Visibility, setVisibility] = useState({
        id: false,
    })
    const columns = [
        {id: 1, field: "id", headerName: "ID", flex: 5},
        {id: 2, field: "user__username", headerName: "用户名", flex: 8, renderCell: (params) => {
                return params.row.user.username;
            }},
        {id: 3, field: "user__UID", headerName: "用户UID", flex: 8, renderCell: (params) => {
                return params.row.user.UID;
            }},
        {id: 4, field: "package_special_service__name", headerName: "特殊服务名称", flex: 8, renderCell: (params) => {
                return params.row.package_special_service.name;
            }},
        {id: 5, field: "package_special_service__note", headerName: "备注", flex: 5, renderCell: (params) => {
                return params.row.package_special_service.note;
            }},
        {id: 6, field: "package_special_service__description", headerName: "说明", flex: 5, renderCell: (params) => {
                return params.row.package_special_service.description;
            }},
        {id: 7, field: "is_permit", headerName: "是否启用", flex: 5}
    ];

    const ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
    }
    const fetchData = async (sortData = {}) => {
        try {
            const queryParams = {
                ...searchData, // 将搜索条件作为查询参数
                ...sortData,
                curr_page: currentPage < 1 ? 1 : currentPage,
                page_size: pageSize,
            };
            let requestUrl = server.user_package_special_service_list;
            requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);

            await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: 'GET',
                success: (res) => {
                    setData(res.data.rows)
                    setLoading(false);
                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([])
                    setLoading(false)
                }
            });
        } catch (error) {
            console.error('请求异常', error);
        }
    };
    useEffect(() => {
        setLoading(true)

        // 调用上面定义的函数来加载数据
        fetchData();
    }, [currentPage, pageSize, dispatch]);
    const Refresh = () => {
        // setData([]);
        setData([]);
        setLoading(true);
        fetchData();

    }
    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { ordering };
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        await fetchData(sortData);
    };
    const onChangeColumnVisibilityModel = (newVisibility) => {
        // if (user.is_open_claim !== "4") {
        //     newVisibility = { ...newVisibility, Check_amount: false };
        // }
        setVisibility(newVisibility);
    };
    return (
        <Box sx={{
            width: '100%'
        }}>
            <Grid container>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection={false}
                            headerHeight={70}
                            loading={loading}
                            rowCount={data.length}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            onSortModelChange={handleSortModelChange}
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>
        </Box>
    )
}
export default UserPackageSpecialServices;
export {UserPackageSpecialServicesPage};