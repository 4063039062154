/**
 * 构建带查询参数的URL。
 * @param {string} baseUrl 基础URL。
 * @param {Object} params 包含查询参数的对象。
 * @returns {string} 构建好的带查询参数的URL。
 */
export function buildUrlWithQueryParams(baseUrl, params = {}) {
    const searchParams = new URLSearchParams();

    // 遍历参数对象，将每个参数添加到查询字符串中
    Object.entries(params).forEach(([key, value]) => {
        // 只考虑非空值
        if (value !== null && value !== undefined && value !== '') {
            searchParams.append(key, value);
        }
    });

    // 如果有查询参数，则添加到baseUrl中
    return `${baseUrl}?${searchParams.toString()}`;
}
