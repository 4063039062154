import {
    CHANGE_PASSWORD_CLOSE_ALERT,
    CHANGE_PASSWORD_LOADING,
    LOGIN_CLOSE_ALERT,
    LOGIN_ERR,
    LOGIN_LOADING, LOGOUT_SUCCESS,
    OUT,
    SET
} from "../type";

const initialState = {
    isLoading: true,
    alert: false,
    content: null
};

export default function logInReducer(preState=initialState, action) {
    const {type, payload} = action
    switch (type) {
        case SET:
            return (preState = {
                ...preState,
                isLoading: false,
                alert: true,
                content: payload
            })
        case LOGIN_LOADING:
        case CHANGE_PASSWORD_LOADING:
            return (preState = {
                ...preState,
                isLoading: true
            })
        case LOGIN_ERR:
            return (preState = {
                ...preState,
                isLoading: false,
                alert: true,
                content: payload
            })
        case LOGIN_CLOSE_ALERT:
        case CHANGE_PASSWORD_CLOSE_ALERT:
            return (preState = {
                ...preState,
                alert: false
            })
        case LOGOUT_SUCCESS:
            return (preState = {
                ...preState,
                isLoading: false,
                alert: false
            })
        default:
            return preState
    }
}

