import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {Box} from "@mui/material";
// error warning info success
export default (props) => {
    const {level, title, message, options, closeFunc} = props

    setTimeout(() => {
        closeFunc()
    }, props.timeout || 3000)

    return (
        <Box sx={{
            zIndex: 9998,
            position: 'absolute',
            top: '10%',
            left: '27%',
            width: `50%`,
        }}>
                <Alert onClose={closeFunc} severity={level}>
                    <AlertTitle>{title}</AlertTitle>
                    {message}{options}
                </Alert>
        </Box>)
}
