import * as React from 'react';
import {connect} from "react-redux";
import {Redirect, Route, Switch} from 'react-router-dom';

import {styled, createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import MainListItems from "../components/listItems";
import Copyright from "../components/Copyright";
import APICircularProgress from "../components/APICircularProgress";

import User from "./User";
import Shipment from "./Shipment";
import Admin from './Admin'
import Bill from "./Bill";
import Account from "./AccountManagement/Account";
import Rater from './Rater';

import {zhCN} from '@mui/x-data-grid-pro';
import {zhCN as coreCN} from '@mui/material/locale';
import {changePage} from "../redux/actions/indexAction";
import {SHIPMENT} from "../redux/type";
import {Logout} from "../redux/actions/auth";
import AccountMenu from "../components/AccountMenu";
import APIAlert from "../components/APIAlert";
import Settings from "./Settings";
import {LogisticSupplierPage} from "./logisticSupplierManagement/logisticSupplierPage";
import {WarehousePage} from "./logisticSupplierManagement/warehouse";
import {LogisticServicePage} from "./logisticSupplierManagement/logisticService";
import {ServiceAndUserPage} from "./logisticSupplierManagement/serviceAndUser";
import {ServiceAndSpecialPage} from "./logisticSupplierManagement/serviceAndSpecial";
import {UserPackageSpecialServicesPage} from "./logisticSupplierManagement/userPackageSpecialServices";
import {ServiceAndWarehousePage} from "./logisticSupplierManagement/serviceAndWarehouse";
import {WarehouseAndAccountPage} from "./logisticSupplierManagement/warehouseAndAccount";

import {ClaimManagementPage} from "./claimManagement/ClaimManagementPage"
import {InternalClaimPage} from "./claimManagement/InternalClaimPage"
import {CustomerClaimsPage} from "./claimManagement/CustomerClaimPage"
import {FBAAddressPage} from "./FBAAddress/FBAAddress";
import {FreightShipmentPage} from "./FreightShipment/FreightShipment"
import {ZonePage} from "./logisticSupplierManagement/zone/zone";
import {PickupPage} from "./pickup/Pickup";
import {FuelSurchargePage} from "./FuelSurcharge/FuelSurchargePage"

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),);

const mdTheme = createTheme({
        palette: {
            primary: {main: '#0a84f1'}
        },
    },
    zhCN,
    coreCN
);

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true
            // isRedirect: false
        }
    }

    //
    setOpen = () => {
        console.log("Before toggle:", this.state.open);
        this.setState({open: !this.state.open}, () => {
            console.log("After toggle:", this.state.open);
        });
    }

    ChangePage = (toPage) => {
        const propsPath = this.props.history.location.pathname.split('/')[2]
        this.props.changePage(propsPath, toPage)
    }

    UNSAFE_componentWillMount() {
        const current = localStorage.getItem('current')
        if (!current) { // 第一次访问本站
            this.props.changePage(SHIPMENT, this.props.history)
        }
        this.props.changePage(current, this.props.history)
    }

    render() {
        let loading;
        let alert;
        if (!this.props.isAuthenticated) {
            return <Redirect to="/login/"/>
        }
        if (this.props.isLoading) {
            loading = APICircularProgress('secondary')
        }
        if (this.props.Alert.open) {
            alert = APIAlert(this.props.Alert)
        }
        let currentUrl = localStorage.getItem('currentUrl');
        return (
            <ThemeProvider theme={mdTheme}>
                {alert}
                {loading}
                <Box sx={{display: 'flex'}}>
                    <CssBaseline/>
                    {/*header*/}
                    <AppBar position="absolute" open={this.state.open}>
                        <Toolbar
                            sx={{
                                pr: '24px', // keep right padding when drawer closed
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.setOpen}
                                sx={{
                                    marginRight: '36px',
                                    ...(this.state.open && {display: 'none'}),
                                }}
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{flexGrow: 1}}
                            >
                                API
                            </Typography>
                            <Box sx={{flexGrow: 0}}>
                                <AccountMenu history={this.props.history}/>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Drawer variant="permanent" open={this.state.open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={this.setOpen}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </Toolbar>
                        <Divider/>
                        <MainListItems history={this.props.history} changePage={this.ChangePage} drawerOpen={this.state.open}/>
                    </Drawer>
                    <Box component="main" sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}>
                        <Toolbar/>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper sx={{p: 2, display: 'flex', flexDirection: 'column', top: 0, paddingTop: 0.5, width: '100%'}}>
                                <Switch>
                                    <Route component={User} path='/index/user' title='User'/>
                                    <Route component={Shipment} path='/index/shipment' title='Shipment'/>
                                    <Route component={Bill} path='/index/bill' title='Bill'/>
                                    <Route component={Settings} path='/index/settings' title='Settings'/>
                                    <Route component={Account} path='/index/account' title='Account'/>
                                    <Route component={Admin} path='/index/admin' title='Admin'/>

                                    <Route exact path='/index/claims' component={ClaimManagementPage} title='ClaimManagement'/>
                                    <Route path='/index/claims/internal' component={InternalClaimPage} title='internalClaim'/>
                                    <Route path='/index/claims/customer' component={CustomerClaimsPage} title='customerClaim'/>

                                    <Route exact path='/index/logistic_supplier' component={LogisticSupplierPage} title='LogisticsSupplier'/>
                                    <Route path="/index/logistic_supplier/warehouse" component={WarehousePage} title='Warehouse'/>
                                    <Route path="/index/logistic_supplier/logistics_service" component={LogisticServicePage} title='LogisticsService'/>
                                    <Route path="/index/logistic_supplier/service_and_user" component={ServiceAndUserPage} title='ServiceAndUser'/>
                                    <Route path="/index/logistic_supplier/service_and_special" component={ServiceAndSpecialPage} title='ServiceAndSpecial'/>
                                    <Route path="/index/logistic_supplier/user_package_special_services" component={UserPackageSpecialServicesPage} title='UserPackageSpecialServices'/>
                                    <Route path="/index/logistic_supplier/service_and_warehouse" component={ServiceAndWarehousePage} title='ServiceAndWarehouse'/>
                                    <Route path="/index/logistic_supplier/warehouse_and_account" component={WarehouseAndAccountPage} title='WarehouseAndAccount'/>

                                    <Route path="/index/FBAAddress" component={FBAAddressPage} title='FBAAddress'/>
                                    <Route component={FreightShipmentPage} path='/index/freight_shipment' title='FreightShipment'/>
                                    <Route path="/index/pickup" component={PickupPage} title='Pickup'/>
                                    <Route component={ZonePage} path='/index/zone' title='zone'/>
                                    <Route component={FuelSurchargePage} path='/index/fuel_surcharge' title='fuel_surcharge'/>

                                    <Route component={Rater} path='/index/rater' title='rater'/>
                                    <Redirect to={currentUrl ? currentUrl : '/index/user'}/>
                                </Switch>
                            </Paper>
                        </Grid>
                        <Copyright sx={{pt: 2}}/>
                    </Box>
                </Box>
            </ThemeProvider>)
    }

}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    isLoading: state.auth.isLoading,
    current: state.indexReducer.current,
    searchTable: state.indexReducer.searchTable,
    Alert: state.alertReducer
});

export default connect(mapStateToProps, {Logout, changePage})(Home);