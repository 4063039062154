import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StyledBox from "../../components/StyledBox";
import {
    DataGridPro,
    GridColumnOrderChangeParams,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector
} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import server from "../../server";
import {AxiosSyncDefaultRequest} from '../../components/default_request'
import {ServiceBar} from './searchBar/ServiceBar'
import CreateService from './service/Dialog/createService'
import {buildUrlWithQueryParams} from '../../components/buildUrlWithQueryParams'
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import Switch from '@mui/material/Switch';
import {randomPhoneNumber} from "@mui/x-data-grid-generator";
import Alert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import EditFormDialog from "./service/Dialog/EditDialog";

const LogisticService = "LogisticService";

function LogisticServicePage() {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState(false);
    const [searchData, setSearchData] = useState({name: '', supplier: '', service_type: ''});
    const dispatch = useDispatch()
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const handleOpenEditDialog = (row) => {
        setSelectedRow(row);
        setOpenEditDialog(true);
    };

    const [Visibility, setVisibility] = useState({
        id: false
    })
    const [checked, setChecked] = React.useState({
        is_enable: {},
        international: {},
        is_much: {},
        is_debug: {},
        is_show: {}

    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const  handleSave = (editedData, rowId, method = 'PUT', refresh = true) => {
        // 直接返回 fetchData 函数调用的 Promise
        let result = {status: false, message: ''};
        return fetchData(method, editedData, rowId
        ).then((e) => {
            if (refresh){
                Refresh(false)
            }

            result.status = e !== undefined;
            return result;
        }).catch((error) => {
            result.status = false;
            result.message = error.response.message;
            return result;
        }).finally(() => {
            return result;
        });
    }
    const fetchData = async (method, data = {}, rowId = null, search_data={}, open = true, sortData={}) => {
        try {
            if(search_data){
                Object.entries(search_data).forEach(([key, value]) => {
                    searchData[key] = search_data[key]
                });
            }
            const queryParams = {
                ...searchData, // 将搜索条件作为查询参数
                ...sortData,
                curr_page: currentPage < 1 ? 1 : currentPage,
                page_size: pageSize,
            };

            let requestUrl = server.logistics_service_list;
            let requestData = {};
            let msg = "获取"
            if (method === 'GET') {
                requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);
            } else if (method === 'PUT' && rowId != null) {
                msg = "修改"
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = data;
            } else if (method === "DELETE") {
                msg = "删除"
                requestUrl = `${requestUrl}${rowId}/`;
            } else if (method === "POST") {
                msg = "创建"
                requestData = data;
                console.log("requestData", requestData)
            }

            return await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                success: (res) => {
                    // setTotalRows(1)

                    if (method === 'GET') {
                        let rows = res.data.rows
                        let is_enable = {};
                        let international = {};
                        let is_much = {};
                        let is_debug = {};
                        let is_show = {}
                        setData(rows)
                        for (let i in rows) {

                            is_enable[rows[i]["id"]] = rows[i]["is_enable"]
                            international[rows[i]["id"]] = rows[i]["international"]
                            is_much[rows[i]["id"]] = rows[i]["is_much"]
                            is_debug[rows[i].id] = rows[i]["is_debug"]
                            is_show[rows[i].id] = rows[i]["is_show"]

                        }
                        setLoading(false);
                        setChecked({
                            is_enable,
                            international,
                            is_much,
                            is_debug,
                            is_show
                        })
                        setTotalRows(res.data.total);
                    }
                    if(open) {
                        setSnackbar({open: true, message: `${msg}成功！`, severity: 'success'});
                    }
                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // throw error
                }
            });
        } catch (error) {
            console.error('请求异常', error);
        }
    };
    const handleChange = (event, id, key) => {
        handleSave({[key]: event.target.checked}, id, "PUT", false).then(
            setChecked((prev) => ({
                ...prev,
                [key]: {
                    ...prev[key],
                    // ...prev.international,
                    // ...prev.is_enable,
                    // ...prev.is_debug,
                    // ...prev.is_show,
                    [id]: event.target.checked
                },
            }))
        ).catch((error) => {
            setSnackbar({open: true, message: error.response.message, severity: 'error'});
        })

    };
    const columns = [
        {id: 1, field: "id", headerName: "ID", flex: 5},
        {id: 2, field: "name", headerName: "服务名", flex: 8},
        {id: 3, field: "code", headerName: "服务设定码", flex: 5},
        {id: 4, field: "service_number", headerName: "使用号", flex: 5},
        {id: 5, field: "is_much", headerName: "是否一票多件", flex: 5,
            renderCell: (params) => {
                return <Switch
                    checked={checked["is_much"][params.row.id]}
                    onChange={(e) => handleChange(e, params.row.id, "is_much")}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }
        },
        {
            id: 6, field: "supplier", headerName: "物流商", flex: 5, renderCell: (params) => {
                return params.row.supplier.name;
            }
        },
        {
            id: 7, field: "service_type", headerName: "服务类型", flex: 5, renderCell: (params) => {
                return params.row.service_type.name;
            }
        },
        {id: 8, field: "international", headerName: "是否国际服务", flex: 5, renderCell: (params) => {
                return <Switch
                    checked={checked["international"][params.row.id]}
                    onChange={(e) => handleChange(e, params.row.id, "international")}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }},
        {
            id: 9, field: "is_enable", headerName: "启用状态", flex: 5, renderCell: (params) => {
                return <Switch
                    checked={checked["is_enable"][params.row.id]}
                    onChange={(e) => handleChange(e, params.row.id, "is_enable")}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }
        },
        {id: 10, field: "description", headerName: "描述", flex: 5},
        {id: 11, field: "is_debug", headerName: "测试服务", flex: 5,
            renderCell: (params) => {
                return <Switch
                    checked={checked["is_debug"][params.row.id]}
                    onChange={(e) => handleChange(e, params.row.id, "is_debug")}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }
        },
        {id: 12, field: "is_show", headerName: "是否显示", flex: 5,
            renderCell: (params) => {
                return <Switch
                    checked={checked["is_show"][params.row.id]}
                    onChange={(e) => handleChange(e, params.row.id, "is_show")}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }
        },

        {id: 13, type: 'actions', field: 'actions', headerName: "Actions", getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        title={'Edit'}
                        onClick={() => handleOpenEditDialog(params.row)}
                        key={`edit-${params.id}`}
                        color="primary"
                    />,

                ];
            }},
    ];

    const ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
    }
    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { ordering };
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        console.log(sortData)
        await fetchData('GET', {}, null, null, null, sortData);
    };

    useEffect(() => {
        setLoading(true);
        // 调用上面定义的函数来加载数据
        fetchData("GET", {}, null, {is_show: false}, false).then();
    }, [currentPage, pageSize]);

    const triggerSearch = (e, data) => {
        e.preventDefault();
        setSearch(true);
        setSearchData(data)
        // console.log(data)
        fetchData("GET", {}, null, data)
        setSearch(false);
    };


    const Refresh = (open=true) => {
        setData([]);
        setLoading(true);
        fetchData('GET', {}, null, {}, open).then(r => {})
    }
    const ToolBar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton/>
                {/*<GridToolbarFilterButton/>*/}
                <GridToolbarDensitySelector/>
                {/*<GridToolbarExport/>*/}
                <Button startIcon={<RefreshIcon/>} onClick={Refresh}>
                    刷新
                </Button>
                {/*{this.props.options}*/}
            </GridToolbarContainer>
        );
    }
    const onChangeColumnVisibilityModel = (newVisibility) => {
        // if (user.is_open_claim !== "4") {
        //     newVisibility = { ...newVisibility, Check_amount: false };
        // }
        setVisibility(newVisibility);
    }

    return (
        <Box sx={{
            width: '100%'
        }}>
            <Grid container>
                <Grid container item md={12} lg={12}>
                    <Box sx={{minWidth: '100%'}}>
                        <ServiceBar
                            searchData={searchData}
                            setSearchData={setSearchData}
                            search={search}
                            setSearch={triggerSearch}
                            setCurrentPage={setCurrentPage}
                        />
                    </Box>
                </Grid>
                <CreateService onSave={handleSave}></CreateService>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection={false}
                            headerHeight={70}
                            loading={loading}
                            rowCount={totalRows}
                            paginationMode='server'
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            initialState={
                                {pinnedColumns: {left: ['name'], right: ['actions']}}
                            }
                            onSortModelChange={handleSortModelChange}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: ToolBar,
                            }}
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>
            <EditFormDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                rowData={selectedRow}
                onSave={handleSave}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default LogisticService;
export {LogisticServicePage};