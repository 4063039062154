import {
    RATER,
    RATER_TABLE_LOADING,
    LOAD_RATER,
    SET_CURRENT,
    LOAD_SHIPPING_FEE,
    SHIPPING_FEE_TABLE_LOADING,
    ADMIN_MODAL_LOADING,
    UPLOAD_INVOICE_SUCCESS,
    UPLOAD_INVOICE_ERR,
    OFFER_SHEET_TABLE_LOADING,
    LOAD_OFFER_SHEET,
    OFFER_SHEET_LOADING,
    UPDATE_SURCHARGE_ERR,
    UPDATE_SURCHARGE_SUCCESS,
    UPLOAD_SHIPPING_FEE_SUCCESS,
    UPLOAD_SHIPPING_FEE_ERR,
    CLAIM_MODAL_LOADING,
    UPLOAD_CLAIM_SUCCESS,
    UPLOAD_CLAIM_ERR,
    SHIPPING_FEE_LOADING,
    DEL_OFFER_SHEET,
    DEL_OFFER_SHEET_SUCCESS,
    DEL_OFFER_SHEET_ERR,
    ADD_OFFER_SHEET_SUCCESS,
    ADD_OFFER_SHEET_ERR
} from "../type";
import server from "../../server";
import {sort_order} from "../../components/utils";
import {AxiosDefaultRequest} from "../../components/default_request";
import store from "../store";


export const RaterLoad = (offset, limit, setPage = null, setPageSize = null, options = null, reload=null, orders=null) => (dispatch, getState) => {
    dispatch({type: RATER_TABLE_LOADING})
    let url = server.rater_url + "?offset=" + offset + "&limit=" + limit
    if (options) {
        for (let i in options) {
            if (options[i] !== '') {
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    if (orders){
        url += sort_order(orders);
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            const result = {
                data: res.data.rows, rowCount: res.data.total, isLoading: false, display: false,
                shipping_fee_list: res.data.shipping_fee, shipping_fee_rowCount: res.data.shipping_fee_count
            };
            if (String(setPage) && setPage !== null) {
                result['page'] = setPage
            } else {
                result['page'] = store.getState().account.page;
            }
            if (String(setPageSize) && setPageSize !== null) {
                result['pageSize'] = setPageSize
            } else {
                result['pageSize'] = store.getState().account.pageSize;
            }
            dispatch({type: SET_CURRENT, payload: {current: RATER, searchTable: RATER, currentUrl: "/index/rater"}})
            dispatch({type: LOAD_RATER, payload: result})
        }
    })
}

export const ShippingFeeLoad = (offset, limit, setPage = null, setPageSize = null, options = null, reload=null, orders=null) => (dispatch, getState) => {
    dispatch({type: SHIPPING_FEE_TABLE_LOADING})
    let url = server.shipping_fee_url + "?offset=" + offset + "&limit=" + limit
    if (options) {
        for (let i in options) {
            if (options[i] !== '') {
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    if (orders){
        url += sort_order(orders);
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            const result = {
                data: res.data.rows, rowCount: res.data.total, isLoading: false, display: false
            };
            if (String(setPage) && setPage !== null) {
                result['page'] = setPage
            } else {
                result['page'] = store.getState().account.page;
            }
            if (String(setPageSize) && setPageSize !== null) {
                result['pageSize'] = setPageSize
            } else {
                result['pageSize'] = store.getState().account.pageSize;
            }
            // dispatch({type: SET_CURRENT, payload: {current: RATER, searchTable: RATER, currentUrl: "/index/rater"}})
            dispatch({type: LOAD_SHIPPING_FEE, payload: result})
        }
    })
}

export const CellEditShippingFee = (params) => (dispatch, getState) => {
    let shipping_fee_id = params['id'];
    let field = params['field'];
    let value = params['value'];
    AxiosDefaultRequest({
        url: server.CellEditShippingFee_url,
        method: 'POST',
        data: {
            'shipping_fee_id': shipping_fee_id,
            'field': field,
            'value': value,
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['request'] = true
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                // dispatch({type: UPLOAD_INVOICE_SUCCESS, payload: result})

            } else {
                result['request'] = false
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                // dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'request': false,
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
        }
    })
}

export const CellEditOfferSheet = (params, service_name, pfn) => (dispatch, getState) => {
    let description = params['row']['surcharge'];
    let field = params['field'];
    let value = params['value'];
    AxiosDefaultRequest({
        url: server.CellEditOfferSheet_url,
        method: 'POST',
        data: {
            'description': description,
            'service_name': service_name,
            'pfn': pfn,
            'field': field,
            'value': value,
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['request'] = true
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                // dispatch({type: UPLOAD_INVOICE_SUCCESS, payload: result})

            } else {
                result['request'] = false
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                // dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'request': false,
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: UPLOAD_INVOICE_ERR, payload: result})
        }
    })
}


export const OfferSheetLoad = (offset, limit, setPage = null, setPageSize = null, options = null, reload=null, orders=null) => (dispatch, getState) => {
    dispatch({type: OFFER_SHEET_LOADING})
    let url = server.offer_sheet_url + "?offset=" + offset + "&limit=" + limit
    if (options) {
        for (let i in options) {
            if (options[i] !== '') {
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    if (orders){
        url += sort_order(orders);
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            const result = {
                data: res.data.rows, rowCount: res.data.total, isLoading: false, display: false
            };
            if (String(setPage) && setPage !== null) {
                result['page'] = setPage
            } else {
                result['page'] = store.getState().account.page;
            }
            if (String(setPageSize) && setPageSize !== null) {
                result['pageSize'] = setPageSize
            } else {
                result['pageSize'] = store.getState().account.pageSize;
            }
            // dispatch({type: SET_CURRENT, payload: {current: RATER, searchTable: RATER, currentUrl: "/index/rater"}})
            dispatch({type: LOAD_OFFER_SHEET, payload: result})
        }
    })
}

export const OfferSheetDel = (data) => (dispatch, getState) => {
    dispatch({type: OFFER_SHEET_LOADING})
    let url = server.offer_sheet_url
    AxiosDefaultRequest({
        url: url,
        method: 'DELETE',
        data: data,
        success: (res) => {
            let result = res.data;
            if(result.status){
                result['request'] = true
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: DEL_OFFER_SHEET_SUCCESS, payload: result})
            }
            else {
                result['request'] = false
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: DEL_OFFER_SHEET_ERR, payload: result})
            }

        },
        err: res => {
            let result = {
                'request': false,
                'level': 'error',
                'title': 'FAILED',
                'message': 'The surcharge deletion failed, please check whether the data is incorrect and resubmit!'
            }
            dispatch({type: DEL_OFFER_SHEET_ERR, payload: result})
        }

    })
}

export const OfferSheetADD = (data) => (dispatch, getState) => {
    dispatch({type: OFFER_SHEET_LOADING})
    let url = server.offer_sheet_url
    AxiosDefaultRequest({
        url: url,
        method: 'POST',
        data: data,
        success: (res) => {
            let result = res.data;
            if(result.status){
                result['request'] = true
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: ADD_OFFER_SHEET_SUCCESS, payload: result})
            }
            else {
                result['request'] = false
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: ADD_OFFER_SHEET_ERR, payload: result})
            }

        },
        err: res => {
            let result = {
                'request': false,
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to add surcharge, please check whether the data is incorrect and resubmit!'
            }
            dispatch({type: ADD_OFFER_SHEET_ERR, payload: result})
        }

    })
}


export const UpdateSurcharge = (pfn, service, service_maps, data, capping) => (dispatch, getState) => {
    dispatch({type: OFFER_SHEET_LOADING})

    // 1. 打包json 发送api
    // 2. 存储json字符串 发送微服务
    // 3. 微服务更新数据库
    AxiosDefaultRequest({
        url: server.offer_sheet_update_url,
        method: 'POST',
        data: {
            'pfn': pfn,
            'service': service,
            'service_maps': service_maps,
            'data': data,
            'capping': capping
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['request'] = true
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: UPDATE_SURCHARGE_SUCCESS, payload: result})

            } else {
                result['request'] = false
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: UPDATE_SURCHARGE_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'request': false,
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: UPDATE_SURCHARGE_ERR, payload: result})
        }
    })
}


export const uploadShippingFee = (file_data, file_name, quote_id) => (dispatch, getState) => {
    dispatch({type: SHIPPING_FEE_LOADING})
    AxiosDefaultRequest({
        url: server.upload_shipping_fee_url,
        method: 'POST',
        data: {
            'shipping_fee_file': file_data,
            'file_name': file_name,
            'quote_id': quote_id,
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['request'] = true
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: UPLOAD_SHIPPING_FEE_SUCCESS, payload: result})
            } else {
                result['request'] = false
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: UPLOAD_SHIPPING_FEE_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'request': false,
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the claim file, please check the data for errors and resubmit!'
            }
            dispatch({type: UPLOAD_SHIPPING_FEE_ERR, payload: result})
        }
    })
}