import * as React from 'react';

import Modal from "@mui/material/Modal";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import {AxiosDefaultRequest, AxiosSyncDefaultRequest} from "../../../src/components/default_request";
import server from '../../../src/server'
import {useDispatch} from "react-redux";
import '../styles.scss'
import Paper from "@mui/material/Paper";
import {
    RenderButtonField,
    RenderCloseField,
    renderCloseField,
    RenderSelectField,
    RenderTextField
} from "../../components/renderField";
import {useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {Link} from "@mui/material";
import Button from "@mui/material/Button";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";


export default function ExportServiceAndSpecial({handleSearch, searchData, SetSearchData}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [special_services_options, setSpecialServicesOptions] = useState([])
    const [service_options, setServiceOptions] = useState([])
    const [searchOpen, setSearchOpen] = useState(true);

    const handleFieldChange = (e, value, key, value_name) => {
        SetSearchData(prevState => ({
            ...prevState,
            [key]: value?value[value_name]:''
        }));
    }
    const handleChange = (e, key, value) => {
        SetSearchData(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    useEffect(() => {
        setLoading(true)
        const fetchSpecialSevicesData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.page_special_services}`,
                    method: 'GET',
                    success: (res) => {
                        console.log(res.data.results)
                        let options = []
                        let rows = res.data.results
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setSpecialServicesOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchServiceData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_service_list}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setServiceOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        fetchSpecialSevicesData()
        fetchServiceData();
    }, [dispatch]);


    function Reset(e) {
        SetSearchData(prevState => ({
            ...prevState,
            note: '',
            service_id: '',
            package_special_service: '',
            pss_status: ''
        }));
    }
    const toggleSearchOpen = () => {
        setSearchOpen(!searchOpen);
    };
    return (
        <span>
            {!searchOpen && (
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<SearchIcon />}
                    onClick={() => toggleSearchOpen()}
                    variant="text">
                    搜索
                </Button>
            )}
            {searchOpen && (
            <Box component="form" noValidate>
                <Paper sx={{p: 1}}>
                <Grid container style={{marginBottom: "1%"}}>
            {/*{Object.entries(searchData).map(([key, value]) => (*/}

                    {RenderSelectField({
                        idAname: "package_special_service", label: "特殊服务名称",
                        value: searchData.package_special_service,
                        select_options: special_services_options,
                        v: "id",
                        value_name: "name",
                        onChangeHandler: (e, value) => {
                            handleFieldChange(e, value, "package_special_service", "id")
                        }
                    })}

                    {RenderSelectField({
                        idAname: "pss_status", label: "是否启用服务",
                        value: searchData.pss_status,
                        select_options: [{id: "0", label: "否"},{id: "1", label: "是"}],
                        v: "id",
                        value_name: "label",
                        onChangeHandler: (e, value) => {
                            handleFieldChange(e, value, "pss_status", "id")
                        }
                    })}
                    {RenderSelectField({
                        idAname: "service_id", label: "渠道服务",
                        value: searchData.service_id,
                        select_options: service_options,
                        v: "id",
                        value_name: "name",
                        onChangeHandler: (e, value) => {
                            handleFieldChange(e, value, "service_id", "id")
                        }
                    })}
                    {RenderTextField({
                        idAname: "note", label: "备注",
                        value: searchData.note,
                        onChangeHandler: (e) => {
                            handleChange(e, "note", e.target.value)
                        }
                    })}


                    <Grid container style={{paddingTop: "1%"}}>
                    {RenderButtonField({
                        idAname: "Search",
                        variant: "contained",
                        text: "查询",
                        // buttonType: "submit",
                        tip: "条件查询",
                        onClickHandler: (e) => {
                            handleSearch(e)
                        }
                    })}
                        {RenderButtonField({
                            idAname: "Clear",
                            variant: "outlined",
                            text: "清空",
                            buttonType: "button",
                            tip: "清空查询信息",
                            onClickHandler: (e) => {
                                Reset(e)
                            }
                        })}
                        <RenderCloseField onClickHandler={() => setSearchOpen(false)} />
                    </Grid>

                    {/*))}*/}
                    </Grid>
                    </Paper>
            </Box>
            )}
        </span>
    );
}
