import {
    LOAD_CLAIM,
    CLAIM_LOADING,
    CLAIM_ORDERS,
    CLAIM_SEARCH,
    CLAIM_STOP,
    CLAIM_SEARCH_UPDATE,
    UPLOAD_CLAIM_SUCCESS,
    UPLOAD_CLAIM_ERR,
    CLAIM_MODAL_LOADING,
    UPDATE_CLAIM_OPEN,
    CLAIM_CLOSE_ALERT,
    CLAIM_UPDATE,
    UPDATE_CLAIM_SUCCESS,
    UPDATE_CLAIM_ERR,
    VERIFY_CLAIM_OPEN,
    VERIFY_CLAIM_ERR,
    CLAIM_EXPORT,
    CLAIM_EXPORT_SUCCESS,
    CLAIM_SEARCH_CLEAR,
    CONFIRM_REFUND_CLAIM_ERR,
    CONFIRM_REFUND_OPEN,
} from "../type";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";

const initialState = {
    isLoading: false,
    TableLoading: false,
    ModalLoading: false,
    alert: false,
    pageSize: 30,
    page: 0,
    rowCount: 0,
    request: false,  // 控制table重载
    search: null,
    searchClick: false,
    stop: true,

    content: null,
    data: [],  // 页面数据
    orderClick: false,
    orders: [],
    claim_update_list: {}
};

export default function (preState=initialState, action) {
    const {type, payload} = action
    switch (type) {
        case LOAD_CLAIM:
            const rows : GridRowsProp = payload.data
            return {
                ...preState,
                data: rows,
                rowCount: payload.rowCount,
                TableLoading: false,
                page: payload.page,
                pageSize: payload.pageSize
            }
        case CLAIM_MODAL_LOADING:
            return {
                ...preState,
                ModalLoading: true,
                request: false,
                content: null,
            }
        case CLAIM_LOADING:
            return {
                ...preState,
                TableLoading: true,
                request: false,
                content: null,
            }
        case CLAIM_STOP:
            return {
                ...preState,
                stop: false,
                searchClick: false,
                orderClick: false
            }
        case CLAIM_ORDERS:
            return {
                ...preState,
                orderClick: true,
                orders: payload
            }
        case CLAIM_SEARCH:
            return {
                ...preState,
                page: 0,
                pageSize: 30,
                stop: true,
                searchClick: true
            }
        case CLAIM_SEARCH_UPDATE:
            let search;
            if (!preState.search) {
                search = payload;
            } else {
                search = preState.search;
                for (let i in payload){
                    search[i] = payload[i]
                }
            }
            return {
                ...preState,
                search: search,
                searchClick: false
            }
        case UPLOAD_CLAIM_ERR:
        case UPDATE_CLAIM_OPEN:
        case VERIFY_CLAIM_OPEN:
        case VERIFY_CLAIM_ERR:
        case CONFIRM_REFUND_CLAIM_ERR:
        case CONFIRM_REFUND_OPEN:
            return {
                ...preState,
                ModalLoading: false,
                alert: true,
                content: payload
            }
        case CLAIM_CLOSE_ALERT:
            return {
                ...preState,
                alert: false
            }
        case CLAIM_SEARCH_CLEAR:
            return {
                ...preState,
                search: []
            }
        case CLAIM_EXPORT:
            return {
                ...preState,
                isLoading: true
            }
        case CLAIM_EXPORT_SUCCESS:
            return {
                ...preState,
                isLoading: false,
                alert: true,
                content: payload,
                search: {
                    ...preState.search,
                    submitType: ""
                }
            }
        case CLAIM_UPDATE:
            return {
                ...preState,
                claim_update_list: payload
            }
        case UPDATE_CLAIM_SUCCESS:
        case UPLOAD_CLAIM_SUCCESS:
        case UPDATE_CLAIM_ERR:
            return {
                ...preState,
                ModalLoading: false,
                request: true,
                alert: true,
                content: payload,
                data: []  // 刷新表格
            }
        default:
            return preState
    }
}