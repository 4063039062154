import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Snackbar} from '@mui/material';
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";

const EditFormDialog = ({ open, onClose, rowData, onSave }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        address1: '',
        address2: '',
        city: '',
        postal_code: '',
        state: '',
        phone: '',
        company_name: '',
        country: '',
        description: '',
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    // 当rowData改变时，更新表单数据
    useEffect(() => {
        if (rowData) {
            setFormData(rowData);
        }
    }, [rowData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const result = await onSave(formData, formData.id, 'PUT');
            if (result.status === true) {
                // 操作成功，显示成功消息
                setSnackbar({ open: true, message: '保存成功！', severity: 'success' });
            }
        } catch (error) {
            console.error("保存失败", error);
            // 操作失败，显示错误消息
            // setSnackbar({ open: true, message: '保存失败，请重试。', severity: 'error' });
        } finally {
            setLoading(false);
            onClose(); // 保存操作完成后关闭对话框
        }
    };
    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>编辑信息</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="name"
                                label="仓库名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="address1"
                                label="地址一"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.address1}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="address2"
                                label="地址二"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.address2}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="city"
                                label="城市"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.city}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="postal_code"
                                label="邮编"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.postal_code}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="state"
                                label="州/省简称"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.state}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="phone"
                                label="电话"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="company_name"
                                label="公司名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.company_name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="description"
                                label="描述"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.description}
                                onChange={handleChange}
                                required
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>
                    <LoadingButton loading={loading} onClick={handleSave} variant={"contained"}>保存</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default EditFormDialog;
