import * as React from 'react';
import {connect} from "react-redux";
import {loadAccount} from "../../redux/actions/account";
import store from "../../redux/store";
import {ACCOUNT_ORDERS, ACCOUNT_STOP} from "../../redux/type";
import AddCredit from "./AddCreditModal";
import AddCreditPageModal from "./AddCreditPageModal";
import UpdateCancelCredit from "./ResetCreditCancelModal"
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import Grid from "@mui/material/Grid";
import SearchTable from "../../components/searchTable";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import APIToolBar from "../../components/APIToolBar";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import StyledBox from "../../components/StyledBox";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// style
import '../styles.scss'

import SetColumns from "../../components/SetColumns";
import SetVisibility from "../../components/SetVisibility";
import Switch from "@mui/material/Switch";
import server from "../../server";
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import {GridActionsCellItem} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import EditFormDialog from "../logisticSupplierManagement/account/Dialog/EditDialog";
import CreateAccount from "../logisticSupplierManagement/account/Dialog/createAccount";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";


class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Visibility: this.setVisibility,
            addCreditDialogOpen: false,
            UpdateCancelCreditDialogOpen: false,
            selectedRow: null,
            openEditDialog: false,
            show: {},
            snackbar:{
            open: false,
            message: '',
            severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
        },

        }
    }

    handleOpenEditDialog = (row) => {
        this.setState({selectedRow: row});
        this.setState({openEditDialog: true});
    };
    fetchData = async (method, data = {}, rowId = null) => {
        try {
            // if(search_data){
            //     Object.entries(search_data).forEach(([key, value]) => {
            //         searchData[key] = search_data[key]
            //     });
            // }
            // const queryParams = {
            //     ...searchData, // 将搜索条件作为查询参数
            //     curr_page: currentPage < 1 ? 1 : currentPage,
            //     page_size: pageSize,
            // };

            let requestUrl = server.account;
            let requestData = {};
            let msg = ''
            if (method === 'PUT' && rowId != null) {
                msg = "修改"
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = data;
                console.log("data---", requestData)
            } else if (method === "DELETE") {
                requestUrl = `${requestUrl}${rowId}/`;
                msg = '删除'
            } else if (method === "POST") {
                msg = "新增"
                requestData = data;
                console.log("requestData", requestData)
            }

            return await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                success: (res) => {
                    // setTotalRows(1)

                    this.Refresh()

                    // this.Refresh()

                    this.setState({snackbar:{open: true, message: `${msg}成功！`, severity: 'success'}});


                },
                fail: (error) => {
                    console.error('请求失败', error);
                    // setData([]);
                    // setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    // setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    this.setState({snackbar: {open: true, message: errorMessage, severity: 'error' }});
                    // throw error
                }
            });
        } catch (error) {
            console.error('请求异常', error);
        }
    };
    handleSave = (editedData, rowId, method = 'PUT') => {
        // 直接返回 fetchData 函数调用的 Promise
        let result = {status: false, message: ''};
        return this.fetchData(method, editedData, rowId
        ).then((e) => {

            this.Refresh()


            result.status = e !== undefined;
            return result;
        }).catch((error) => {
            result.status = false;
            result.message = error.response.message;
            return result;
        }).finally(() => {
            return result;
        });
    }
    handleChange = (event, id) => {
        this.handleSave({"is_active": event.target.checked}, id).then()
        // setChecked((prev) => ({
        //     ...prev,
        //     [chk_key]: {
        //         ...prev.much,
        //         ...prev.international,
        //         ...prev.enable,
        //         [id]: event.target.checked
        //     },
        // }));
    };
    setAddCreditDialogOpen = (value) => {
        this.setState({addCreditDialogOpen: value})
    }
    setUpdateCancelCreditDialogOpen = (value) => {
        this.setState({UpdateCancelCreditDialogOpen: value})
    }
    localStorageKey = this.props.user.username + "_" + 'AccountColumns';
    localStorageVisibilityKey = this.props.user.username + "_" + 'AccountVisibility';
    columns: GridColDef[] = [
        {id: 1, field: "id", headerName: "Id"},
        {id: 2, field: "username", headerName: "User Name", width: 150},
        {id: 3, field: "UID", headerName: "User Id"},
        {id: 4, field: "credit", headerName: "credit"},
        {id: 5, field: "fedex_rate", headerName: "fedex_rate"},
        {id: 6, field: "ups_rate", headerName: "ups_rate"},
        {id: 7, field: "credit_limit", headerName: "credit_limit"},
        {id: 8, field: "info", headerName: "info"},
        {
            id: 9, field: "APIkey", headerName: "APIkey", width:400, renderCell: (params) => {
                return (<>
                    <span>{this.state.show[params.row.id] ? params.row.APIkey : "*******"}</span>
                    <Button
                        startIcon={this.state.show[params.row.id] ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                        sx={{color: 'rgba(0,0,0,0.4)'}}
                        onClick={(e) => {
                            this.setState({

                                show: {
                                    ...this.state.show,
                                    [params.row.id]: this.state.show[params.row.id] ? false : true
                                }
                            })
                        }}
                    >

                    </Button></>);
            },
        },
        {id: 10, field: "mtw_dx", headerName: "mtw_dx"},
        {id: 11, field: "fedex_jp", headerName: "fedex_jp"},
        {id: 12, field: "ups_jp", headerName: "ups_jp"},
        {id: 13, field: "cancel_credit", headerName: "cancel_credit"},

        {id: 14, field: "email", headerName: "email"},

        {
            id: 15, field: "is_active", headerName: "is_active", renderCell: (params) => {
                return <Switch
                    checked={this.props.checked[params.row.id]}
                    onChange={(e) => this.handleChange(e, params.row.id)}
                    inputProps={{'aria-label': 'controlled'}}
                />
            }
        },
        {id: 16, field: "pfn", headerName: "pfn"},

        {
            id: 17, type: 'actions', field: 'actions', headerName: "Actions", getActions: (params) => {
                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        title={'Edit'}
                        onClick={() => this.handleOpenEditDialog(params.row)}
                        key={`edit-${params.id}`}
                        color="primary"
                    />,

                ];
            }
        },
    ]
    // 控制是否展示列
    columnVisibilityModel = {
        id: false
    }
    set_columns = SetColumns(this.localStorageKey, this.columns)
    setVisibility = SetVisibility(this.localStorageVisibilityKey, this.columnVisibilityModel)

    ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        // 获取columns
        // console.log(set_columns)
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
        // oldIndex > targetIndex
        // targetIndex < oldIndex
    }

    useQuery = (page) => {
        this.props.loadAccount((page * this.props.pageSize), ((page + 1) * this.props.pageSize),
            page, null, store.getState().account.search, null, null)
    }
    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.loadAccount((this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, store.getState().account.search, null, null)
        }, 100);
    }
    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(this.localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }

    UNSAFE_componentWillMount() { // 预加载
        this.props.loadAccount(this.props.page, this.props.pageSize, null,
            null, store.getState().account.search, null, null);
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (store.getState().account.stop && nextProps.search && !this.props.isLoading && nextProps.searchClick) {  // search
            store.dispatch({type: ACCOUNT_STOP})
            this.props.loadAccount(this.props.page, this.props.pageSize, null,
                null, store.getState().account.search, null, null);
        }
        // order
        if (nextProps.orders && nextProps.orderClick) {
            store.dispatch({type: ACCOUNT_STOP})
            this.props.loadAccount(this.props.page, this.props.pageSize, null,
                null, store.getState().account.search, null, nextProps.orders);
        }
    }

    ChangeColumnSortModel = (modal) => {
        // 触发 UNSAFE_componentWillReceiveProps
        store.dispatch({type: ACCOUNT_ORDERS, payload: modal})
    }
    Refresh = () => {
        this.useQuery(this.props.page);
    }

    render() {
        return (
            <Box sx={{width: '100%'}}>
                <Grid container>
                    <Grid container item md={12} lg={12}>
                        <Box sx={{minWidth: '100%'}}>
                            <SearchTable user={this.props.user}/>
                            <CreateAccount onSave={this.handleSave}></CreateAccount>
                            <AddCredit
                                user={this.props.user}
                                open={this.state.addCreditDialogOpen}
                                setOpen={this.setAddCreditDialogOpen}
                                Refresh={this.Refresh}
                            />
                            <AddCreditPageModal/>
                            <UpdateCancelCredit
                                open={this.state.UpdateCancelCreditDialogOpen}
                                setOpen={this.setUpdateCancelCreditDialogOpen}
                                Refresh={this.Refresh}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={12}>

                        <StyledBox>
                            {this.props.data ? <DataGridPro
                                rows={this.props.data}
                                density="compact"
                                columns={this.set_columns}
                                pageSize={this.props.pageSize}
                                rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                                rowReordering={false}
                                onColumnOrderChange={(e) => {
                                    this.ColumnOrderChange(e)
                                    // APIColumnOrderChange(e, set_columns, localStorageKey)
                                }}
                                // checkboxSelection
                                headerHeight={70}
                                // autoHeight={true}
                                loading={this.props.isLoading}
                                rowCount={this.props.rowCount}
                                paginationMode='server'
                                onPageChange={(page) => this.useQuery(page)}
                                onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                                columnVisibilityModel={this.state.Visibility}
                                onColumnVisibilityModelChange={(newModel) =>
                                    this.ChangeColumnVisibilityModel(newModel)
                                }
                                disableMultipleSelection={false}
                                initialState={
                                    {pinnedColumns: {left: ['username'], right: ['actions']}}
                                }
                                pagination={true}

                                sortingMode="server"
                                // sortModel={this.set_sort}
                                onSortModelChange={(newSortModel) => this.ChangeColumnSortModel(newSortModel)}

                                components={{
                                    LoadingOverlay: LinearProgress,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    Toolbar: APIToolBar
                                }}
                                columnBuffer={3}
                                columnThreshold={3}
                            /> : ''}
                        </StyledBox>
                    </Grid>
                </Grid>
                <EditFormDialog
                    open={this.state.openEditDialog}
                    onClose={() => this.setState({openEditDialog: false})}
                    rowData={this.state.selectedRow}
                    onSave={this.handleSave}
                />
                <Snackbar
                    open={this.state.snackbar.open}
                    autoHideDuration={2000}
                    onClose={() => this.setState({ ...this.state.snackbar, snackbar:{open: false }})}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert onClose={() => this.setState({ ...this.state.snackbar, snackbar:{open: false }})} severity={this.state.snackbar.severity} sx={{ width: '100%' }}>
                        {this.state.snackbar.message}
                    </Alert>
                </Snackbar>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.account.isLoading,
    pageSize: state.account.pageSize,
    page: state.account.page,
    rowCount: state.account.rowCount,
    data: state.account.data,
    search: state.account.search,
    searchClick: state.account.searchClick,
    orders: state.account.orders,
    orderClick: state.account.orderClick,
    user: state.auth.user,
    checked: state.account.checked,
});

export default connect(mapStateToProps, {loadAccount})(Account)