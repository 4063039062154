// PdfViewer.js
import React from 'react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

class PDFViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scale: 2.0,
        };
    }

    increaseClarity = () => {
        this.setState(prevState => ({scale: prevState.scale + 0.2}));
    };

    decreaseClarity = () => {
        this.setState(prevState => ({scale: prevState.scale - 0.2}));
    };

    render() {
        return (
            <Box>
                <Document file={this.props.url}>
                    <Page scale={this.state.scale} pageNumber={1} />
                </Document>
                <Box display="flex" justifyContent="center" mt={2}>
                    <Button variant="contained" onClick={this.decreaseClarity}>
                        Decrease Clarity
                    </Button>
                    <Typography variant="body1" sx={{ mx: 2 }}>
                        Scale: {Math.round(this.state.scale * 100)}%
                    </Typography>
                    <Button variant="contained" onClick={this.increaseClarity}>
                        Increase Clarity
                    </Button>
                </Box>
            </Box>
        );
    }
}

export default PDFViewer;
