import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
    RenderButtonField, RenderCloseField, renderCloseField, RenderDateRangePickerField,
    RenderDesktopDatePickerField, RenderMultilineTextField,
    RenderSelectField,
    RenderTextField
} from "../../../components/renderField";
import React, {useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ExportPage from "../../modal/ExportPageModal";
import ReceiptPage from "../../modal/ReceiptPageModal"
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import {Snackbar} from "@mui/material";
import Alert from "@mui/material/Alert";
import { parseISO, format } from 'date-fns';
const options = [
    {label: '未确认', value: "0"},
    {label: "确认", value: "1"},
    // <MenuItem aria-label="None" key={'None'} value={""}>不选择</MenuItem>,
    // <MenuItem aria-label="None" key={'0'} value={"0"}>未确认</MenuItem>,
    // <MenuItem aria-label="None" key={'1'} value={"1"}>确认</MenuItem>,
    // <option aria-label="None" key={'2'} value={"2"}>已出账</option>,
];
const returned = [
    {label: "未退款", value: "0"},
    {label: "已退款", value: "1"},
    // <MenuItem aria-label="None" key={'None'} value={""}>不选择</MenuItem>,
    // <MenuItem aria-label="None" key={'0'} value={"0"}>未退款</MenuItem>,
    // <MenuItem aria-label="None" key={'1'} value={"1"}>已退款</MenuItem>,
    // <option aria-label="None" key={'2'} value={"2"}>已出账</option>,
]
const searchFormControl = {width: '80%', marginBottom: '5%'};
export function InternalClaimBar({searchData, setSearchData, search, setSearch, setCurrentPage}) {
    const [searchOpen, setSearchOpen] = useState(true);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const handleSubmit = (e) => {
        e.preventDefault(); // 阻止表单默认提交行为
        // 检查 searchData 是否有至少一个属性被填写
        const isAnyFieldFilled = Object.keys(searchData).some(key => {
            // 根据你的具体情况，这里可能需要调整，比如如果使用了日期或数字等类型
            if (key === "submitType"){
                return false;
            }
            return searchData[key] !== '' && searchData[key] !== null && searchData[key] !== undefined;
        });

        if (isAnyFieldFilled) {
            setCurrentPage(0);
            setSearch(); // 假设这是触发搜索的函数
        } else {
            // 可以设置一个状态来显示错误信息，或直接使用 alert
            setSnackbar({open: true, message: '请至少填写一个查询条件！', severity: 'info'});
        }
    }
    const handleFieldChanges = (e, value, key, value_name) => {
        setSearchData({...searchData, [key]: value?value[value_name]:''})
    }
    const handleFieldChange = (fieldName) => (event) => {
        setSearchData({ ...searchData, [fieldName]: event.target.value });
    };

    const handleButtonFieldChange = (fieldName, value) => () => {
        setSearchData({ ...searchData, [fieldName]: value });
    };

    const handleDateFieldChange = (fieldName) => (event) => {
        setSearchData({ ...searchData, [fieldName]:  event});
    };

    const handleDateRangeFieldChange = (startFieldName, endFieldName) => (newValue) => {
        // newValue 是一个数组，包含两个元素：[开始日期, 结束日期]
        const [startValue, endValue] = newValue;

        // 使用 toLocaleDateString 将日期转换为字符串
        const startDateString = startValue ? new Date(startValue).toLocaleDateString("en") : null;
        const endDateString = endValue ? new Date(endValue).toLocaleDateString("en") : null;

        // 同时更新开始和结束日期
        setSearchData({
            ...searchData,
            [startFieldName]: startDateString,
            [endFieldName]: endDateString
        });
    };

    const handleClearData = () => {
        setSearchData(prevSearchData => {
            const resetData = Object.keys(prevSearchData).reduce((acc, key) => {
                // 对于四个特定的时间字段，将它们的值设置为null
                if (["verify_time_after", "verify_time_before", "create_time_after", "create_time_before"].includes(key)) {
                    acc[key] = null;
                } else {
                    // 对于其他字段，将它们的值设置为空字符串""
                    acc[key] = "";
                }
                return acc;
            }, {});

            return { ...resetData };
        });
    }
    const toggleSearchOpen = () => {
        setSearchOpen(!searchOpen);
    };
    return (
        <span>
            {!searchOpen && (
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<SearchIcon />}
                    onClick={() => toggleSearchOpen()}
                    variant="text">
                    搜索
                </Button>
            )}
            {searchOpen && (
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <Grid container style={{marginBottom: "1%"}}>
                        <RenderTextField
                            idAname="Case_number"
                            label="Case_number"
                            value={searchData.Case_number}
                            onChangeHandler={handleFieldChange('Case_number')}
                        />
                        <RenderTextField
                            idAname="Tracking_number"
                            label="Tracking Number"
                            value={searchData.Tracking_number}
                            onChangeHandler={handleFieldChange('Tracking_number')}
                        />
                        <RenderTextField
                            idAname="EFT"
                            label="EFT"
                            value={searchData.EFT}
                            onChangeHandler={handleFieldChange('EFT')}
                        />
                        <RenderTextField
                            idAname="UID"
                            label="用户UID"
                            value={searchData.UID}
                            onChangeHandler={handleFieldChange('UID')}
                        />
                    </Grid>
                    <Grid container style={{marginBottom: "1%"}}>
                        <Grid item lg={3} md={3} xs={3} sx={{paddingTop: '0.5%'}}>
                            <Grid container spacing={1}>
                                <RenderDesktopDatePickerField
                                    idAname="verify_time_after"
                                    label="起始确认时间"
                                    value={searchData.verify_time_after}
                                    onChangeHandler={handleDateFieldChange('verify_time_after')}
                                >
                                </RenderDesktopDatePickerField>
                                <RenderDesktopDatePickerField
                                    idAname="verify_time_before"
                                    label="结束确认时间"
                                    value={searchData.verify_time_before}
                                    onChangeHandler={handleDateFieldChange('verify_time_before')}
                                >
                                </RenderDesktopDatePickerField>
                            </Grid>
                        </Grid>
                        <Grid item lg={3} md={3} xs={3} sx={{paddingTop: '0.5%'}}>
                            <Grid container spacing={1}>
                                <RenderDesktopDatePickerField
                                    idAname="create_time_after"
                                    label="起始上传时间"
                                    value={searchData.create_time_after}
                                    onChangeHandler={handleDateFieldChange('create_time_after')}
                                />
                                {/*</RenderDesktopDatePickerField>*/}
                                <RenderDesktopDatePickerField
                                    idAname="create_time_before"
                                    label="结束上传时间"
                                    value={searchData.create_time_before}
                                    onChangeHandler={handleDateFieldChange('create_time_before')}
                                />
                                {/*<RenderDateRangePickerField*/}
                                {/*    idAname={['start_upload_time', 'end_upload_time']}*/}
                                {/*    label={["起始上传时间", "结束上传时间"]}*/}
                                {/*    value={[searchData.start_upload_time, searchData.end_upload_time]}*/}
                                {/*    onChangeHandler={handleDateRangeFieldChange('start_upload_time', 'end_upload_time')}*/}
                                {/*/>*/}
                            </Grid>
                        </Grid>
                        <RenderSelectField
                            idAname="Status"
                            label="状态"
                            value={searchData.status}
                            onChangeHandler={(e, value) => handleFieldChanges(e, value, "status", "value")}
                            select_options={options}
                            v={"value"}
                            value_name={"label"}
                        ></RenderSelectField>
                        <RenderSelectField
                            idAname="Returned"
                            label="退款状态"
                            value={searchData.returned}
                            onChangeHandler={(e, value) => handleFieldChanges(e, value, "returned", "value")}
                            select_options={returned}
                            v={"value"}
                            value_name={"label"}
                        ></RenderSelectField>
                    </Grid>
                    <Grid container style={{marginBottom: "1%"}}>
                        <RenderTextField
                            idAname="invoice_number"
                            label="账单号"
                            value={searchData.invoice_number}
                            onChangeHandler={handleFieldChange('invoice_number')}
                        />
                    </Grid>
                    <Grid container>
                        <RenderButtonField
                            idAname="Search"
                            variant="contained"
                            text="查询"
                            buttonType="submit"
                            tip="条件查询"
                            onClickHandler={null}
                            loading={search}
                        />
                        <RenderButtonField
                            idAname="exportClaim"
                            variant="contained"
                            text="导出索赔信息"
                            buttonType="submit"
                            tip="查询条件导出"
                            onClickHandler={handleButtonFieldChange('submitType', 'export')}
                            loading={search}
                        ></RenderButtonField>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <ExportPage type={'CE'}/>
                            </FormControl>
                        </Grid>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <ReceiptPage type={'ICR'}/>
                            </FormControl>
                        </Grid>
                        <RenderButtonField
                            idAname="Clear"
                            variant="outlined"
                            text="清空"
                            buttonType="button"
                            tip="清空查询信息"
                            onClickHandler={handleClearData}
                        />
                        <RenderCloseField onClickHandler={() => setSearchOpen(false)} />
                    </Grid>
                </Box>
            )}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={(event, reason) => {
                    if (reason !== 'clickaway') {
                        setSnackbar({ ...snackbar, open: false });
                    }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </span>
    )
}

