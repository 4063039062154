import React, { useState } from 'react';
import {Dialog, DialogTitle, DialogContent, Button, DialogActions, Snackbar} from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import Grid from "@mui/material/Grid";
import {RenderDesktopDatePickerField} from "../../components/renderField";
import LoadingButton from "@mui/lab/LoadingButton";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import Alert from "@mui/material/Alert";
import server from "../../server";
import APICircularProgress from "../../components/APICircularProgress";
import {useSelector} from "react-redux";

const ExportShipment = () => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [api_loading, setApiLoading] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const [formData, setFormData] = useState({start_create_time: null, end_create_time: null});
    const user = useSelector((state) => state.auth.user);
    const ChangeHandler = (key, e) => {
        // let localTime = new Date(e);
        setFormData(prevPerson => ({
            ...prevPerson,
            [key]: e
        }));
        // options['start_create_time'] = localTime.toISOString();
    }
    const handleExport = async () => {
        if(!formData.start_create_time && !formData.end_create_time){
            setSnackbar({open: true, message: "请至少选择一个条件！", severity: "warning"})
            return
        }
        if(!user.is_superuser){
            setSnackbar({open: true, message: "只有超级用户才能导出！", severity: "warning"})
            return
        }
        setLoading(true);
        setApiLoading(APICircularProgress("success"))
        let url = `${server.ship_url}?submitType=export&monthly=true`
        if(formData.start_create_time){
            let localTime = new Date(formData.start_create_time)
            let start_time = localTime.toISOString()
            url = `${url}&start_create_time=${start_time}`
        }
        if(formData.end_create_time){
            let localTime = new Date(formData.end_create_time);
            let end_time = localTime.toISOString()
            url = `${url}&end_create_time=${end_time}`
        }
        await AxiosSyncDefaultRequest({
            url: url,
            method: "GET",
            success: (res) => {
                setLoading(false)
                setFormData({start_create_time: null, end_create_time: null})
                // Refresh()
                console.log("res", res)
                setApiLoading(null)
                setOpenCreateDialog(false)
                let data = res.data
                if("message" in data){
                    setSnackbar({open: true, message: data.message, severity: 'success'})
                }
                console.log("user", user)
            },
            fail: (error) => {
                console.error('请求失败', error);
                // setData([])
                setLoading(false)
                setLoading(false)
                setApiLoading(null)
                setOpenCreateDialog(false)
            }
        })
    }
    return (
        <>
            {user.is_superuser ? (
                <>
                    <Button sx={{textTransform: 'none'}} startIcon={<IosShareIcon/>} onClick={() => setOpenCreateDialog(true)}>
                        导出数据
                    </Button>
                    <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)} maxWidth="sm" fullWidth>
                        <DialogTitle>导出数据</DialogTitle>
                        <DialogContent>
                            <Grid item lg={3} md={3} xs={3} sx={{paddingTop: '0.5%'}}>
                                <Grid container spacing={17}>
                                    <RenderDesktopDatePickerField
                                        sx={{m: 3, minWidth: 136}}
                                        idAname="start_create_time"
                                        label="下单起始日期"
                                        value={formData.start_create_time}
                                        onChangeHandler={(e) => ChangeHandler("start_create_time", e)}
                                    />
                                    <RenderDesktopDatePickerField
                                        sx={{m: 3, minWidth: 136, length: 6}}
                                        idAname="end_create_time"
                                        label="下单结束日期"
                                        value={formData.end_create_time}
                                        onChangeHandler={(e) => ChangeHandler("end_create_time", e)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenCreateDialog(false)} variant="contained">取消</Button>
                            <LoadingButton loading={loading} onClick={handleExport}>导出</LoadingButton>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        open={snackbar.open}
                        autoHideDuration={2000}
                        onClose={() => setSnackbar({...snackbar, open: false})}
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    >
                        <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                               sx={{width: '100%'}}>
                            {snackbar.message}
                        </Alert>
                    </Snackbar>
                    {api_loading && api_loading}
                </>
            ) : null}
        </>
    );
}
export default ExportShipment;