import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar} from '@mui/material';
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import {
    RenderDesktopDatePickerField,
    RenderSelectField,
    RenderTextField,
    RenderTimePickerField
} from "../../../components/renderField";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {DataGrid} from "@mui/x-data-grid";

const PickupDialog = ({ warehouse_service, warehouseList, selectedRow, rowData, onSave }) => {
    const [pickupOpen, setPickupOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const date_time_list = ["PickupDate", "ReadyTime", "CloseTime"];
    const columns = [
        { field: 'id', headerName: 'ID', width: 90, hideable: true},
        {
            field: 'ToZip',
            headerName: 'To Zip',
            width: 100
        },
        {
            field: 'ServiceType',
            headerName: 'Service Type',
            width: 250
        },
        {
            field: 'HandingUnits',
            headerName: 'Handing Units',
            width: 150,
        },
        {
            field: 'PackageType',
            headerName: 'Package Type',
            width: 110,
        },
    ];

    // 接收勾选数据
    // 验证勾选数据-前端验证
    // 0. 必须有至少一条数据
    // 1. 勾选内容必须是同一个客户
    // 2. 必须为同一个发货仓
    // 3. 状态必须是2,3 出单状态
    const [formData, setFormData] = useState({
        account: '',
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        PostalCode: '',
        Company: '',
        Contact: '',
        PhoneNumber: '',
        PickupDate: new Date(),
        ReadyTime: new Date('1970-01-01T09:00:00'), // 设置默认时间为9:00 AM
        CloseTime: new Date('1970-01-01T16:30:00'), // 设置默认时间为04:30 PM
        shipment_details: {}
    });
    const [statusFormData, setStatusFormData] = useState({
        account: {required: true, message: "", status: true},
        Address1: {required: true, message: "", status: true},
        Address2: {required: false, message: "", status: true},
        City: {required: true, message: "", status: true},
        State: {required: true, message: "", status: true},
        PostalCode: {required: true, message: "", status: true},
        Company: {required: true, message: "", status: true},
        Contact: {required: true, message: "", status: true},
        PhoneNumber: {required: true, message: "", status: true},
        PickupDate: {required: true, message: "", status: true},
        ReadyTime: {required: true, message: "", status: true},
        CloseTime: {required: true, message: "", status: true}
    });
    useEffect(() => {
        if (pickupOpen){
            let selectedRows = [];
            let shipment_ids = [];
            let _row = null;
            // 检查每个选定的 ID 是否在 rowData 中
            for (let id of selectedRow) {
                let row = rowData.find(row => row.id === id);
                if (!row) {
                    setSnackbar({open: true, message: `数据未查询到id为${id}的信息,请刷新页面重新勾选信息!`, severity: 'info'});
                    return;
                }
                selectedRows.push(row);
            }

            // 检查所有选定的行是否具有相同的 UID 和 Warehouse，并且状态是 2 或 3
            // 如果所有的检查都通过，转换数据并输出结果
            let convertedRows = selectedRows.map(row => {
                _row = row;
                shipment_ids.push(row.id);
                return {
                    id: row.id,
                    ToZip: row.PostalCode,
                    ServiceType: "FEDEX_FREIGHT_ECONOMY",
                    HandingUnits: row.MultiPackages,
                    PackageType: "Pallet"
                }
            });

            let result = Object.values(convertedRows.reduce((acc, row) => {
                // 如果这个 PostalCode 已经在累加器中，那么我们将 HandingUnits 合并
                if(acc[row.ToZip]) {
                    acc[row.ToZip].HandingUnits += row.HandingUnits;
                }
                // 否则，我们添加这一行到累加器
                else {
                    acc[row.ToZip] = row;
                }
                return acc;
            }, {}));

            let matchingWarehouse = warehouseList.find(warehouse => warehouse.warehouse_number === _row.Warehouse);
            setFormData({
                ...formData,
                shipment_details: result,
                shipment_ids: shipment_ids,
                account: _row.Warehouse || "",
                Address1: matchingWarehouse.address1 || "",
                Address2: matchingWarehouse.address2 || "",
                City: matchingWarehouse.city || "",
                State: matchingWarehouse.state || "",
                PostalCode: matchingWarehouse.postal_code || "",
                Company: matchingWarehouse.company_name || "",
                Contact: matchingWarehouse.name || "",
                PhoneNumber: matchingWarehouse.phone || "",
            });
            setStatusFormData({
                account: {required: true, message: "", status: true},
                Address1: {required: true, message: "", status: true},
                Address2: {required: false, message: "", status: true},
                City: {required: true, message: "", status: true},
                State: {required: true, message: "", status: true},
                PostalCode: {required: true, message: "", status: true},
                Company: {required: true, message: "", status: true},
                Contact: {required: true, message: "", status: true},
                PhoneNumber: {required: true, message: "", status: true},
                PickupDate: {required: true, message: "", status: true},
                ReadyTime: {required: true, message: "", status: true},
                CloseTime: {required: true, message: "", status: true}
            });
        }
    }, [pickupOpen])
    const onOpen = () => {

        if (!selectedRow){
            setSnackbar({open: true, message: '请勾选任意一条或多条数据!', severity: 'info'});
            return;
        }
        let selectedRows = [];
        // 检查每个选定的 ID 是否在 rowData 中
        for (let id of selectedRow) {
            let row = rowData.find(row => row.id === id);
            if (!row) {
                setSnackbar({open: true, message: `数据未查询到id为${id}的信息,请刷新页面重新勾选信息!`, severity: 'info'});
                return;
            }
            selectedRows.push(row);
        }

        // 检查所有选定的行是否具有相同的 UID 和 Warehouse，并且状态是 2 或 3
        let firstRow = selectedRows[0];
        for (let row of selectedRows) {
            if (row.user.UID !== firstRow.user.UID) {
                setSnackbar({open: true, message: '勾选行非同一位客户!', severity: 'error'});
                return;
            }
            if (row.Warehouse !== firstRow.Warehouse) {
                setSnackbar({open: true, message: '勾选行非同一仓库!', severity: 'error'});
                return;
            }
            if (row.status !== "2" && row.status !== "3") {
                setSnackbar({open: true, message: '勾选行包含了非出单状态!', severity: 'error'});
                return;
            }
        }
        setPickupOpen(true);
    }
    const onClose = () => setPickupOpen(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const handleSave = async () => {
        // 创建一个新的对象来存储错误
        const newErrors = {};
        let is_valid = true;

        // 检查所有必填字段是否已填写
        for (let field in statusFormData) {
            if (statusFormData[field].required && !formData[field]) {
                newErrors[field] = {
                    ...statusFormData[field],
                    message: 'This field is required',
                    status: false
                };
                is_valid = false;
            }else{
                newErrors[field] = {
                    ...statusFormData[field],
                    message: '',
                    status: true
                };
            }
        }
        // 如果有错误，更新状态并停止保存操作
        setStatusFormData({
            ...statusFormData,
            ...newErrors
        });
        if (!is_valid) {
            return;
        }

        setLoading(true);
        try {
            const result = await onSave(formData, formData.id, 'POST');
            if (result.status === true) {
                // 操作成功，显示成功消息
                setSnackbar({ open: true, message: '保存成功！', severity: 'success' });
            }
        } catch (error) {
            console.error("保存失败", error);
            // 操作失败，显示错误消息
            // setSnackbar({ open: true, message: '保存失败,请重试.', severity: 'error' });
        } finally {
            setLoading(false);
            onClose(); // 保存操作完成后关闭对话框
        }
    };
    const handleChange = (e, value, key, value_name) => {
        setFormData(prevState => ({
            ...prevState,
            [key]: value?value[value_name]:''
        }));
    }
    const handleFieldChange = (fieldName) => (event) => {
        const valid = {};
        if(date_time_list.includes(fieldName)){
            setFormData({ ...formData, [fieldName]: event });
            if (statusFormData[fieldName].status) {
                if (!event && statusFormData[fieldName].required) {
                    valid[fieldName] = {
                        ...statusFormData[fieldName],
                        message: 'This field is required',
                        status: false
                    }
                    setStatusFormData({
                        ...statusFormData,
                        ...valid
                    });
                }
            } else {
                valid[fieldName] = {
                    ...statusFormData[fieldName],
                    message: '',
                    status: true
                }
                setStatusFormData({
                    ...statusFormData,
                    ...valid
                });
            }
        }
        else {
            setFormData({...formData, [fieldName]: event.target.value});

            if (statusFormData[fieldName].status) {
                if (!event.target.value && statusFormData[fieldName].required) {
                    valid[fieldName] = {
                        ...statusFormData[fieldName],
                        message: 'This field is required',
                        status: false
                    }
                    setStatusFormData({
                        ...statusFormData,
                        ...valid
                    });
                }
            } else {
                valid[fieldName] = {
                    ...statusFormData[fieldName],
                    message: '',
                    status: true
                }
                setStatusFormData({
                    ...statusFormData,
                    ...valid
                });
            }
        }

    };
    return (
        <>
            <Button sx={{textTransform: 'none'}} startIcon={<EventAvailableIcon/>} onClick={() => {onOpen()}}>
                卡派预约
            </Button>
            <Dialog open={pickupOpen} onClose={onClose} maxWidth="md" fullWidth sx={{width: '100%', minWidth: '100%'}}>
                <DialogTitle>卡派预约</DialogTitle>
                <DialogContent>
                    <Grid item lg={12} md={12} xs={12}>
                        <Grid container spacing={1} justifyContent="center">
                            <Grid item xs={6} md={6} lg={6}>
                                <Paper elevation={3} sx={{ margin: 0, marginBottom: 1, padding: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Account
                                    </Typography>
                                    {RenderSelectField({
                                        idAname: "account",
                                        label: "Account",
                                        value: formData.account,
                                        onChangeHandler: (e, value) => {handleChange(e, value,"Warehouse", "warehouse_number")},
                                        v: "warehouse_number",
                                        value_name: "warehouse_name",
                                        select_options: warehouse_service,
                                        required: true,
                                        error: !statusFormData.account.status, // 如果状态为false，显示错误状态
                                        helperText: statusFormData.account.message, // 显示错误消息
                                        sx: {width: 314},
                                        endAdornment: {endAdornment: null},
                                    })}
                                    {/*<RenderSelectField*/}
                                    {/*    idAname={"account"}*/}
                                    {/*    label={"Account"}*/}
                                    {/*    value={formData.account}*/}
                                    {/*    onChangeHandler={handleFieldChange("account")}*/}
                                    {/*    select_options={warehouse_service.map(option => (*/}
                                    {/*        <MenuItem key={option.id} value={option.warehouse_number}>*/}
                                    {/*            {option.warehouse_name}*/}
                                    {/*        </MenuItem>*/}
                                    {/*    ))}*/}
                                    {/*    width={12}*/}
                                    {/*    required*/}
                                    {/*    error={!statusFormData.account.status} // 如果状态为false，显示错误状态*/}
                                    {/*    helperText={statusFormData.account.message} // 显示错误消息*/}
                                    {/*/>*/}
                                </Paper>
                                <Paper elevation={3} sx={{ margin: 0, padding: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Pickup Address
                                    </Typography>
                                    <RenderTextField
                                        idAname="Address1"
                                        label="Address1"
                                        value={formData.Address1}
                                        onChangeHandler={handleFieldChange("Address1")}
                                        width={12}
                                        required
                                        error={!statusFormData.Address1.status} // 如果状态为false，显示错误状态
                                        helperText={statusFormData.Address1.message} // 显示错误消息
                                    />
                                    <RenderTextField
                                        idAname="Address2"
                                        label="Address2"
                                        value={formData.Address2}
                                        onChangeHandler={handleFieldChange("Address2")}
                                        width={12}
                                        required
                                        error={!statusFormData.Address2.status} // 如果状态为false，显示错误状态
                                        helperText={statusFormData.Address2.message} // 显示错误消息

                                    />
                                    <RenderTextField
                                        idAname="City"
                                        label="City"
                                        value={formData.City}
                                        onChangeHandler={handleFieldChange("City")}
                                        width={12}
                                        required
                                        error={!statusFormData.City.status} // 如果状态为false，显示错误状态
                                        helperText={statusFormData.City.message} // 显示错误消息
                                    />
                                    <RenderTextField
                                        idAname="State"
                                        label="State"
                                        value={formData.State}
                                        onChangeHandler={handleFieldChange("State")}
                                        width={12}
                                        required
                                        error={!statusFormData.State.status} // 如果状态为false，显示错误状态
                                        helperText={statusFormData.State.message} // 显示错误消息
                                    />
                                    <RenderTextField
                                        idAname="PostalCode"
                                        label="PostalCode"
                                        value={formData.PostalCode}
                                        onChangeHandler={handleFieldChange("PostalCode")}
                                        width={12}
                                        required
                                        error={!statusFormData.PostalCode.status} // 如果状态为false，显示错误状态
                                        helperText={statusFormData.PostalCode.message} // 显示错误消息
                                    />
                                    <RenderTextField
                                        idAname="Company"
                                        label="Company"
                                        value={formData.Company}
                                        onChangeHandler={handleFieldChange("Company")}
                                        width={12}
                                        required
                                        error={!statusFormData.Company.status} // 如果状态为false，显示错误状态
                                        helperText={statusFormData.Company.message} // 显示错误消息
                                    />
                                    <RenderTextField
                                        idAname="Contact"
                                        label="Contact"
                                        value={formData.Contact}
                                        onChangeHandler={handleFieldChange("Contact")}
                                        width={12}
                                        required
                                        error={!statusFormData.Contact.status} // 如果状态为false，显示错误状态
                                        helperText={statusFormData.Contact.message} // 显示错误消息
                                    />
                                    <RenderTextField
                                        idAname="PhoneNumber"
                                        label="PhoneNumber"
                                        value={formData.PhoneNumber}
                                        type="number"
                                        onChangeHandler={handleFieldChange("PhoneNumber")}
                                        width={12}
                                        required
                                        error={!statusFormData.PhoneNumber.status} // 如果状态为false，显示错误状态
                                        helperText={statusFormData.PhoneNumber.message} // 显示错误消息
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <Paper elevation={3} sx={{ margin: 0, padding: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Pickup Info
                                    </Typography>
                                    <RenderDesktopDatePickerField
                                        idAname={"PickupDate"}
                                        label={"Pickup Date"}
                                        value={formData.PickupDate}
                                        sx={{margin: 1}}
                                        onChangeHandler={handleFieldChange("PickupDate")}
                                        width={12}
                                        minDate={new Date()} // 设置最小日期为今天
                                        error={!statusFormData.PickupDate.status} // 如果状态为false，显示错误状态
                                        helperText={statusFormData.PickupDate.message} // 显示错误消息
                                        required
                                        slotProps={{
                                            textField: {
                                                required: statusFormData.PickupDate.required,
                                                helperText: statusFormData.PickupDate.message,
                                                error: !statusFormData.PickupDate.status,
                                                size: 'small'
                                            },
                                        }}
                                    />
                                    <RenderTimePickerField
                                        idAname={"ReadyTime"}
                                        label={"Ready Time"}
                                        value={formData.ReadyTime}
                                        sx={{margin: 1}}
                                        onChangeHandler={handleFieldChange("ReadyTime")}
                                        width={12}
                                        error={!statusFormData.ReadyTime.status} // 如果状态为false，显示错误状态
                                        helperText={statusFormData.ReadyTime.message} // 显示错误消息
                                        required
                                        slotProps={{
                                            textField: {
                                                required: statusFormData.ReadyTime.required,
                                                helperText: statusFormData.ReadyTime.message,
                                                error: !statusFormData.ReadyTime.status,
                                                size: 'small'
                                            },
                                        }}
                                    />
                                    <RenderTimePickerField
                                        idAname={"CloseTime"}
                                        label={"Close Time"}
                                        value={formData.CloseTime}
                                        sx={{margin: 1}}
                                        onChangeHandler={handleFieldChange("CloseTime")}
                                        width={12}
                                        error={!statusFormData.CloseTime.status} // 如果状态为false，显示错误状态
                                        helperText={statusFormData.CloseTime.message} // 显示错误消息
                                        required
                                        slotProps={{
                                            textField: {
                                                required: statusFormData.CloseTime.required,
                                                helperText: statusFormData.CloseTime.message,
                                                error: !statusFormData.CloseTime.status,
                                                size: 'small'
                                            },
                                        }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper elevation={3} sx={{ height: 300, margin: 0, padding: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Shipment Details
                                    </Typography>
                                    <DataGrid
                                        rows={formData.shipment_details}
                                        columns={columns}
                                        pageSize={formData.shipment_details.length}
                                        rowsPerPageOptions={[formData.shipment_details.length]}
                                        hideFooter={true}
                                        disableRowSelectionOnClick
                                    />
                                </Paper>

                            </Grid>

                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>
                    <LoadingButton loading={loading} onClick={handleSave} variant={"contained"}>保存</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>

    );
};

export default PickupDialog;
