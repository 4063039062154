import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Snackbar} from '@mui/material';
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import {useDispatch} from "react-redux";

const EditFormDialog = ({ open, onClose, rowData, onSave }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        fedex_rate: null,
        ups_rate: null,
        credit_limit: 0,
        info: '',
        mtw_dx: '',
        fedex_jp: '',
        ups_jp: '',
        cancel_credit: 0,
        email: '',
        pfn: ''

    });
    const [error_info, SetError] = useState({
        email: false,
        credit_limit: false,
        fedex_jp: false,
        ups_jp: false,
        mtw_dx: false,
        fedex_rate: false,
        ups_rate: false


    })
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const dispatch = useDispatch();
    const [supplier_options, setSupplierOptions] = useState([])
    const [service_type_options, setServiceTypeOptions] = useState([])
    const [code, setCode] = useState({})
    // 当rowData改变时，更新表单数据
    useEffect(() => {
        if (rowData) {
            let row_data = {}
            Object.entries(rowData).map(([key, value]) => {
                    if(key in formData){
                        row_data[key] = value
                    }


            })
            setFormData(row_data);
        }
    }, [rowData]);

    const handleChange = (event) => {
        const { name, value } = event.target;

            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));

    };
    useEffect(() => {
        setLoading(true)
        // const fetchSupplierData = async () => {
        //     try {
        //         await AxiosSyncDefaultRequest({
        //             url: `${server.logistics_supplier_list}?page=0&size=100`,
        //             method: 'GET',
        //             success: (res) => {
        //                 let options = []
        //                 let code_options = {}
        //                 let rows = res.data.rows
        //                 for (const i in rows) {
        //                     options.push({id: rows[i].id, name: rows[i].name})
        //                     code_options[rows[i].id] = rows[i].code
        //                 }
        //                 setSupplierOptions(options)
        //                 setCode(code_options)
        //             },
        //             // fail: (error) => {
        //             //     console.error('请求失败', error);
        //             //     setData([])
        //             //     setLoading(false)
        //             // }
        //         });
        //     } catch (error) {
        //         console.error('请求异常', error);
        //     }
        // };
        // const fetchServiceTypeData = async () => {
        //     try {
        //         await AxiosSyncDefaultRequest({
        //             url: `${server.service_type}`,
        //             method: 'GET',
        //             success: (res) => {
        //                 console.log(res.data.results)
        //                 let options = []
        //                 let rows = res.data.results
        //                 for (const i in rows) {
        //                     options.push({id: rows[i].id, name: rows[i].name})
        //                 }
        //                 setServiceTypeOptions(options)
        //             },
        //             // fail: (error) => {
        //             //     console.error('请求失败', error);
        //             //     setData([])
        //             //     setLoading(false)
        //             // }
        //         });
        //     } catch (error) {
        //         console.error('请求异常', error);
        //     }
        // };
        // fetchSupplierData();
        // fetchServiceTypeData();
    }, [dispatch]);
    const handleSave = async () => {
        SetError(
            {
                email: false,
                credit_limit: false,
                fedex_jp: false,
                ups_jp: false,
                mtw_dx: false,
                fedex_rate: false,
                ups_rate: false
            }
        )
        if(formData.fedex_rate && formData.credit_limit && formData.email && formData.ups_rate && formData.fedex_jp && formData.ups_jp && formData.mtw_dx) {


            setLoading(true);
            let data = JSON.parse(JSON.stringify(formData));
            let keys = ["credit_limit", "cancel_credit"]
            for (let key in keys) {
                if (data[key]) {
                    data = {
                        [key]: formData[key].toFixed(2)
                    }
                }
            }
            if (data.password) {
                delete data.password
            }

            try {
                const result = await onSave(data, formData.id, 'PUT');
                if (result.status === true) {
                    // 操作成功，显示成功消息
                    setSnackbar({open: true, message: '保存成功！', severity: 'success'});
                }
            } catch (error) {
                console.error("保存失败", error);
                // 操作失败，显示错误消息
                // setSnackbar({ open: true, message: '保存失败，请重试。', severity: 'error' });
            } finally {
                // setLoading(false);
                onClose(); // 保存操作完成后关闭对话框
            }
        }
        else {
            if(!formData.credit_limit){
                SetError(p => ({
                    ...p,
                    credit_limit: true
                }))
            }
            if(!formData.fedex_rate){
                SetError(p => ({
                    ...p,
                    fedex_rate: true
                }))
            }
            if(!formData.ups_rate){
                SetError(p => ({
                    ...p,
                    ups_rate: true
                }))
            }

            if(!formData.fedex_jp){
                SetError(p => ({
                    ...p,
                    fedex_jp: true
                }))
            }
            if(!formData.ups_jp){
                SetError(p => ({
                    ...p,
                    ups_jp: true
                }))
            }
            if(!formData.email){
                SetError(p => ({
                    ...p,
                    email: true
                }))
            }
            if(!formData.mtw_dx){
                SetError(p => ({
                    ...p,
                    mtw_dx: true
                }))
            }
        }
    };
    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>编辑信息</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="fedex_rate"
                                label="fedex费率"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.fedex_rate}
                                onChange={handleChange}
                                required
                                error={error_info.fedex_rate}
                                helperText="此为必填项！"
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="ups_rate"
                                label="ups费率"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.ups_rate}
                                onChange={handleChange}
                                error={error_info.ups_rate}
                                helperText="此为必填项！"
                                required
                            />

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="credit_limit"
                                label="credit_limit"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.credit_limit}
                                onChange={handleChange}
                                error={error_info.credit_limit}
                                helperText="此为必填项！"
                                required
                            />

                        </Grid>

                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="info"
                                label="info"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.info}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="mtw_dx"
                                label="mtw_dx"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.mtw_dx}
                                onChange={handleChange}
                                error={error_info.mtw_dx}
                                helperText="此为必填项！"
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="fedex_jp"
                                label="fedex_jp"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.fedex_jp}
                                onChange={handleChange}
                                error={error_info.fedex_jp}
                                helperText="此为必填项！"
                                required
                            />

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="ups_jp"
                                label="ups_jp"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.ups_jp}
                                onChange={handleChange}
                                error={error_info.ups_jp}
                                helperText="此为必填项！"
                                required
                            />

                        </Grid>

                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="cancel_credit"
                                label="cancel_credit"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.cancel_credit}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="email"
                                label="email"
                                type="email"
                                fullWidth
                                variant="standard"
                                value={formData.email}
                                onChange={handleChange}
                                error={error_info.email}
                                helperText="此为必填项！"
                                required
                            />

                        </Grid>

                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="pfn"
                                label="pfn"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.pfn}
                                onChange={handleChange}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>
                    <Button loading={loading} onClick={handleSave} variant={"contained"}>保存</Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default EditFormDialog;
