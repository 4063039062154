import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Box,
    Snackbar
} from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

const re = {regex: /^(\+|-)?\d+(\.\d{0,2})?$/};

function UpdateClaimA({ data, claim_update_list, updateClaim, Refresh}) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [credit, setCredit] = useState(0);
    const [claimId, setClaimId] = useState(null);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const openDialog = () => {
        if (claim_update_list && claim_update_list.length === 1) {
            data.forEach((item) => {
                if (claim_update_list.includes(item.id)) {
                    if (item.status === '1') {
                        if (!snackbar.open) setSnackbar({open: true, message: '已确认索赔无法修改金额！', severity: 'error'});
                        return;
                    }
                    setCredit(item.Check_amount);
                    setClaimId(item.id);
                    setOpen(true);
                }
            });
        } else {
            if (!snackbar.open) setSnackbar({open: true, message: '请勾选一项进行修改！', severity: 'error'});

        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const result = await updateClaim("PUT", {Check_amount: credit, status: 'update'}, claimId);
            if (result?.status === 204) {
                setSnackbar({open: true, message: '修改成功！', severity: 'success'});
                handleClose();
            }else {
                setSnackbar({open: false, message: '修改失败！', severity: 'error'});
                handleClose();
            }
        } catch (e) {
            console.error("上传失败", e);
        } finally {
            setLoading(false);
            Refresh()

        }
    };

    // Assuming `re.regex` is defined elsewhere and valid for checking input format
    const handleCreditChange = (event) => {
        const { value } = event.target;
        if (re.regex.test(value) || value === '') {
            setCredit(value);
        }
    };

    return (
        <span>
            <Button sx={{ textTransform: 'none' }} startIcon={<AttachMoneyIcon />} onClick={openDialog}>
                修改金额
            </Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>修改金额</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl sx={{ m: 1, minWidth: 500 }}>
                            <TextField
                                id="Credit"
                                name="Credit"
                                inputMode="decimal"
                                type="number"
                                value={credit}
                                label="Credit"
                                variant="standard"
                                helperText="Please set the recharge amount, Maximum two decimal places"
                                onChange={handleCreditChange}
                                required
                            />
                        </FormControl>
                        <DialogActions>
                            <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2, ml: 45 }} onClick={handleClose}>
                                取消
                            </Button>
                            <LoadingButton loading={loading} fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                                确认
                            </LoadingButton>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </span>
    );
}
export default UpdateClaimA;