import React, {useState, useEffect} from 'react';

const ClaimManagement = "ClaimManagement";

function ClaimManagementPage() {
    return (
        <div>

        </div>
    )
}

export default ClaimManagement;
export {ClaimManagementPage};