import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
    RenderButtonField, RenderCloseField, RenderDesktopDatePickerField, RenderSelectField, RenderTextField
} from "../../components/renderField";
import React, {useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ExportPage from "../modal/ExportPageModal";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";
import {useDispatch} from "react-redux";
import Tooltip from "@mui/material/Tooltip";

export function FreightShipmentBar({searchData, setSearchData, search, setSearch, setCurrentPage}) {
    const [searchOpen, setSearchOpen] = useState(true);
    const handleSubmit = (e) => {
        e.preventDefault(); // 阻止表单默认提交行为
        setCurrentPage(0);
        setSearch()
    }
    const handleChange = (e, value, key, value_name) => {
        setSearchData(prevState => ({
            ...prevState,
            [key]: value?value[value_name]:''
        }));
    }
    const handleFieldChange = (fieldName) => (event) => {
        if(fieldName.includes("time")){
            setSearchData({ ...searchData, [fieldName]: event });
        }
        else {setSearchData({ ...searchData, [fieldName]: event.target.value });}

    };
    const ship_status_options = [
        {status: "-1", name: "未知"},
        {status: "1", name: "打单中"},
        {status: "2", name: "出单"},
        {status: "3", name: "完成"},
        {status: "4", name: "失败"},
        {status: "5", name: "未知错误"},
        {status: "6", name: "取消"},
    ];

    const handleClearData = () => {
        setSearchData({
            tracking_number: "", secondaryTrackNumber: "", Reference1: "", Warehouse: "", start_create_time: null, end_create_time: null,
            user: '', status: '', usps_tracking_number: ''
        });
    };

    const toggleSearchOpen = () => {
        setSearchOpen(!searchOpen);
    };
    const statusOptions = Object.entries(ship_status_options).map(([key, value]) => {
        return (
            <MenuItem value={key} key={key}>{value}</MenuItem>
        )
    });
    const [warehouse_options, SetWarehouseOptions] = useState([])
    const [user_list, SetUserList] = useState([]);
    const searchFormControl = {width: '80%', marginBottom: '5%'};
    const dispatch = useDispatch();
    useEffect(() => {
        // setLoading(true)
        const fetchSupplierData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.warehouse_list_url}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = [];
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        SetWarehouseOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchUserData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.user_list_url}`,
                    method: 'GET',
                    success: (res) => {
                        let user_option = [];
                        for (const k in res.data.UserList) {
                            user_option.push({id: k, username: res.data.UserList[k]})
                        }
                        SetUserList(user_option)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        fetchSupplierData();
        fetchUserData()
        // fetchServiceTypeData();
    }, [dispatch]);

    return (
        <span>
            {!searchOpen && (
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<SearchIcon />}
                    onClick={() => toggleSearchOpen()}
                    variant="text">
                    搜索
                </Button>
            )}
            {searchOpen && (
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <Paper sx={{p: 1}}>
                        <Grid container style={{marginBottom: "1%"}}>
                            {RenderTextField({
                                idAname: "tracking_number", label: "追踪号",
                                value: searchData.tracking_number,
                                onChangeHandler: handleFieldChange("tracking_number")
                            })}

                            {RenderTextField({
                                idAname: "Reference1", label: "客户备注",
                                value: searchData.Reference1,
                                onChangeHandler: handleFieldChange("Reference1")
                            })}
                            {RenderSelectField({
                                idAname: "Warehouse",
                                label: "仓库",
                                value: searchData.Warehouse,
                                onChangeHandler: (e, value) => {handleChange(e, value,"Warehouse", "id")},
                                v: "id",
                                value_name: "name",
                                select_options: warehouse_options,
                            })}
                        </Grid>
                        <Grid container style={{marginBottom: "1%"}}>

                            <Grid item lg={3} md={3} xs={3} sx={{paddingTop: '0.5%'}}>
                                <Grid container spacing={1}>
                                    {RenderDesktopDatePickerField({
                                        idAname: "start_create_time",
                                        label: "下单起始日期",
                                        value: searchData.start_create_time,
                                        onChangeHandler: handleFieldChange("start_create_time")
                                    })}
                                    {RenderDesktopDatePickerField({
                                        idAname: "end_create_time",
                                        label: "下单结束日期",
                                        value: searchData.end_create_time,
                                        onChangeHandler: handleFieldChange("end_create_time")
                                    })}
                                </Grid>
                            </Grid>
                            {RenderSelectField({
                                idAname: "user",
                                label: "用户",
                                value: searchData.user,
                                onChangeHandler: (e, value) => {handleChange(e, value,"user", "id")},
                                v: "id",
                                value_name: "username",
                                select_options: user_list
                            })}
                            {RenderSelectField({
                                idAname: "status",
                                label: "状态",
                                value: searchData.status,
                                onChangeHandler: (e, value) => {handleChange(e, value,"status", "status")},
                                select_options: ship_status_options,
                                v: "status",
                                value_name: "name"
                            })}
                        </Grid>
                    <Grid container>
                        <RenderButtonField
                            idAname="Search"
                            variant="contained"
                            text="查询"
                            buttonType="submit"
                            tip="条件查询"
                            onClickHandler={null}
                            loading={search}
                        />
                        {/*{RenderButtonField({*/}
                        {/*    idAname: "export",*/}
                        {/*    variant: "contained",*/}
                        {/*    text: "导出查询信息",*/}
                        {/*    // buttonType: "submit",*/}
                        {/*    tip: "查询条件导出",*/}

                        {/*    onClickHandler: (e) => {setSearch('export')*/}
                        {/*    }*/}
                        {/*})}*/}
                        <FormControl variant="standard" sx={{mr: 5}}>
                            <Tooltip title="查询条件导出">
                            <Button variant="contained" onClick={() => setSearch('export')}>导出查询信息</Button>
                            </Tooltip>
                        </FormControl>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <ExportPage type={'FF'}/>
                            </FormControl>
                        </Grid>
                        <RenderButtonField
                            idAname="Clear"
                            variant="outlined"
                            text="清空"
                            buttonType="button"
                            tip="清空查询信息"
                            onClickHandler={handleClearData}
                        />
                        <RenderCloseField onClickHandler={() => setSearchOpen(false)} />

                    </Grid>
                    </Paper>
                </Box>
            )}
        </span>
    )
}

