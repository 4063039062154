import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
    RenderButtonField, RenderCloseField, renderCloseField,
    RenderDesktopDatePickerField,
    RenderSelectField,
    RenderTextField
} from "../../../components/renderField";

import FormControl from "@mui/material/FormControl";
import ExportPage from "../../modal/ExportPageModal";
import {Link} from "@mui/material";
import Button from "@mui/material/Button";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, {useState} from "react";
import {GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";


export function ServiceBar({searchData, setSearchData, search, setSearch, setCurrentPage}) {

    const [searchOpen, setSearchOpen] = useState(true);
    const handleSubmit = (e) => {
        e.preventDefault(); // 阻止表单默认提交行为
        setCurrentPage(0);
        setSearch(true)
    }

    const handleFieldChange = (fieldName) => (event) => {
        setSearchData({ ...searchData, [fieldName]: event.target.value });
    };
    const handleClearData = () => {
        setSearchData({name: '', supplier: '', service_type: ''})
    }
    const toggleSearchOpen = () => {
        setSearchOpen(!searchOpen);
    };
    return (
        <div>
            {!searchOpen && (
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<SearchIcon />}
                    onClick={() => toggleSearchOpen()}
                    variant="text">
                    搜索
                </Button>
            )}
            {searchOpen && (

            <Box component="form" noValidate>
                <Paper sx={{p: 1}}>
                <Grid container style={{marginBottom: "1%"}}>
                    <RenderTextField
                        idAname="name"
                        label="服务名"
                        value={searchData.name}
                        onChangeHandler={handleFieldChange('name')}
                    />
                    <RenderTextField
                        idAname="supplier"
                        label="物流商"
                        value={searchData.supplier}
                        onChangeHandler={handleFieldChange('supplier')}
                    />
                    <RenderTextField
                        idAname="service_type"
                        label="服务类型"
                        value={searchData.service_type}
                        onChangeHandler={handleFieldChange('service_type')}
                    />
                </Grid>
                <Grid container>
                    <RenderButtonField
                        idAname="Search"
                        variant="contained"
                        text="查询"
                        buttonType="submit"
                        tip="条件查询"
                        onClickHandler={(e) => setSearch(e, searchData)}
                        loading={search}
                    />

                    <RenderButtonField
                        idAname="Clear"
                        variant="outlined"
                        text="清空"
                        buttonType="button"
                        tip="清空查询信息"
                        onClickHandler={handleClearData}
                    />
                    <RenderCloseField onClickHandler={() => setSearchOpen(false)} />

                </Grid>
                </Paper>
            </Box>
            )}
        </div>
    )
}

