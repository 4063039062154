import {
    CLOSE_ALERT,
    OPEN_ALERT

} from "../type";

const initialState = {
    open: false,
    level: null,
    title: null,
    message: null,
    closeFunc: null
};

export default function (preState=initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case OPEN_ALERT:
            return {
                ...preState,
                open: true,
                level: payload.level,
                title: payload.title,
                message: payload.message,
                closeFunc: payload.closeFunc,
            }
        case CLOSE_ALERT:
            return {
                ...preState,
                open: false
            }
        default:
            return preState

    }
}