import {AxiosDefaultRequest} from "../../components/default_request";
import server from "../../server";
import {LOAD_ACCOUNT, ACCOUNT_LOADING, SET_CURRENT, ACCOUNT} from "../type";
import store from "../store";
import {sort_order} from "../../components/utils";

export const loadAccount = (offset, limit, setPage = null, setPageSize = null, options=null, reload=null, orders=null) => dispatch => {
    dispatch({type: ACCOUNT_LOADING})
    let url = server.account + "?offset=" + offset + "&limit=" + limit
    if (options) {
        for (let i in options) {
            if (options[i] !== '') {
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    if (orders){
        url += sort_order(orders);
        url = url.replace("sort", "ordering")
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            let rows = res.data.rows
            let checked = {}
            let show = {}
            for(let i in rows){
                checked[rows[i].id] = rows[i].is_active
            }
            const result = {data: rows, rowCount: res.data.total, isLoading: false, display: false, checked};
            if (String(setPage) && setPage !== null) {
                result['page'] = setPage
            } else {
                result['page'] = store.getState().account.page;
            }
            if (String(setPageSize) && setPageSize !== null) {
                result['pageSize'] = setPageSize
            } else {
                result['pageSize'] = store.getState().account.pageSize;
            }
            dispatch({type: SET_CURRENT, payload: {current: ACCOUNT, searchTable: ACCOUNT, currentUrl: "/index/account"}})
            dispatch({type: LOAD_ACCOUNT, payload: result})
        }
    })
}