import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Box, Snackbar} from '@mui/material';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";

function RevokeClaimA({ data, claim_update_list, revokeClaim, Refresh }) {
    const [open, setOpen] = useState(false);
    const [claimCredit, setClaimCredit] = useState(0);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const openDialog = () => {
        let tempClaimCredit = 0;
        if (claim_update_list && claim_update_list.length >= 1) {
            for (let i = 0; i < claim_update_list.length; i++) {
                const matchingData = data.find(d => d.id === claim_update_list[i]);
                if (matchingData) {
                    if (matchingData.status === '0') {
                        if (!snackbar.open) setSnackbar({open: true, message: '无法对未确认的数据进行操作！', severity: 'error'});
                        return;
                    } else {
                        tempClaimCredit += Number(matchingData.Check_amount);
                    }
                }
            }
            setOpen(true);
            setClaimCredit(tempClaimCredit);
        } else {
            if (!snackbar.open) setSnackbar({open: true, message: '请勾选一项或多项进行修改！', severity: 'error'});
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            console.log(claim_update_list)
            const result = await revokeClaim("PUT", {ids: claim_update_list, status: 'revoke'}, claim_update_list[0]);
            if (result?.status === 204) {
                setSnackbar({open: true, message: '撤回索赔成功！', severity: 'success'});
                handleClose();
            }else {
                setSnackbar({open: false, message: '撤回索赔失败！', severity: 'error'});
                handleClose();
            }
        } catch (e) {
            console.error("撤回索赔失败", e);
        } finally {
            setLoading(false);
            Refresh()

        }
    };
    return (
        <span>
            <Button sx={{ textTransform: 'none' }} startIcon={<AssignmentReturnIcon />} onClick={openDialog}>
                撤回索赔
            </Button>
            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogTitle>撤回索赔</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <FormControl sx={{ m: 1, minWidth: 500 }}>
                            即将对此{claim_update_list.length}个数据进行撤回
                            <br /><br /><br />
                            总撤回额度: {claimCredit.toFixed(2)}
                        </FormControl>
                        <DialogActions>
                            <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2, ml: 45 }} onClick={handleClose}>
                                取消
                            </Button>
                            <LoadingButton loading={loading} fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                                确认
                            </LoadingButton>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </span>
    );
}

export default RevokeClaimA