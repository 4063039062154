import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ErrorDialog({error_info, open, setOpen}) {

    return (
        <React.Fragment>
            <Box>
            <Dialog
                open={open}
                onClose={setOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // sx={{width: "50%"}}
            >
                <DialogTitle id="alert-dialog-title">
                    {"错误信息!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {error_info}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            </Box>
        </React.Fragment>
    );
}