import React, {Component, useState} from 'react';
import store from "../redux/store";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dayjs from 'dayjs';

import {
    ACCOUNT,
    ACCOUNT_SEARCH, ACCOUNT_SEARCH_UPDATE, ADD_CREDIT_RECORD_SEARCH, ADMIN, ADMIN_SEARCH, ADMIN_SEARCH_UPDATE, BILL,
    BILL_SEARCH, BILL_SEARCH_UPDATE, CLOSE_ALERT, OPEN_ALERT, SHIPMENT, SHIPMENT_SEARCH, SHIPMENT_SEARCH_UPDATE,
    CLAIM, CLAIM_SEARCH_UPDATE, CLAIM_SEARCH, RATER_SEARCH_UPDATE, RATER_SEARCH, RATER, EXPORT_PAGE_SEARCH_UPDATE
} from "../redux/type";
import List from "@mui/material/List";
import FormControl from "@mui/material/FormControl";
import {AxiosDefaultRequest} from "./default_request";
import server from "../server";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {Link} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ExportPage from "../pages/modal/ExportPageModal"
import { zhCN } from 'date-fns/locale';
import {getMaxDate} from './utils'
// import dayjs from 'dayjs';
import { format, parseISO } from 'date-fns';

import {
    RenderButtonField,
    renderCloseField,
    RenderDesktopDatePickerField,
    RenderSelectField,
    RenderTextField
} from "./renderField";
import {useSelector} from "react-redux";

const searchFormControl = {width: '80%', marginBottom: '5%'};
const searchFormControl2 = {width: '80%', marginBottom: '1%', marginLeft: '-2%', marginTop: "5%"};
const searchFormControl3 = {width: '80%', marginBottom: '1%', marginLeft: '0%', marginTop: "5%"};
const searchFormControl4 = {width: '80%', marginBottom: '1%', marginLeft: '-25%', marginTop: "5%"};  // end upload time
const searchFormControl5 = {width: '80%', marginBottom: '1%', marginLeft: '-20%', marginTop: "5%"};  // end check time
const searchFormControl6 = {width: '85%', marginBottom: '1%', marginLeft: '-6.5%'};
const searchFormControl7 = {width: '85%', marginBottom: '5%'};
const searchFormControl8 = {width: '80%', marginBottom: '1%', marginLeft: '-6%', marginTop: "5%"};
const searchFormControl9 = {width: '80%', marginBottom: '1%', marginLeft: '-25%', marginTop: "5%"};

const SearchTable = ({ user, refund=null, with_holding=null, lump_sum=null }) => {
    const [searchOpen, setSearchOpen] = useState(true);
    const searchTable = useSelector(state => state.indexReducer.searchTable);

    const toggleDrawer = () => setSearchOpen(!searchOpen);

    // 根据 searchTable 状态决定要渲染的组件
    let ComponentToRender;
    switch (searchTable) {
        case SHIPMENT:
            ComponentToRender = ShipmentSearchTable;
            break;
        case BILL:
            ComponentToRender = BillSearchTable;
            break;
        case ACCOUNT:
            ComponentToRender = AccountSearchTable;
            break;
        case ADMIN:
            ComponentToRender = AdminSearchTable;
            break;
        case CLAIM:
            ComponentToRender = ClaimSearchTable;
            break;
        case RATER:
            ComponentToRender = RaterSearchTable;
            break;
        default:
            ComponentToRender = null;
    }
    const closeAlert = () => {
        store.dispatch({type: CLOSE_ALERT})
    }
    return (
        <span>
      <Paper sx={{ paddingLeft: '1%' }}>
        {searchOpen && (
            <Box role="presentation">
                {ComponentToRender && <ComponentToRender reqUser={user} closeFunc={toggleDrawer} closeAlert={closeAlert} with_holding={with_holding} refund={refund}   lump_sum={ lump_sum}/>}
            </Box>
        )}
      </Paper>
            {!searchOpen && (
                <Button sx={{ textTransform: 'none' }} startIcon={<SearchIcon />} onClick={toggleDrawer} variant="text">
                    搜索
                </Button>
            )}
    </span>
    );
};

export default SearchTable;


// TODO 后续更新 改动任意字段失去焦点后都会发送请求
class ShipmentSearchTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            warehouse_options: [],
            warehouse_id: '',
            tracking_number: '',
            usps_tracking_number: '',
            start_create_time: null,
            end_create_time: null,
            user_list: [],
            secondaryTrackNumber: '',
            Reference1: '',
            service_name: null,
            ship_status: null,
            user: null,
            // # -1:None 1: 打单中 2: 生成单 3:已完成 4:打单失败 5:未知错误 6:取消单
            ship_status_options: [
                {status: "-1", name: "未知"},
                {status: "1", name: "打单中"},
                {status: "2", name: "出单"},
                {status: "3", name: "完成"},
                {status: "4", name: "失败"},
                {status: "5", name: "未知错误"},
                {status: "6", name: "取消"},
            ],
            // service_options: ["None", "FEDEX_GROUND", "FEDEX_HOME_DELIVERY", "FEDEX_MWT", "FEDEX_SMART_POST",
            //     "FEDEX_2_DAY_Flat_Rate", "FEDEX_2_DAY_LRN", "UPS_GROUND", "UPS_GROUND_2"],
            service_options: []
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        // TODO 验证
        if (this.state.tracking_number || this.state.secondaryTrackNumber || this.state.Reference1 || this.state.warehouse_id ||
            this.state.usps_tracking_number || this.state.start_create_time || this.state.end_create_time ||
            this.state.service_name || this.state.ship_status || this.state.user) {
            this.search(true)
        } else {
            // level, title, message, options, closeFunc
            store.dispatch({
                type: OPEN_ALERT, payload: {
                    level: 'error',
                    title: 'FAILED',
                    message: '请输入至少一个条件.',
                    closeFunc: this.props.closeAlert
                }
            })
        }
    }
    search = (isClick, payload) => {
        if (isClick) {
            store.dispatch({
                type: SHIPMENT_SEARCH
            })
        } else {
            this.setState(payload);
            store.dispatch({
                type: SHIPMENT_SEARCH_UPDATE, payload: payload
            })
        }
    }

    componentDidMount() {
        AxiosDefaultRequest({
            url: server.warehouse_list_url,
            success: res => {
                let options = [];
                let rows = res.data.rows
                for (const i in rows) {
                    options.push({id: rows[i].id, name: rows[i].name})
                }
                this.setState({warehouse_options: options})
            }
        })
        AxiosDefaultRequest({
            url: server.user_list_url,
            success: res => {
                let user_option = [];
                for (const k in res.data.UserList) {
                    user_option.push({id: k, username: res.data.UserList[k]})
                }
                this.setState({user_list: user_option})
            }
        })
        AxiosDefaultRequest({
            url: `${server.select_logistics_service_list}?service_type=CreateShipment&service_type=ReturnShipment`,
            success: res => {
                let service_options = [];
                let rows = res.data.rows
                for (const i in rows) {
                    service_options.push({service_name: rows[i].service_number, description: rows[i].description?rows[i].description:''})
                }
                this.setState({service_options: service_options})
            }
        })
    }

    ChangeWarehouse = (e, newValue) => {
        // this.search(false, {'warehouse_id': newValue ? newValue.id : ''});
        const warehouseId = newValue ? newValue.id : null;
        this.setState({ warehouse_id: warehouseId }, () => {
            this.search(false, { 'warehouse_id': warehouseId });
        });
        // this.setState({'warehouse_id': newValue ? newValue.id : null})
    }
    ChangeStatus=(e, newValue) => {
        this.search(false, {ship_status: newValue ? newValue.status: ''})
}
    ChangeUser = (e, newValue) => {
        this.search(false, {user:newValue ? newValue.id:''})
    }
    ChangeServiceName =(e, newValue) => {
        this.search(false, {service_name:newValue ? newValue.service_name:''})
    }
    clearSearch = () => {
        this.search(false, {
            tracking_number: '',
            secondaryTrackNumber: '',
            Reference1: '',
            warehouse_id: '',
            usps_tracking_number: '',
            start_create_time: null,
            end_create_time: null,
            service_name: null,
            user: '',
            ship_status: ''
        });
    }
    render() {
        // const serviceOptions = this.state.service_options.map(option => (
        //     <MenuItem value={option} key={option}>{option}</MenuItem>
        // ));
        // const statusOptions = []
        // for (let option in this.state.ship_status_options){
        //
        // }
        // const statusOptions = Object.entries(this.state.ship_status_options).map(([key, value]) => (
        //     <MenuItem value={key} key={key}>{value}</MenuItem>
        // ));

        return (
            <div>
                <Box component="form" onSubmit={this.handleSubmit} noValidate>
                    <Grid container style={{marginBottom: "1%"}}>
                        {RenderTextField({
                            idAname: "tracking_number", label: "追踪号",
                            value: this.state.tracking_number,
                            onChangeHandler: (e) => { this.search(false, {'tracking_number': e.target.value}); }
                        })}
                        {/*{RenderTextField({*/}
                        {/*    idAname: "secondaryTrackNumber", label: "子单号(模糊查询)",*/}
                        {/*    value: this.state.secondaryTrackNumber,*/}
                        {/*    onChangeHandler: (e) => { this.search(false, {'secondaryTrackNumber': e.target.value}); }*/}
                        {/*})}*/}
                        {RenderTextField({
                            idAname: "usps_tracking_number", label: "USPS单号",
                            value: this.state.usps_tracking_number,
                            onChangeHandler: (e) => { this.search(false, {'usps_tracking_number': e.target.value}); }
                        })}
                        {RenderTextField({
                            idAname: "Reference1", label: "客户备注",
                            value: this.state.Reference1,
                            onChangeHandler: (e) => { this.search(false, {'Reference1': e.target.value}); }
                        })}
                        {this.props.with_holding ? <p style={{height: 10, marginTop: 27, marginLeft: 20}}>{`下单扣款：${this.props.with_holding}`}</p> : ""}
                    </Grid>
                    <Grid container style={{marginBottom: "1%"}}>
                        {RenderSelectField({
                            idAname: "Warehouse",
                            label: "仓库",
                            value: this.state.warehouse_id,
                            onChangeHandler: (e, newValue) => {this.ChangeWarehouse(e, newValue)},
                            select_options: this.state.warehouse_options,
                            value_name: "name",
                            v: "id"
                        })}
                        <Grid item lg={3} md={3} xs={3} sx={{paddingTop: '0.5%'}}>
                            <Grid container spacing={1}>
                                {RenderDesktopDatePickerField({
                                    idAname: "start_create_time",
                                    label: "下单起始日期",
                                    value: this.state.start_create_time,
                                    onChangeHandler: (e) => {this.search(false, {'start_create_time': e})}
                                })}
                                {RenderDesktopDatePickerField({
                                    idAname: "end_create_time",
                                    label: "下单结束日期",
                                    value: this.state.end_create_time,
                                    onChangeHandler: (e) => {this.search(false, {'end_create_time': e})}
                                })}
                            </Grid>
                        </Grid>
                        {RenderSelectField({
                            idAname: "user",
                            label: "用户",
                            value: this.state.user,
                            onChangeHandler: (e, newValue) => {this.ChangeUser(e, newValue)},
                            // onChangeHandler: (e) => {this.search(false, {'user': e.target.value})},
                            value_name: "username",
                            v: "id",
                            select_options: this.state.user_list
                        })}
                        {this.props.refund ? <p style={{height: 10, marginTop: 27, marginLeft: 20}}>{`退款金额：${this.props.refund}`}</p> : ""}
                    </Grid>
                    <Grid container style={{marginBottom: "1%"}}>
                        {RenderSelectField({
                            idAname: "service_name",
                            label: "服务类型",
                            value: this.state.service_name,
                            value_name: "description",
                            v: "service_name",
                            onChangeHandler: (e, newValue) => {this.ChangeServiceName(e, newValue)},
                            // onChangeHandler: (e) => {this.search(false, {'service_name': e.target.value})},
                            select_options: this.state.service_options
                        })}
                        {RenderSelectField({
                            idAname: "ship_status",
                            label: "状态",
                            value: this.state.ship_status,
                            onChangeHandler: (e, newValue) => {this.ChangeStatus(e, newValue)},
                            // onChangeHandler: (e) => {this.search(false, {'ship_status': e.target.value})},
                            value_name: "name",
                            v: "status",
                            select_options: this.state.ship_status_options
                        })}
                        <div style={{width: '25%', display: "inline-block"}}></div>

                        {this.props.lump_sum ? <p style={{height: 10, marginTop: 27, marginLeft: 20}}>{`合计余额变动：${this.props.lump_sum}`}</p> : ""}
                    </Grid>
                    <Grid container>
                        {RenderButtonField({
                            idAname: "Search",
                            variant: "contained",
                            text: "查询",
                            buttonType: "submit",
                            tip: "条件查询",
                            onClickHandler: null
                        })}
                        {RenderButtonField({
                            idAname: "export",
                            variant: "contained",
                            text: "导出查询信息",
                            buttonType: "submit",
                            tip: "查询条件导出",
                            onClickHandler: (e) => {this.search(false, {'submitType': 'export'})
                            }
                        })}
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <ExportPage type={'SE'}/>
                            </FormControl>
                        </Grid>
                        {RenderButtonField({
                            idAname: "Clear",
                            variant: "outlined",
                            text: "清空",
                            buttonType: "button",
                            tip: "清空查询信息",
                            onClickHandler: () => {this.clearSearch()}
                        })}
                        {renderCloseField({onClickHandler: () => {this.props.closeFunc()}})}
                    </Grid>
                </Box>
            </div>
        );
    }
}

class BillSearchTable extends Component {
    constructor(props) {
        super(props);
        /*
        * 1.4.X.0609202204I [bill页面]搜索增加bill_type[下拉框],master_tracking,tracking_number,user,时间范围(create_time)
        *     # 2 bill,3 admin,4 void,5 shipment,7 adjust
        * */
        this.state = {
            description: '', bill_type: '', master_tracking: '',
            bill_track: '', user: '', bill_invoice: "",
            start_create_time: null, end_create_time: null,
            bill_type_select: [], user_list: [], reqUser: props['reqUser']
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.description || this.state.bill_type || this.state.master_tracking || this.state.bill_track ||
            this.state.user || this.state.start_create_time || this.state.end_create_time || this.state.bill_invoice) {
            let payload = {
                description: this.state.description, bill_type: this.state.bill_type,
                master_tracking: this.state.master_tracking, bill_track: this.state.bill_track,
                user: this.state.user, start_create_time: this.state.start_create_time, end_create_time: this.state.end_create_time,
                bill_invoice: this.state.bill_invoice
            }
            this.search(true, payload)
        } else {
            // level, title, message, options, closeFunc
            store.dispatch({
                type: OPEN_ALERT, payload: {
                    level: 'error',
                    title: 'FAILED',
                    message: '请输入至少一个查询条件',
                    closeFunc: this.props.closeAlert
                }
            })
        }
    }

    componentDidMount() {
        let options = [<MenuItem aria-label="BillType_None" key={'BillType_None'} value={""}>不选择</MenuItem>];
        let user_option = [<MenuItem aria-label="User_None" key={'User_None'} value={""}>不选择</MenuItem>];
        const bill_type_select = {2: '账单', 3: '充值', 4: '取消', 5: '出单', 6: '重置', 7: '调整', 8: "重置账单"};
        for (const i in bill_type_select) {
            options.push({status: i, label: bill_type_select[i]})
        }
        AxiosDefaultRequest({
            url: server.user_list_url,
            success: res => {
                for (const k in res.data.UserList) {
                    user_option.push({id: k, username: res.data.UserList[k]});
                }
                this.setState({bill_type_select: options, user_list: user_option});
            }
        })
    }

    ChangeBillType = (e) => {
        this.search(false, {'bill_type': e.target.value});
    }
    ChangeField = (e, newValue, key, value_key) => {
        this.search(false, {[key]:newValue ? newValue[value_key]:''})
    }
    ChangeUser = (e) => {
        this.search(false, {'user': e.target.value});
    }
    search = (isClick, payload) => {
        if (isClick) {
            // export
            store.dispatch({type: BILL_SEARCH, payload: payload})
        } else {
            this.setState(payload);
            store.dispatch({
                type: BILL_SEARCH_UPDATE, payload: payload
            })
        }
    }
    clearSearch = () => {
        this.search(false, {
            "description": '', "bill_type": '', "master_tracking": '',
            "bill_track": '', "user": '', "start_create_time": null, "end_create_time": null,
            "bill_invoice": '',
        });
    }
    render() {
        return (
            <div>
                <Box component="form" onSubmit={this.handleSubmit} noValidate>
                    <Grid container style={{marginBottom: "1%"}}>
                        {RenderSelectField({
                            idAname: "bill_type",
                            label: "对账状态",
                            value: this.state.bill_type,
                            v: "status",
                            value_name: "label",
                            onChangeHandler: (e, value) => {this.ChangeField(e, value, "bill_type", "status")},
                            select_options: this.state.bill_type_select
                        })}
                        {RenderTextField({
                            idAname: "master_tracking", label: "主单号",
                            value: this.state.master_tracking,
                            onChangeHandler: (e) => { this.search(false, {'master_tracking': e.target.value}); }
                        })}
                        {RenderSelectField({
                            idAname: "user",
                            label: "用户",
                            value: this.state.user,
                            value_name: "username",
                            v: "id",
                            onChangeHandler: (e, newValue) => {this.ChangeField(e, newValue, "user", "id")},
                            select_options: this.state.user_list
                        })}
                    </Grid>
                    <Grid container style={{marginBottom: "1%"}}>
                        <Grid item lg={3} md={3} xs={3} sx={{paddingTop: '0.5%'}}>
                            <Grid container spacing={1}>
                                {RenderDesktopDatePickerField({
                                    idAname: "start_create_time",
                                    label: "创建起始日期",
                                    value: this.state.start_create_time,
                                    onChangeHandler: (e) => {this.search(false, {'start_create_time': e})}
                                })}
                                {RenderDesktopDatePickerField({
                                    idAname: "end_create_time",
                                    label: "创建结束日期",
                                    value: this.state.end_create_time,
                                    onChangeHandler: (e) => {this.search(false, {'end_create_time': e})}
                                })}
                            </Grid>
                        </Grid>
                        {RenderTextField({
                            idAname: "bill_invoice", label: "账单号",
                            value: this.state.bill_invoice,
                            onChangeHandler: (e) => { this.search(false, {'bill_invoice': e.target.value}); }
                        })}
                    </Grid>
                    <Grid container style={{paddingBottom: "1%"}}>
                        {RenderButtonField({
                            idAname: "Search",
                            variant: "contained",
                            text: "查询",
                            buttonType: "submit",
                            tip: "条件查询",
                            onClickHandler: null
                        })}
                        {RenderButtonField({
                            idAname: "export",
                            variant: "contained",
                            text: "导出查询信息",
                            buttonType: "submit",
                            tip: "查询条件导出",
                            onClickHandler: (e) => {this.search(false, {'submitType': 'export'})
                            }
                        })}
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <ExportPage type={'BE'}/>
                            </FormControl>
                        </Grid>
                        {RenderButtonField({
                            idAname: "Clear",
                            variant: "outlined",
                            text: "清空",
                            buttonType: "button",
                            tip: "清空查询信息",
                            onClickHandler: () => {this.clearSearch()}
                        })}
                        {renderCloseField({onClickHandler: () => {this.props.closeFunc()}})}
                    </Grid>
                </Box>
            </div>
        );
    }
}

class AccountSearchTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '', AccountUid: ''
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.username || this.state.UID) {
            this.search(true);
        } else {
            // level, title, message, options, closeFunc
            store.dispatch({
                type: OPEN_ALERT, payload: {
                    level: 'error',
                    title: 'FAILED',
                    message: '请输入至少一个查询条件',
                    closeFunc: this.props.closeAlert
                }
            })
        }
    }
    search = (isClick, payload) => {
        if (isClick) {
            store.dispatch({type: ACCOUNT_SEARCH})
        } else {
            this.setState(payload);
            store.dispatch({
                type: ACCOUNT_SEARCH_UPDATE, payload: payload
            })
        }
    }
    clearSearch = () => {
        this.search(false, {username: '', UID: ''})
    }
    render() {
        return (
            <div>
                <Box component="form" onSubmit={this.handleSubmit} noValidate>
                    <Grid container style={{marginBottom: "1%"}}>
                        {RenderTextField({
                            idAname: "username", label: "用户名",
                            value: this.state.username,
                            onChangeHandler: (e) => { this.search(false, {'username': e.target.value}); }
                        })}
                        {RenderTextField({
                            idAname: "UID", label: "用户ID",
                            value: this.state.UID,
                            onChangeHandler: (e) => { this.search(false, {'UID': e.target.value}); }
                        })}
                    </Grid>
                    <Grid container style={{paddingBottom: "1%"}}>
                        {RenderButtonField({
                            idAname: "Search",
                            variant: "contained",
                            text: "查询",
                            buttonType: "submit",
                            tip: "条件查询",
                            onClickHandler: null
                        })}
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <ExportPage type={'RR'}/>
                            </FormControl>
                        </Grid>
                        {RenderButtonField({
                            idAname: "Clear",
                            variant: "outlined",
                            text: "清空",
                            buttonType: "button",
                            tip: "清空查询信息",
                            onClickHandler: () => {this.clearSearch()}
                        })}
                        {renderCloseField({onClickHandler: () => {this.props.closeFunc()}})}
                    </Grid>
                </Box>
            </div>
        );
    }
}

class AdminSearchTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            import_name: '', record_status: '', record_type: '', status_options: [], type_options: []
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.import_name || this.state.record_status || this.state.record_type) {
            this.search(true)
        } else {
            // level, title, message, options, closeFunc
            store.dispatch({
                type: OPEN_ALERT, payload: {
                    level: 'error',
                    title: 'FAILED',
                    message: '请输入至少一个查询条件',
                    closeFunc: this.props.closeAlert
                }
            })
        }
    }

    componentDidMount() {
        const status_options = [];
        const status_options_dict = { 0: '初始化', 1: '处理中', 2: '完成', 3: '错误' };
        for (const key in status_options_dict) {
            if (status_options_dict.hasOwnProperty(key)) {
                status_options.push(
                    {record_status: key, name: status_options_dict[key]}
                );
            }
        }

        const type_options = [];
        const type_options_dict = { UI: 'UI', TK: 'TK', GS: 'GS' };
        for (const key in type_options_dict) {
            if (type_options_dict.hasOwnProperty(key)) {
                type_options.push(
                    {type: key, name: type_options_dict[key]}
                );
            }
        }

        this.setState({ status_options, type_options });
    }

    ChangeStatus = (e, newValue) => {
        // this.search(false, {'record_status': e.target.value});
        this.search(false, {record_status:newValue ? newValue.record_status:''})
    }
    ChangeType = (e, newValue) => {
        // this.search(false, {'record_type': e.target.value});
        this.search(false, {record_type:newValue ? newValue.type:''})
    }
    search = (isClick, payload) => {
        if (isClick) {
            store.dispatch({type: ADMIN_SEARCH})
        } else {
            this.setState(payload);
            store.dispatch({
                type: ADMIN_SEARCH_UPDATE, payload: payload
            })
        }
    }
    clearSearch = () => {
        this.search(false, {import_name: '', record_status: '', record_type: ''})
    }
    render() {
        return (
            <div>
                <Box component="form" onSubmit={this.handleSubmit} noValidate>
                    <Grid container style={{marginBottom: "1%"}}>
                        {RenderTextField({
                            idAname: "import_name", label: "导入文件名",
                            value: this.state.import_name, helperText: "不需要填写后缀名[.txt .xlsx .xls ...]",
                            onChangeHandler: (e) => { this.search(false, {'import_name': e.target.value}); }
                        })}
                        {RenderSelectField({
                            idAname: "record_status",
                            label: "状态",
                            value: this.state.record_status,
                            onChangeHandler: (e, newValue) => {this.ChangeStatus(e, newValue)},
                            select_options: this.state.status_options,
                            v: "record_status",
                            value_name: "name",
                        })}
                        {RenderSelectField({
                            idAname: "record_type",
                            label: "记录类型",
                            value: this.state.record_type,
                            onChangeHandler: (e, newValue) => {this.ChangeType(e, newValue)},
                            select_options: this.state.type_options,
                            value_name: "type",
                            v: "name"
                        })}
                    </Grid>
                    <Grid container sx={{paddingBottom: '0.5%'}}>
                        {RenderButtonField({
                            idAname: "Search",
                            variant: "contained",
                            text: "查询",
                            buttonType: "submit",
                            tip: "条件查询",
                            onClickHandler: null
                        })}
                        {RenderButtonField({
                            idAname: "Clear",
                            variant: "outlined",
                            text: "清空",
                            buttonType: "button",
                            tip: "清空查询信息",
                            onClickHandler: () => {this.clearSearch()}
                        })}
                        {renderCloseField({onClickHandler: () => {this.props.closeFunc()}})}
                    </Grid>
                </Box>
            </div>
        );
    }
}

// TODO 后续更新 改动任意字段失去焦点后都会发送请求
class ClaimSearchTable extends Component {
    constructor(props) {
        super(props);
        let options = [
            <MenuItem aria-label="None" key={'None'} value={""}>不选择</MenuItem>,
            <MenuItem aria-label="None" key={'0'} value={"0"}>未确认</MenuItem>,
            <MenuItem aria-label="None" key={'1'} value={"1"}>确认</MenuItem>,
            // <option aria-label="None" key={'2'} value={"2"}>已出账</option>,
        ];
        this.state = {
            tracking_number: '',
            case_number: '',
            EFT: '',
            UID: '',
            status: '',
            invoice_number: '',
            start_check_time: null,
            end_check_time: null,
            start_upload_time: null,
            end_upload_time: null,
            status_options: options,
            returned: '',
            returned_options: [
                <MenuItem aria-label="None" key={'None'} value={""}>不选择</MenuItem>,
                <MenuItem aria-label="None" key={'0'} value={"0"}>未退款</MenuItem>,
                <MenuItem aria-label="None" key={'1'} value={"1"}>已退款</MenuItem>,
                // <option aria-label="None" key={'2'} value={"2"}>已出账</option>,
            ]

        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        // TODO 验证
        if (this.state.tracking_number || this.state.case_number || this.state.EFT || this.state.start_check_time ||
            this.state.start_upload_time || this.state.end_check_time || this.state.end_upload_time || this.state.invoice_number ||
            this.state.UID || this.state.status || this.state.returned) {
            this.search(true)
        } else {
            // level, title, message, options, closeFunc
            store.dispatch({
                type: OPEN_ALERT, payload: {
                    level: 'error',
                    title: 'FAILED',
                    message: '请输入至少一个查询条件',
                    closeFunc: this.props.closeAlert
                }
            })
        }
    }
    search = (isClick, payload) => {
        if (isClick) {
            store.dispatch({
                type: CLAIM_SEARCH, payload: payload
            })
        } else {
            this.setState(payload);
            store.dispatch({
                type: CLAIM_SEARCH_UPDATE, payload: payload
            })
        }
    }
    ClaimChangeStatus = (e) => {
        this.search(false, {'status': e.target.value});
    }
    ClaimChangeReturned = (e) => {
        this.search(false, {'returned': e.target.value});
    }
    clearSearch = () => {
        this.search(false, {
            tracking_number: '', case_number: '', EFT: '',
            UID: '', status: '', returned: '', invoice_number: '',
            start_check_time: null, start_upload_time: null,
            end_check_time: null, end_upload_time: null,
        })
    }
    render() {
        return (
            <div>
                <Box component="form" onSubmit={this.handleSubmit} noValidate>
                    <Grid container style={{marginBottom: "1%"}}>
                        {RenderTextField({
                            idAname: "Case_number", label: "Case_number",
                            value: this.state.case_number,
                            onChangeHandler: (e) => { this.search(false, {'case_number': e.target.value}); }
                        })}
                        {RenderTextField({
                            idAname: "Tracking_number",
                            label: "Tracking Number",
                            value: this.state.tracking_number,
                            onChangeHandler: (e) => {this.search(false, {'tracking_number': e.target.value});}
                        })}
                        {RenderTextField({
                            idAname: "EFT",
                            label: "EFT",
                            value: this.state.EFT,
                            onChangeHandler: (e) => {this.search(false, {'EFT': e.target.value});}
                        })}
                        {RenderTextField({
                            idAname: "UID",
                            label: "用户ID",
                            value: this.state.UID,
                            onChangeHandler: (e) => {this.search(false, {'UID': e.target.value});}
                        })}
                    </Grid>
                    <Grid container style={{marginBottom: "1%"}}>
                        <Grid item lg={3} md={3} xs={3} sx={{paddingTop: '0.5%'}}>
                            <Grid container spacing={1}>
                                {RenderDesktopDatePickerField({
                                    idAname: "start_check_time",
                                    label: "起始确认时间",
                                    value: this.state.start_check_time,
                                    onChangeHandler: (e) => {this.search(false, {'start_check_time': e})}}
                                )}
                                {RenderDesktopDatePickerField({
                                    idAname: "end_check_time",
                                    label: "结束确认时间",
                                    value: this.state.end_check_time,
                                    onChangeHandler: (e) => {this.search(false, {'end_check_time': e})}}
                                )}
                            </Grid>
                        </Grid>
                        <Grid item lg={3} md={3} xs={3} sx={{paddingTop: '0.5%'}}>
                            <Grid container spacing={1}>
                                {RenderDesktopDatePickerField({
                                    idAname: "start_upload_time",
                                    label: "起始上传时间",
                                    value: this.state.start_upload_time,
                                    onChangeHandler: (e) => {this.search(false, {'start_upload_time': new Date(e).toLocaleDateString("en")})}
                                })}
                                {RenderDesktopDatePickerField({
                                    idAname: "end_upload_time",
                                    label: "结束上传时间",
                                    value: this.state.end_upload_time,
                                    onChangeHandler: (e) => {this.search(false, {'end_upload_time': new Date(e).toLocaleDateString("en")})}
                                })}
                            </Grid>
                        </Grid>
                        {RenderSelectField({
                            idAname: "Status",
                            label: "状态",
                            value: this.state.status,
                            onChangeHandler: (e) => {this.ClaimChangeStatus(e)},
                            select_options: this.state.status_options
                            })}
                        {RenderSelectField({
                            idAname: "Returned",
                            label: "退款状态",
                            value: this.state.returned,
                            onChangeHandler: (e) => {this.ClaimChangeReturned(e)},
                            select_options: this.state.returned_options
                            })}
                    </Grid>
                    <Grid container style={{marginBottom: "1%"}}>
                        {RenderTextField({
                            idAname: "invoice_number",
                            label: "账单号",
                            value: this.state.invoice_number,
                            onChangeHandler: (e) => {this.search(false, {'invoice_number': e.target.value});}
                        })}
                    </Grid>
                    <Grid container sx={{paddingBottom: '0.5%'}}>
                        {RenderButtonField({
                            idAname: "ClaimSearch",
                            variant: "contained",
                            text: "查询",
                            buttonType: "submit",
                            tip: "条件查询",
                            onClickHandler: null
                        })}
                        {RenderButtonField({
                            idAname: "exportClaim",
                            variant: "contained",
                            text: "导出索赔信息",
                            buttonType: "submit",
                            tip: "查询条件导出",
                            onClickHandler: (e) => {this.search(false, {'submitType': 'export'})
                        }
                        })}
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <ExportPage type={'CE'}/>
                            </FormControl>
                        </Grid>
                        {RenderButtonField({
                            idAname: "Clear",
                            variant: "outlined",
                            text: "清空",
                            buttonType: "button",
                            tip: "清空查询信息",
                            onClickHandler: () => {this.clearSearch()}
                        })}
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl variant="standard">
                                <Link
                                    // fullWidth
                                    underline='none'
                                    sx={{textTransform: 'none',}}
                                >
                                    <Button onClick={() => {
                                        this.props.closeFunc()
                                    }}>
                                        收起搜索栏
                                        <KeyboardArrowUpIcon/>
                                    </Button>
                                </Link>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }
}

// TODO 后续更新 改动任意字段失去焦点后都会发送请求
class RaterSearchTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logistic: '',
            service_name: '',
            year: '',
            quarter: '',
            PFN: '',
            logistic_options: ["", "FEDEX", "UPS"],
            service_options: ["", "FEDEX_GROUND", "FEDEX_HOME_DELIVERY", "FEDEX_MWT", "UPS_HWT", "FEDEX_SMART_POST",
                "FEDEX_2_DAY_Flat_Rate", "FEDEX_2_DAY_LRN", "UPS_GROUND", "UPS_GROUND_R", "FEDEX_FREIGHT_ECONOMY"],
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        // TODO 验证
        if (this.state.logistic || this.state.service_name || this.state.year || this.state.quarter || this.state.PFN) {
            this.search(true)
        } else {
            // level, title, message, options, closeFunc
            store.dispatch({
                type: OPEN_ALERT, payload: {
                    level: 'error',
                    title: 'FAILED',
                    message: '请输入至少一个查询条件',
                    closeFunc: this.props.closeAlert
                }
            })
        }
    }
    search = (isClick, payload) => {
        if (isClick) {
            store.dispatch({
                type: RATER_SEARCH, payload: payload
            })
        } else {
            this.setState(payload);
            store.dispatch({
                type: RATER_SEARCH_UPDATE, payload: payload
            })
        }
    }
    ChangeField =(e, value, key, value_key) => {
        this.search(false, {[key]: value?value[value_key]:''})
    }
    render() {
        const logisticOptions = this.state.logistic_options.map(option => (
            {option: option}
            // <MenuItem value={option} key={option}>{option}</MenuItem>
        ));

        const serviceOptions = this.state.service_options.map(option => (
            {option}
        ));

        return (
            <div>
                <Box component="form" onSubmit={this.handleSubmit} noValidate>
                    <Grid container>
                        <Grid item lg={3} md={3} xs={3}>
                            <FormControl sx={searchFormControl} variant="standard">
                                {RenderSelectField({
                                    idAname: "logistic",
                                    label: "渠道商",
                                    value: this.state.logistic,
                                    onChangeHandler: (e, newValue) => {this.ChangeField(e, newValue, "logistic", "option")},
                                    select_options: logisticOptions,
                                    value_name: "option",
                                    v: "option",
                                    sx: {width: 323},
                                })}
                                {/*<TextField*/}
                                {/*    id="logistic" select label="渠道商" value={this.state.logistic}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        this.search(false, {'logistic': e.target.value});*/}
                                {/*    }}*/}
                                {/*    variant="standard"*/}
                                {/*>*/}
                                {/*    {logisticOptions}*/}
                                {/*</TextField>*/}
                            </FormControl>
                            <FormControl sx={searchFormControl} variant="standard">
                                {RenderSelectField({
                                    idAname: "service_name",
                                    label: "服务类型",
                                    value: this.state.service_name,
                                    onChangeHandler: (e, newValue) => {this.ChangeField(e, newValue, "service_name", "option")},
                                    select_options: serviceOptions,
                                    value_name: "option",
                                    v: "option",
                                    sx: {width: 323},
                                })}
                                {/*<TextField*/}
                                {/*    id="service_name" select label="服务类型" value={this.state.service_name}*/}
                                {/*    onChange={(e) => {*/}
                                {/*        this.search(false, {'service_name': e.target.value});*/}
                                {/*    }}*/}
                                {/*    variant="standard"*/}
                                {/*>*/}
                                {/*    {serviceOptions}*/}
                                {/*</TextField>*/}
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} xs={3}>
                            <FormControl sx={searchFormControl7} variant="standard">
                                <TextField
                                    type='text' id="year" name="year"
                                    label="年份" variant="standard"
                                    value={this.state.year}
                                    onChange={(e) => {
                                        this.search(false, {'year': e.target.value});
                                    }}
                                    sx={{top: 10}}
                                />
                            </FormControl>
                            <FormControl sx={searchFormControl7} variant="standard">
                                <TextField
                                    type='text' id="quarter" name="quarter"
                                    label="季度" variant="standard"
                                    value={this.state.quarter}
                                    onChange={(e) => {
                                        this.search(false, {'quarter': e.target.value});
                                    }}
                                    sx={{top: 20}}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} xs={3}>
                            <FormControl sx={searchFormControl7} variant="standard">
                                <TextField
                                    type='text' id="PFN" name="PFN"
                                    label="PFN" variant="standard"
                                    value={this.state.PFN}
                                    onChange={(e) => {
                                        this.search(false, {'PFN': e.target.value});
                                    }}
                                    sx={{top: 10}}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Button
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    sx={{textTransform: 'none'}}
                                >
                                    Search
                                </Button>
                            </FormControl>
                        </Grid>
                        {/*<Grid item lg={1.5} md={1.5} xs={1.5}>*/}
                        {/*    <FormControl sx={searchFormControl} variant="standard">*/}
                        {/*        <Tooltip title={"通过查询条件进行导出"}>*/}
                        {/*            <Button*/}
                        {/*                type="submit"*/}
                        {/*                variant="contained"*/}
                        {/*                id="exportRater"*/}
                        {/*                sx={{textTransform: 'none'}}*/}
                        {/*                onClick={(e) => {*/}
                        {/*                    this.search(false, {'submitType': 'export'});*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                Export Rater*/}
                        {/*            </Button>*/}
                        {/*        </Tooltip>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        <Grid item lg={1.5} md={1.5} xs={1.5}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Tooltip title={"清空查询信息"}>
                                    <Button
                                    type="button"
                                    // fullWidth
                                    variant="outlined"
                                    sx={{textTransform: 'none'}}
                                    onClick={() => {
                                        this.search(false, {
                                            logistic: '',
                                            service_name: '',
                                            year: '',
                                            quarter: '',
                                            PFN: '',
                                        });
                                    }}
                                >
                                    Clear
                                </Button>
                                </Tooltip>

                            </FormControl>
                        </Grid>
                        <Grid item lg={2} md={2} xs={2}>
                            <FormControl sx={searchFormControl} variant="standard">
                                <Link
                                    // fullWidth
                                    underline='none'
                                    sx={{textTransform: 'none',}}
                                >
                                    <Button onClick={() => {
                                        this.props.closeFunc()
                                    }}>
                                        收起搜索栏
                                        <KeyboardArrowUpIcon/>
                                    </Button>
                                </Link>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    }
}