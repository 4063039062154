import React, {Component} from 'react';
import server from '../server.js'
import GetCookie from "./GetCookie";
import axios from "axios";
import store from "../redux/store";

export function AxiosDefaultRequest(props) {
    let token = store.getState().auth.token;
    const {url, success, err} = props
    let send_url;
    if (server.test) {
        send_url = `${server.test_host}:${server.post}${url}`;
    } else {
        send_url = `${server.host}${url}`;
    }
    axios.defaults.withCredentials = true;
    let default_headers = {
        "X-CSRFtoken": token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    if (props['headers']) {
        let new_headers = Object.keys(props['headers'])
        for (let i=0;i<new_headers.length;i++){
            default_headers[new_headers[i]] = props['headers'][new_headers[i]]
        }
    }
    axios({
        ...props,
        url: send_url,
        dataType: 'json',
        headers: default_headers,
        // headers: {
        //     "X-CSRFtoken": token,
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json',
        // },
        timeout: 100000000000,
        withCredentials: true
    }).then(success).catch(err)
    // console.log(props)
}


export function AxiosSyncDefaultRequest(props) {
    let token = store.getState().auth.token;
    const {url, success, fail, ...axiosProps} = props;
    let send_url = server.test ? `${server.test_host}:${server.post}${url}` : `${server.host}${url}`;

    axios.defaults.withCredentials = true;
    let default_headers = {
        "X-CSRFToken": token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    };

    if (props.headers) {
        Object.assign(default_headers, props.headers);
    }
    let timeout = 30000;
    if (axiosProps.timeout) {
        timeout = axiosProps.timeout;
    }
    // Create a promise to be returned by the function
    const requestPromise = axios({
        ...axiosProps,
        url: send_url,
        headers: default_headers,
        timeout: timeout // Adjusted to a more reasonable timeout value
    });

    // If callbacks are provided, handle them
    if (success || fail) {
        requestPromise.then(response => {
            if (success) success(response);
        }).catch(error => {
            if (fail) fail(error);
        });
    }

    // Return the promise for 'async/await' use
    return requestPromise;
}


