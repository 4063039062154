import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import logInReducer from './reducers/login_reducer';
import auth from './reducers/auth';
import shipment from "./reducers/shipment";
import BillReducer from "./reducers/BillReducer";
import account from "./reducers/account";
import modal from "./reducers/modal";
import indexReducer from "./reducers/indexReducer";
import alertReducer from "./reducers/alertReducer";
import adminReducer from "./reducers/adminReducer";
import settingsReducer from "./reducers/settingsReducer";
import OfferSheetReducer from "./reducers/OfferSheetReducer";
import ClaimReducer from "./reducers/ClaimReducer";
import RegisterReducer from "./reducers/RegisterReducer";
import RaterReducer from "./reducers/RaterReducer";
import userReducer from "./reducers/userReducer";

const rootReducer = combineReducers({
  logInReducer, auth, shipment, BillReducer, account, modal, indexReducer, alertReducer, adminReducer,
  settingsReducer, OfferSheetReducer, ClaimReducer, RegisterReducer, RaterReducer, userReducer
})

const middleWare = [thunk];
const initialState = {};
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleWare))
);
export default store

