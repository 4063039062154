import {
    CHANGE_PAGE,
    SHIPMENT,
    BILL,
    ACCOUNT,
    LOGOUT,
    ADMIN,
    SETTINGS,
    CLAIM,
    RATER,
    SHIPMENT_SEARCH_CLEAR,
    BILL_SEARCH_CLEAR,
    SETTINGS_SEARCH_CLEAR,
    ACCOUNT_SEARCH_CLEAR,
    ADMIN_SEARCH_CLEAR,
    RATER_SEARCH_CLEAR,
    CLAIM_SEARCH_CLEAR,
    USER,
    USER_SEARCH_CLEAR,
    SET_CURRENT

} from "../type";
import * as React from "react";
import LogisticSupplier from "../../pages/logisticSupplierManagement/logisticSupplierPage";
import LogisticService from "../../pages/logisticSupplierManagement/logisticService";
import ServiceAndUser from "../../pages/logisticSupplierManagement/serviceAndUser";
import ServiceAndSpecial from "../../pages/logisticSupplierManagement/serviceAndSpecial";
import UserPackageSpecialServices from "../../pages/logisticSupplierManagement/userPackageSpecialServices";
import WarehouseAndAccount from "../../pages/logisticSupplierManagement/warehouseAndAccount";
import Warehouse from "../../pages/logisticSupplierManagement/warehouse";

import InternalClaim from "../../pages/claimManagement/InternalClaimPage";
import CustomerClaims from "../../pages/claimManagement/CustomerClaimPage";
import ClaimManagement from "../../pages/claimManagement/ClaimManagementPage";
import FBAAddress from "../../pages/FBAAddress/FBAAddress";
import FreightShipment from "../../pages/FreightShipment/FreightShipment";
import Zone from "../../pages/logisticSupplierManagement/zone/zone";
import Pickup from "../../pages/pickup/Pickup";
import FuelSurcharge from "../../pages/FuelSurcharge/FuelSurchargePage"

const pagesWithoutSearchTable = [ADMIN, RATER, CLAIM];

export const changePage = (toPage, history) => (dispatch, getState) => {
    const current = getState().indexReducer.current;
    if (current === toPage) return;

    const clearSearchTypeMap = {
        [USER]: USER_SEARCH_CLEAR,
        [SHIPMENT]: SHIPMENT_SEARCH_CLEAR,
        [BILL]: BILL_SEARCH_CLEAR,
        [SETTINGS]: SETTINGS_SEARCH_CLEAR,
        [ACCOUNT]: ACCOUNT_SEARCH_CLEAR,
        [ADMIN]: ADMIN_SEARCH_CLEAR,
        [RATER]: RATER_SEARCH_CLEAR,
        [CLAIM]: CLAIM_SEARCH_CLEAR,

    };

    const clearSearchType = clearSearchTypeMap[toPage];
    if (clearSearchType) {
        dispatch({ type: clearSearchType });
    }

    dispatch({
        type: CHANGE_PAGE,
        payload: { pageName: toPage, searchTable: pagesWithoutSearchTable.includes(toPage) ? null : toPage }
    });

    const pagePathMap = {
        [USER]: '/index/user',
        [SHIPMENT]: '/index/shipment',
        [BILL]: '/index/bill',
        [SETTINGS]: '/index/settings',
        [ACCOUNT]: '/index/account',
        [ADMIN]: '/index/admin',
        [RATER]: '/index/rater',
        [CLAIM]: '/index/claim',
        [LogisticSupplier]: '/index/logistic_supplier',
        [Warehouse]: '/index/logistic_supplier/warehouse',
        [LogisticService]: '/index/logistic_supplier/logistics_service',
        [ServiceAndUser]: '/index/logistic_supplier/service_and_user',
        [ServiceAndSpecial]: '/index/logistic_supplier/service_and_special',
        [UserPackageSpecialServices]: '/index/logistic_supplier/user_package_special_services',
        // [ServiceAndWarehouse]: '/index/logistic_supplier/user_package_special_services',
        [WarehouseAndAccount]: '/index/logistic_supplier/warehouse_and_account',

        [ClaimManagement]: '/index/claims',
        [InternalClaim]: '/index/claims/internal',
        [CustomerClaims]: '/index/claims/customer',

        [FBAAddress]: '/index/FBAAddress',
        [FreightShipment]: '/index/freight_shipment',
        [Zone]: "/index/zone",
        [Pickup]: "/index/pickup",
        [FuelSurcharge]: "/index/fuel_surcharge",

    };
    dispatch({
        type: SET_CURRENT,
        payload: {current: toPage, searchTable: toPage, currentUrl: pagePathMap[toPage]}
    })
    const pagePath = pagePathMap[toPage];
    if (pagePath) {
        history.replace(pagePath);
    }
};