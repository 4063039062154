import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import Confirm from "./Confirm"
import server from "../../server";
import FormHelperText from "@mui/material/FormHelperText";
import DatasetIcon from '@mui/icons-material/Dataset';
import Grid from "@mui/material/Grid";
import {Snackbar} from "@mui/material";
import Alert from "@mui/material/Alert";

const re = {regex: /^(\+|-)?\d+(\.\d{0,2})?$/};

function UpdateCancelCredit({open, setOpen, Refresh}) {
    const [display, setDisplay] = useState(false);
    const [credit, setCredit] = useState(0);
    const [account, setAccount] = useState([]);
    const [uid, setUid] = useState(0);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const [confirm_open, SetConfirmOpen] = useState(false)
    // 模仿 componentDidMount
    useEffect(() => {
        const fetchData = async () => {
            let Account = {0: "所有用户"}
            const res = await AxiosSyncDefaultRequest({
                url: server.user_list_url
            });
            let UserList = res.data.UserList
            Object.entries(UserList).forEach(([key, value]) => {
                if(!value.includes("admin")){
                    Account[key] = value
                }

            });
            setAccount(Account);
            setDisplay(true);
            setUid(Object.keys(Account)[0]);
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        setUid(e.target.value);
    };

    return (
        <span>
            <Button sx={{textTransform: 'none'}} startIcon={<DatasetIcon/>} onClick={setOpen}>重置取消金额</Button>
            <Dialog disableEscapeKeyDown open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Update Cancel Credit</DialogTitle>
            <Box component="form" sx={{width: '100%'}}>
                <DialogContent>
                    <Grid container spacing={2}>
                        {display &&
                            <Grid item xs={12} sx={{paddingX: 3}}>  {/* 添加左右内边距 */}
                                <FormControl fullWidth sx={{
                                    '.MuiInputBase-root': {height: 38, fontSize: '0.875rem'},
                                    '.MuiInputLabel-root': {fontSize: '0.875rem'},
                                    '.MuiSvgIcon-root': {fontSize: '1rem'}
                                }}>
                                    <InputLabel htmlFor="Account">Account</InputLabel>
                                    <Select
                                        native
                                        value={uid}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Account" id="Account"
                                                              sx={{height: '100%', padding: '6px 12px'}}/>}
                                    >
                                        {Object.entries(account).map(([key, value]) => (
                                            <option key={key} value={key}
                                                    style={{fontSize: '0.875rem'}}>{value}</option>
                                        ))}
                                    </Select>
                                    <FormHelperText>Please select a user</FormHelperText>
                                </FormControl>
                            </Grid>}
                        <Grid item xs={12} sx={{paddingX: 3}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="Credit"
                                    name="Credit"
                                    inputMode="decimal"
                                    type="number"
                                    value={credit}
                                    label="Cancel Credit"
                                    size="small"
                                    helperText="Please set the recharge amount, Maximum two decimal places"
                                    onChange={(e) => {
                                        if (re.regex.test(e.target.value) || e.target.value === '') {
                                            setCredit(e.target.value);
                                        }
                                    }}
                                    required
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'flex-end', marginBottom: 1, marginRight: 1}}>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button variant="contained" onClick={() => SetConfirmOpen(true)}>Submit</Button>
                </DialogActions>
                <Confirm open={confirm_open} SetOpen={SetConfirmOpen} user={uid} account={account} cancel_credit={credit} setSnackbar={setSnackbar} setOpen={setOpen}></Confirm>
            </Box>
        </Dialog>
        <Snackbar
            open={snackbar.open}
            autoHideDuration={2000}
            onClose={() => setSnackbar({...snackbar, open: false})}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                   sx={{width: '100%'}}>
                {snackbar.message}
            </Alert>
        </Snackbar>
        </span>

    );
}

export default UpdateCancelCredit;