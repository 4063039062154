import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar} from '@mui/material';

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import StorageIcon from '@mui/icons-material/Storage';
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import {RenderSelectField} from "../../../../components/renderField";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import {useDispatch} from "react-redux";
import {node} from "prop-types";

const CreateService= ({onSave}) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user_options, setUserOptions] = useState([])
    const [formData, setFormData] = useState({
        id: '',
        name: "",
        service_number: '',
        is_much: false,
        supplier: '',
        service_type: '',
        international: false,  //是否国际服务
        is_enable: true, // 是否启用  # 未实装

        description: '', // 前端或其他地方获取展示使用
        is_debug: false, //# 是否测试用
        is_show: true,
        code: "", //服务设定码


    });
    const [error, SetError] = useState({
        name: false,
        supplier: false,
        service_number: false,
        service_type: false,
        is_show: true
    });
    const onClose = () => setOpenCreateDialog(false);
    const onOpen = () => setOpenCreateDialog(true);
    const [supplier_options, setSupplierOptions] = useState([])
    const [service_type_options, setServiceTypeOptions] = useState([])
    const [code, setCode] = useState({})
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchSupplierData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_supplier_list}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let code_options = {}
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                            code_options[rows[i].id] = rows[i].code
                        }
                        setSupplierOptions(options)
                        setCode(code_options)
                        setLoading(false)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                setLoading(false)
                console.error('请求异常', error);
            }
        };
        const fetchServiceTypeData = async () => {
            setLoading(true)
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.service_type}`,
                    method: 'GET',
                    success: (res) => {
                        console.log(res.data.results)
                        let options = []
                        let rows = res.data.results
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setServiceTypeOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        fetchSupplierData();
        fetchServiceTypeData();
    }, [dispatch]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const handleChange = (event) => {
        const {name, value} = event.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const handleFieldChange = (e, value, key, value_name) => {
        setFormData({...formData, [key]: value?value[value_name]:''})
    }

    const handleSave = async () => {
        let data = formData
        Object.entries(formData).forEach(([key, value]) => {
            if (value === false || value === '') {
                delete data[key]
            }
        });

        SetError({
            name: false,
            service_number: false,
            supplier: false,
            service_type: false
        })
        if (formData.name && formData.service_number && formData.supplier && formData.service_type) {
            setLoading(true);

            try {

                const result = await onSave(formData, formData.id, 'POST');
                if (result.status === true) {
                    // 操作成功，显示成功消息
                    setFormData({
                        id: '',
                        name: "",
                        service_number: '',
                        is_much: false,
                        supplier: '',
                        service_type: '',
                        international: false,  //是否国际服务
                        is_enable: true, // 是否启用  # 未实装

                        description: '', // 前端或其他地方获取展示使用
                        is_debug: false, //# 是否测试用
                        is_show: true
                    })
                    // setSnackbar({ open: true, message: '保存成功！', severity: 'success' })
                }
            } catch (error) {
                console.error("保存失败", error);
                // 操作失败，显示错误消息
                // setSnackbar({ open: true, message: '保存失败,请重试.', severity: 'error' });
            } finally {
                setLoading(false);
                onClose(); // 保存操作完成后关闭对话框
            }
        } else {
            for(let key in error){
                if(!formData[key]){
                    SetError((prev) => ({
                        ...prev,
                        [key]: true,
                    }));
                }
            }
        }

    };
    return (
        <>

            <Button sx={{textTransform: 'none'}} startIcon={<StorageIcon/>} onClick={() => {
                onOpen()
            }}>
                新增渠道服务
            </Button>
            <Dialog open={openCreateDialog} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>新增渠道服务</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="name"
                                label="服务名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                                error={error.name}
                                required
                                error={error.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="code"
                                label="服务设定码"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.code}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="service_number"
                                label="服务号"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.service_number}
                                onChange={handleChange}
                                error={error.service_number}
                                required
                                error={error.service_number}
                            />

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                idAname="supplier"
                                label="物流商"
                                value={formData.supplier}
                                onChangeHandler={(e, value) => handleFieldChange(e, value, "supplier", "id")}
                                select_options={supplier_options}
                                v={"id"}
                                value_name={"name"}
                                sx={{width: 350}}
                                required
                                error={error.supplier}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                idAname="service_type"
                                label="服务类型"
                                value={formData.service_type}
                                onChangeHandler={(e, value) => handleFieldChange(e, value, "service_type", "id")}
                                select_options={service_type_options}
                                v={"id"}
                                value_name={"name"}
                                sx={{width: 350}}
                                required
                                error={error.service_type}
                            />

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="description"
                                label="描述"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="is_much"
                                label="是否一票多件"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.is_much}
                                onChange={handleChange}
                            >
                                <MenuItem key="is_much_true" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="is_specific_true" value={false}>
                                    否
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="international"
                                label="是否国际服务"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.international}
                                onChange={handleChange}
                            >
                                <MenuItem key="international_true" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="international_false" value={false}>
                                    否
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="is_debug"
                                label="是否测试服务"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.is_debug}
                                onChange={handleChange}
                            >
                                <MenuItem key="is_debug_true" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="is_debug_false" value={false}>
                                    否
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="is_enable"
                                label="是否启用"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.is_enable}
                                onChange={handleChange}
                            >
                                <MenuItem key="is_enable_true" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="is_enable_false" value={false}>
                                    否
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="is_show"
                                label="是否显示"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.is_show}
                                onChange={handleChange}
                            >
                                <MenuItem key="is_show_true" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="is_show_false" value={false}>
                                    否
                                </MenuItem>
                            </TextField>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant={"contained"}>取消</Button>
                    <LoadingButton loading={loading} onClick={handleSave}>保存</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({...snackbar, open: false})}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                       sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>

    );
};

export default CreateService;
