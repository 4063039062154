import React, { useEffect, useRef, useState } from 'react';
import ClipboardJS from 'clipboard';
import CopyAllIcon from "@mui/icons-material/CopyAll";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

function ClipboardHandler({ track }) {
    const [mrg, setMrg] = useState("复制到粘贴板");
    const buttonRef = useRef(null);
    const clipboard = useRef(null);
    useEffect(() => {
        clipboard.current = new ClipboardJS(buttonRef.current, {
            text: function() {
                return track.value;
            }
        });
        clipboard.current.on('success', function(e) {
            setMrg("√ 复制成功");
            e.clearSelection();
        });
        clipboard.current.on('error', function(e) {
            setMrg("复制失败");
        });
        // Remember to clean up after the component unmounts
        return () => {
            clipboard.current.destroy();
        }
    }, [track]);  // 依赖项数组为空

    return (
        <Tooltip title={mrg}>
            <Button ref={buttonRef} aria-describedby={track.id} startIcon={<CopyAllIcon/>} className="btn" style={{ color: 'rgba(0, 0, 0, 0.30)' }}
                    onMouseOver={
                        (e) => {setMrg("复制到粘贴板")}
                    }
                    onClick={
                        (e) => {
                            clipboard.current && clipboard.current.onClick(e);
                        }
                    }
            />
        </Tooltip>
    );
}

export default ClipboardHandler;
