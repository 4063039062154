import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import store from "../../redux/store";
import {MODAL_CLOSE_ALERT} from "../../redux/type";
import InputAdornment from "@mui/material/InputAdornment";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import server from "../../server";
import {ClickAwayListener, MenuList, Popper} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {uploadShippingFee} from "../../redux/actions/RaterAction";

const menuItemStyle = {
    padding: '8px 16px',
    fontSize: '0.9rem',
    borderBottom: '1px solid #eee'
};

class UploadShippingFeeTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            template_open: false,
            upload_shipping_fee_file: null,
            popperPosition: {}
        }
        this.anchorRef = React.createRef();
    }

    Close = () => {
        this.setState({open: false})
    }

    handleToggle = () => {
        if(this.anchorRef.current) {
            const rect = this.anchorRef.current.getBoundingClientRect();
            const top = rect.bottom + window.scrollY;
            const left = rect.left + window.scrollX;

            this.setState({
                template_open: true,
                popperPosition: { top, left }
            });
        }
    };

    handleClose = (event) => {
        console.log('12345')
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }
        this.setState({ template_open: false });
    };

    downloadTemplate = (filename) => {
        let a = document.createElement('a');
        a.target = "_blank";
        if (server.test) {
            a.href = server.test_host + ":" + server.post + '/DownloadTemplate?file=' + filename + '.xlsx'
        } else {
            a.href = server.host + '?file=' + filename + '.xlsx'
        }
        a.click();
    }
    Open = () => {
        this.setState({open: true})
    }
    handleSubmit = e => {
        e.preventDefault()
        const data = new FormData(e.currentTarget);
        this.props.uploadShippingFee(this.state.upload_shipping_fee_file, data.get('upload_shipping_fee_file').name, this.props.search.id)
        this.setState({open: false})
    }
    closeAlert = () => {
        store.dispatch({type: MODAL_CLOSE_ALERT})
    }
    ChangeFile = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            this.setState({upload_shipping_fee_file: event.target.result})
        };
    }

    render() {
        console.log(this.props.search)
        return (
            <span>
                <Button sx={{textTransform: 'none'}} startIcon={<FileUploadOutlinedIcon/>} onClick={() => {
                    this.Open()
                }}>上传报价</Button>
                <Popper
                    style={{
                        zIndex: 9999,
                        backgroundColor: '#fff',
                        position: 'absolute',
                        top: `${this.state.popperPosition.top}px`,
                        left: `${this.state.popperPosition.left}px`,
                        boxShadow: '0 3px 10px rgba(0,0,0,0.2)',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        // width: '200px'
                    }}
                    open={this.state.template_open}
                    anchorEl={this.anchorRef.current} placement="bottom-start" transition>
                    {({ TransitionProps, placement }) => (
                        <ClickAwayListener onClickAway={this.handleClose}>
                            <MenuList>
                                <MenuItem style={menuItemStyle} onClick={(e) => {this.downloadTemplate('Ground与Home')}}>Ground和Home</MenuItem>
                                <MenuItem style={menuItemStyle} onClick={(e) => {this.downloadTemplate('MWT')}}>MWT</MenuItem>
                                <MenuItem style={menuItemStyle} onClick={(e) => {this.downloadTemplate('SMART_POST')}}>SMART POST</MenuItem>
                                <MenuItem style={menuItemStyle} onClick={(e) => {this.downloadTemplate('FEDEX_FREIGHT_ECONOMY')}}>FEDEX_FREIGHT_ECONOMY</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    )}
                </Popper>
                    <Dialog disableEscapeKeyDown open={this.state.open} onClose={() => {
                        this.Close()
                    }}>
                        <DialogTitle>上传报价</DialogTitle>
                        <DialogContent>
                            <Box component="form" onSubmit={this.handleSubmit} sx={{display: 'flex', flexWrap: 'wrap'}}>
                                <FormControl sx={{m: 1, minWidth: 500, minHeight: 100}}>
                                    <TextField
                                        id="upload_shipping_fee_file"
                                        name="upload_shipping_fee_file"
                                        type='file'
                                        label="请上传一个报价表(xlsx格式)"
                                        onChange={(e) => {
                                            this.ChangeFile(e)
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FileUploadIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                    />
                                </FormControl>

                                <DialogActions>
                                    <Button
                                        sx={{mt: 3, mb: 2, ml: 35, whiteSpace: 'nowrap', minWidth: 'auto'}}
                                        ref={this.anchorRef}
                                        onMouseOver={this.handleToggle}
                                        variant={"contained"}
                                        >
                                        下载模板文件
                                    </Button>

                                    <Button fullWidth variant="contained" sx={{mt: 3, mb: 2}} onClick={() => {
                                        this.Close()
                                    }}>取消</Button>
                                    <Button fullWidth type='submit' variant="contained" sx={{mt: 3, mb: 2}}>上传</Button>
                                </DialogActions>
                            </Box>
                        </DialogContent>
                    </Dialog>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    search: state.RaterReducer.shipping_fee_search,
    user: state.auth.user
});

export default connect(mapStateToProps, {uploadShippingFee})(UploadShippingFeeTemplate);