import * as React from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import {getLabel} from "../../redux/actions/modal";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import store from "../../redux/store";
import {MODAL_CLOSE_ALERT} from "../../redux/type";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

class GetLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            get_label_file: null
        }
    }
    Close = () => {
        this.setState({open: false})
    }
    Open = () => {
        this.setState({open: true})
    }
    closeAlert = () => {
        store.dispatch({type: MODAL_CLOSE_ALERT})
    }

    handleSubmit = e => {
        e.preventDefault()
        const data = new FormData(e.currentTarget);
        this.props.getLabel(this.state.get_label_file, data.get('get_label_file').name)
        this.setState({open: false})
    }
    ChangeFile = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            this.setState({get_label_file: event.target.result})
        };
    }
    render() {

        return (
            <span>
                <Button sx={{textTransform: 'none'}} startIcon={<ArrowDownwardIcon/>} onClick={() => {
                    this.Open()
                }}>获取标签</Button>
                <Dialog disableEscapeKeyDown open={this.state.open} onClose={() => {
                    this.Close()
                }}>
                    <DialogTitle>Get Label</DialogTitle>
                    <DialogContent>
                        <Box component="form" onSubmit={this.handleSubmit} sx={{display: 'flex', flexWrap: 'wrap'}}>
                            <FormControl sx={{m: 1, minWidth: '100%', minHeight: 500}}>
                                <TextField
                                    id="get_label_file"
                                    name="get_label_file"
                                    type='file'
                                    label="Please upload the main tracking number of the waybill(Note: The uploaded file is a txt file)"
                                    onChange={(e) => {
                                        this.ChangeFile(e)
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <FileUploadIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                />
                            </FormControl>
                            <DialogActions>
                                <Button fullWidth variant="contained" sx={{mt: 3, mb: 2, ml: 45}} onClick={() => {
                                    this.Close()
                                }}>Cancel</Button>
                                <Button fullWidth type='submit' variant="contained" sx={{mt: 3, mb: 2}}>Submit</Button>
                            </DialogActions>
                            {/*<GetReceipt open={this.state.open}/>*/}
                        </Box>
                        </DialogContent>
                    </Dialog>
                {/*</Paper>*/}
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    request: state.adminReducer.request
});

export default connect(mapStateToProps, {getLabel})(GetLabel);