import React from 'react';
import server from '../server.js'


function DownloadFile(params) {
    // var my_params = params;
    // console.log(server.test_host + ":" + server.post + params.location.pathname)
    // console.log('')
    let url;
    if (server.test){
        url = server.test_host + ":" + server.post + params.location.pathname;
    }else{
        url = server.host + params.location.pathname;
    }
    let alink = document.createElement('a');
    alink.href = url
    let download_name;
    if (params.match.params.track !== 'NONE') {
        download_name = params.match.params.track + '.pdf'
    } else {
        download_name = params.match.params.download_url_datetime + '.pdf'
    }
    alink.download = download_name;
    alink.click();




    // fetch(server.test_host + ":" + server.post + params.location.pathname).then(response => {
    //     response.blob().then(blob => {
    //         // Creating new object of PDF file
    //         const fileURL = window.URL.createObjectURL(blob);
    //         // Setting various property values
    //         let alink = document.createElement('a');
    //         alink.href = fileURL;
    //         let download_name;
    //         if (my_params.match.params.track !== 'NONE') {
    //             download_name = my_params.match.params.track + '.pdf'
    //         } else {
    //             download_name = my_params.match.params.download_url_datetime + '.pdf'
    //         }
    //         alink.download = download_name;
    //         alink.click();
    //         window.location.href = '/index/shipment/'
    //     })
    // })
    // return (
    //
    //     <div>
    //         {/*<Document file="somefile.pdf" onLoadSuccess={onDocumentLoadSuccess}>*/}
    //         {/*    <Page pageNumber={pageNumber} />*/}
    //         {/*</Document>*/}
    //         {/*  <p>*/}
    //         {/*    Page {pageNumber} of {numPages}*/}
    //         {/*  </p>*/}
    //         {/*<Link to={'http://127.0.0.1:8002' + params.location.pathname}/>*/}
    //         {/*<a href={'http://127.0.0.1:8002' + params.location.pathname} id='download_click' />*/}
    //     </div>
    // )
}
export default DownloadFile;