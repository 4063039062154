import React, {useState} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

const CreateWarehouse = ({onSave, Refresh}) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user_options, setUserOptions] = useState([])
    const [formData, setFormData] = useState({
        fuel_surcharge_name: '', fuel_surcharge_value: ''
    });
    const [formdataStatus, setFormDataStatus ] = useState({
        fuel_surcharge_name: {required: true, message: "", status: false},
        fuel_surcharge_value: {required: true, message: "", status: false},
        
    })
    const onClose = () => setOpenCreateDialog(false);
    const onOpen = () => setOpenCreateDialog(true);

    const handleChange = (event) => {
        const {name, value} = event.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

    };

    const handleSave = async () => {
        let data = formData
        Object.entries(formData).forEach(([key, value]) => {
            if (value === false || value === '') {
                delete data[key]
            }
        });

        setFormDataStatus({
            fuel_surcharge_name: {required: true, message: "", status: false},
            fuel_surcharge_value: {required: true, message: "", status: false},
        })
        if (formData.fuel_surcharge_name && formData.fuel_surcharge_value) {

            setLoading(true);
            try {

                const result = await onSave(formData, formData.id, 'POST');
                if (result.status === true) {
                    setFormData({
                        fuel_surcharge_name: '',
                        fuel_surcharge_value: ''
                    })
                    // 操作成功，显示成功消息
                    // setSnackbar({open: true, message: '保存成功！', severity: 'success'});
                }
            } catch (error) {
                console.error("保存失败", error);
                // 操作失败，显示错误消息
                // setSnackbar({ open: true, message: '保存失败,请重试.', severity: 'error' });
            } finally {
                setLoading(false);
                onClose(); // 保存操作完成后关闭对话框
            }
        } else {
            const newErrors = {};
            for (let field in formdataStatus) {
                if (formdataStatus[field].required && !formData[field]) {
                    newErrors[field] = {
                        ...formdataStatus[field],
                        message: 'This field is required',
                        status: true
                    };
                } else {
                    newErrors[field] = {
                        ...formdataStatus[field],
                        message: '',
                        status: false
                    };
                }
            }
            setFormDataStatus({
                ...formdataStatus,
                ...newErrors
            });
        }

    };
    return (
        <>

            <Button sx={{textTransform: 'none'}} startIcon={<LocalGasStationIcon/>} onClick={() => {
                onOpen()
            }}>
                新增燃油配置
            </Button>
            <Dialog open={openCreateDialog} onClose={onClose} maxWidth="md" fullWidth>
                <DialogTitle>新增燃油配置</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="fuel_surcharge_name"
                                label="渠道名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.fuel_surcharge_name}
                                onChange={handleChange}
                                required
                                error={formdataStatus.fuel_surcharge_name.status}
                                helperText={formdataStatus.fuel_surcharge_name.message}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="fuel_surcharge_value"
                                label="燃油附加费"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.fuel_surcharge_value}
                                onChange={handleChange}
                                required
                                error={formdataStatus.fuel_surcharge_value.status}
                                helperText={formdataStatus.fuel_surcharge_value.message}
                            >
                            </TextField>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant={"contained"}>取消</Button>
                    <LoadingButton loading={loading} onClick={handleSave}>保存</LoadingButton>
                </DialogActions>
            </Dialog>
        </>

    );
};

export default CreateWarehouse;
