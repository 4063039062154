import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {RenderTextField} from "../../../components/renderField";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";

export default function DimensionForm({cards, setCards, addCard, deleteCard,dimension_status, subPackageType, }) {
    const updateCard = (index, field, value) => {
        const newCards = [...cards];
        newCards[index][field] = value;
        setCards(newCards);
    };
    return (
        <div>
            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                {cards.map((card, index) => (
                <Card key={index} style={{ display: "inline-block", margin: "5px" }}>
                    <CardContent style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: 0, paddingRight: 0 }}>
                        <Typography sx={{
                            fontSize: 14,
                            // marginTop: '-15px',
                            marginLeft: '-10px'
                        }} color="text.secondary" gutterBottom>
                            Package {index + 1}.
                        </Typography>
                        <IconButton
                            aria-label="delete"
                            onClick={() => deleteCard(index)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </CardContent>
                    <CardContent sx={{paddingTop: 0, paddingRight: 0}}>
                        <RenderTextField
                            idAname="length"
                            label="length"
                            value={card.length}
                            type="number"
                            onChangeHandler={e => updateCard(index, "length", e.target.value)}
                            width={12}
                            required
                            error={dimension_status[index].length.status}
                            helperText={dimension_status[index].length.message}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">inch</InputAdornment>,
                            }}
                        />
                        <RenderTextField
                            idAname="width"
                            label="width"
                            value={card.width}
                            type="number"
                            onChangeHandler={e => updateCard(index, "width", e.target.value)}
                            width={12}
                            required={dimension_status[index].width.required}
                            error={dimension_status[index].width.status}
                            helperText={dimension_status[index].width.message}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">inch</InputAdornment>,
                            }}
                        />
                        <RenderTextField
                            idAname="height"
                            label="height"
                            value={card.height}
                            type="number"
                            onChangeHandler={e => updateCard(index, "height", e.target.value)}
                            width={12}
                            required={dimension_status[index].height.required}
                            error={dimension_status[index].height.status}
                            helperText={dimension_status[index].height.message}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">inch</InputAdornment>,
                            }}
                        />
                        <RenderTextField
                            idAname="weight"
                            label="weight"
                            value={card.weight}
                            type="number"
                            onChangeHandler={e => updateCard(index, "weight", e.target.value)}
                            width={12}
                            required={dimension_status[index].weight}
                            error={dimension_status[index].weight.status}
                            helperText={dimension_status[index].weight.message}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">lb</InputAdornment>,
                            }}
                        />
                        <RenderTextField
                            idAname="subPackageType"
                            label="subPackageType"
                            value={card.subPackageType}
                            onChangeHandler={e => updateCard(index, "subPackageType", e.target.value)}
                            width={12}
                            required={dimension_status[index].subPackageType.required}
                            error={dimension_status[index].subPackageType.status}
                            helperText={dimension_status[index].subPackageType.message}
                            select
                        >
                            {subPackageType[index].map((option) => (
                                <MenuItem key={option.id} value={option.type}>
                                    {option.type}
                                </MenuItem>
                            ))}
                        </RenderTextField>
                        <RenderTextField
                            idAname="goodsName"
                            label="goodsName"
                            value={card.goodsName}
                            onChangeHandler={e => updateCard(index, "goodsName", e.target.value)}
                            width={12}
                            required={dimension_status[index].goodsName.required}
                            error={dimension_status[index].goodsName.status}
                            helperText={dimension_status[index].goodsName.message}
                        />
                    </CardContent>
                </Card>
            ))}
            </div>
            <Button variant="contained" onClick={addCard}>
                Add Package
            </Button>
        </div>
    );
}
