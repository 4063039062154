import React, {useState, useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import StyledBox from "../../components/StyledBox";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";

import {DialogContentText, Snackbar, Switch} from "@mui/material";
import {useDispatch} from "react-redux";
import {AxiosSyncDefaultRequest} from "../../components/default_request";
import server from "../../server";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import ToolBar from "../../components/ToolBar"
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import {buildUrlWithQueryParams} from "../../components/buildUrlWithQueryParams";
import Alert from "@mui/material/Alert";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import CreateFormDialog from "./Dialog/createDialog";

const FuelSurcharge = "FuelSurcharge";


function FuelSurchargePage() {
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchData, SetSearchData] = useState({fuel_surcharge_name: '', fuel_surcharge_value: ''});
    const [totalRows, setTotalRows] = useState(0);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const dispatch = useDispatch();
    const [Visibility, setVisibility] = useState({
        id: false
    });
    const [checked, setChecked] = React.useState({

    });
    const handleChange = (event, id, key) => {
        handleSave({[key]: event.target.checked}, id, "PUT", false).then(
            setChecked((prev) => ({
                ...prev,

                [id]: event.target.checked

            }))
        ).catch((error) => {
            setSnackbar({ open: true, message: error.response.message, severity: 'error' });
        })

    };

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const columns = [
        {id: 1, field: "id", headerName: "ID", flex: 5},
        {
            id: 2, field: "fuel_surcharge_name", headerName: "渠道名", flex: 3
        },
        {
            id: 3, field: "fuel_surcharge_value", headerName: "燃油附加费", flex: 5

        },
        {
            id: 4, field: "create_time", headerName: "创建时间", flex: 3
        },
        {
            id: 3, field: "anonymous_time", headerName: "修改时间", flex: 5

        },

        {id: 6, type: 'actions', field: 'actions', headerName: "Actions", getActions: (params) => {
                return [

                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        title={"Delete"}
                        onClick={() => handleOpenDeleteDialog(params.row)}
                        key={`delete-${params.id}`}
                        color="secondary"
                    />
                ];
            }},

    ];
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const handleOpenEditDialog = (row) => {
        setSelectedRow(row);
        setOpenEditDialog(true);
    };

    const ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
    }

    const handleOpenDeleteDialog = (row) => {
        setSelectedRow(row);
        setOpenDeleteDialog(true);
    };
    const handleSearch = (e) => {
        setLoading(true)
        fetchData("GET", {}, null)
    }
    const Refresh = (open=true) => {
        // setData([]);
        setData([]);
        setLoading(true);
        fetchData('GET', {}, null, {}, open).then(r => {})

    }
    const handleSave = (editedData, rowId, method='POST', refresh=true) => {
        // 直接返回 fetchData 函数调用的 Promise
        let result = {status: false, message: ''};
        return fetchData(method, editedData, rowId
        ).then((e) => {
            if(refresh){
                Refresh(false)
            }

            result.status = e !== undefined;
            return result;
        }).catch((error) => {
            result.status = false;
            result.message = error.response.message;
            return result;
        }).finally(() => {
            return result;
        });
    }
    const fetchData = async (method, data = {}, rowId = null, sort_data, open=true) => {
        try {
            const queryParams = {
                ...searchData, // 将搜索条件作为查询参数
                ...sort_data,
                curr_page: currentPage < 1 ? 1 : currentPage,
                page_size: pageSize,
            };

            let requestUrl = server.FuelSurcharge;
            let requestData = {};
            let msg = "获取"

            if (method === 'GET') {

                requestUrl = buildUrlWithQueryParams(requestUrl, queryParams);

            } else if (method === 'PUT' && rowId != null) {
                msg = "修改"
                // 对于PUT请求，添加row id到URL，并设置编辑的数据作为请求体
                requestUrl = `${requestUrl}${rowId}/`;
                requestData = data;
            }else if (method === "DELETE") {
                msg = "删除"
                requestUrl = `${requestUrl}${rowId}/`;
            }else if (method === "POST") {
                msg = "创建"
                requestData = data;
            }

            return await AxiosSyncDefaultRequest({
                url: requestUrl,
                method: method,
                data: requestData,
                success: (res) => {
                    setTotalRows(1)


                    if (method === 'GET') {
                        setData(res.data.rows);
                        setLoading(false);
                        setTotalRows(res.data.total);
                    }
                    if(open){
                        setSnackbar({ open: true, message: `${msg}成功！`, severity: 'success' });
                    }


                },
                fail: (error) => {
                    console.error('请求失败', error);
                    setData([]);
                    setLoading(false);
                    const errorMessage = JSON.stringify(error.response.data);
                    setSnackbar({ open: true, message: errorMessage, severity: 'error' });
                    // throw error
                }
            });
        } catch (error) {
            setSnackbar()
            console.error('请求异常', error);
        }
    };


    useEffect(() => {
        setLoading(true)

        // 调用上面定义的函数来加载数据
        fetchData("GET", {}, null, {}, false).then();
    }, [currentPage, pageSize, dispatch]);

    const handleSortModelChange = async (sortModel) => {
        let ordering = '';
        if (sortModel.length > 0) {
            // 处理所有的排序模型，虽然通常只有一个
            ordering = sortModel.map(model => {
                return model.sort === 'asc' ? model.field : `-${model.field}`;
            }).join(',');
        }
        // 构造排序的数据结构
        const sortData = { ordering };
        // 调用 fetchData 函数请求数据
        setLoading(true);
        setData([]);
        await fetchData('GET', {}, null, sortData);
    };
    const onChangeColumnVisibilityModel = (newVisibility) => {
        // if (user.is_open_claim !== "4") {
        //     newVisibility = { ...newVisibility, Check_amount: false };
        // }
        setVisibility(newVisibility);
    }
    const handleDelete = (rowId) => {
        // 直接返回 fetchData 函数调用的 Promise
        let result = {status: false, message: ''};
        return fetchData('DELETE', {}, rowId
        ).then((e) => {
            Refresh();
            result.status = true;
            return result; // 在 then 中返回 result
        }).catch((error) => {
            result.status = false;
            result.message = error.response.message;
        }).finally(() => {
            return result;
        });
    }
    return (
        <Box sx={{
            width: '100%'
        }}>
            <CreateFormDialog onSave={handleSave}></CreateFormDialog>
            {/*<CreateWarehouse onSave={handleSave} Refresh={Refresh}></CreateWarehouse>*/}
            <Grid container>
                <Grid item md={12} lg={12}>
                    <StyledBox>
                        {data ? <DataGridPro
                            rows={data}
                            density="compact"
                            columns={columns}
                            pageSize={pageSize}
                            columnVisibilityModel={Visibility}
                            rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                            checkboxSelection={false}
                            headerHeight={70}
                            loading={loading}
                            rowCount={data.length}
                            paginationMode='server'
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={pageSize => setPageSize(pageSize)}
                            rowReordering={false}
                            pagination={true}
                            sortingMode="server"
                            onColumnVisibilityModelChange={onChangeColumnVisibilityModel}
                            components={{
                                LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Toolbar: () => <ToolBar onRefresh={Refresh} />,
                            }}
                            onSortModelChange={handleSortModelChange}
                            initialState={
                                {pinnedColumns: {right: ['actions']}}
                            }
                            columnBuffer={3}
                            columnThreshold={3}/> : ''}
                    </StyledBox>
                </Grid>
            </Grid>

            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogTitle>确认删除</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        确定要删除{selectedRow?.name}吗？
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)}>否</Button>
                    <LoadingButton
                        loading={deleteLoading}
                        variant={"contained"}
                        onClick={() => {
                            setDeleteLoading(true);
                            handleDelete(selectedRow.id).then(result => {
                                    if (result.status === true) {
                                        // 操作成功，显示成功消息
                                        setSnackbar({ open: true, message: '删除成功！', severity: 'success' });
                                    } else {
                                        // 操作失败，显示错误消息
                                        setSnackbar({ open: true, message: '删除失败，请重试.', severity: 'error' });
                                    }
                                    setDeleteLoading(false);
                                    setOpenDeleteDialog(false);
                                }
                            ).catch((error) => {
                                console.error("删除失败.", error);
                            }).finally(() => {

                            })
                        }}
                    >是</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default FuelSurcharge;
export {FuelSurchargePage};