import {AxiosDefaultRequest} from "../../components/default_request";
import server from "../../server";
import {LOAD_CLAIM, SET_CURRENT, CLAIM, CLAIM_LOADING, CLAIM_EXPORT, CLAIM_EXPORT_SUCCESS} from "../type";
import store from "../store";
import {sort_order} from "../../components/utils";

export const loadClaim = (offset, limit, setPage = null, setPageSize = null,
                          options = null, reload = null, orders = null) => (dispatch, getState) => {
    /*
    * offset: 开始
    * limit: 结束
    * setPage: 页面条目数量
    * setPageSize: 设置页面条目数
    * options: 当前用于search存储
    * reload: 忘了
    * orders: 排序
    * */
    if (!store.getState().ClaimReducer.search) {
        dispatch({type: CLAIM_LOADING})
    } else {
        if (store.getState().ClaimReducer.search){
            if (store.getState().ClaimReducer.search.submitType === "export"){
                dispatch({type: CLAIM_EXPORT})
            } else {
                dispatch({type: CLAIM_LOADING})
            }
        }
    }
    let url = server.claim_url + "?offset=" + offset + "&limit=" + limit
    if (options) {
        for (let i in options) {
            if (options[i] !== '' && options[i] !== null) {
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    if (orders) {
        url += sort_order(orders);
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            if (store.getState().ClaimReducer.search) {
                if (store.getState().ClaimReducer.search.submitType === "export"){
                    dispatch({type: CLAIM_EXPORT_SUCCESS, payload: {
                        'request': true, 'level': 'success', 'title': 'SUCCESS',
                        'message': "The export is successful, please view/download in the admin interface"}})
                    return
                }
            }
            const result = {data: res.data.rows, rowCount: res.data.total, display: false};
            if (String(setPage) && setPage !== null) {
                result['page'] = setPage
            } else {
                result['page'] = store.getState().ClaimReducer.page;
            }
            if (String(setPageSize) && setPageSize !== null) {
                result['pageSize'] = setPageSize
            } else {
                result['pageSize'] = store.getState().ClaimReducer.pageSize;
            }
            dispatch({
                type: SET_CURRENT,
                payload: {current: CLAIM, searchTable: CLAIM, currentUrl: "/index/claim"}
            })
            dispatch({
                type: LOAD_CLAIM, payload: result
            })
        }
    })
}