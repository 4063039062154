import {AxiosDefaultRequest} from "../../components/default_request";
import server from "../../server";
import {
    SET_CURRENT,
    USER_MODAL_LOADING,
    SPOD_SUCCESS,
    SPOD_ERR,
    USER_TABLE_LOADING,
    USER, LOAD_USER,
} from "../type";
import store from "../store";
import {sort_order} from "../../components/utils";


export const LoadUserTable = (offset, limit, setPage = null, setPageSize = null, options = null, reload=null, orders=null) => (dispatch, getState) => {
    dispatch({type: USER_TABLE_LOADING})
    let url = server.record_url + "?offset=" + offset + "&limit=" + limit + "&userRecord=1"
    if (options) {
        for (let i in options) {
            if (options[i] !== '') {
                url = url + '&' + i + '=' + options[i]
            }
        }
    }
    if (orders){
        url += sort_order(orders);
    }
    AxiosDefaultRequest({
        url: url,
        method: 'GET',
        success: (res) => {
            const result = {data: res.data.rows, rowCount: res.data.total, isLoading: false, display: false};
            if (String(setPage) && setPage !== null) {
                result['page'] = setPage
            } else {
                result['page'] = store.getState().account.page;
            }
            if (String(setPageSize) && setPageSize !== null) {
                result['pageSize'] = setPageSize
            } else {
                result['pageSize'] = store.getState().account.pageSize;
            }
            dispatch({type: SET_CURRENT, payload: {current: USER, searchTable: USER, currentUrl: "/index/user"}})
            dispatch({type: LOAD_USER, payload: result})
        }
    })
}


export const getPod = (Supplier, TrackingNumbers) => (dispatch, getState) => {
    dispatch({type: USER_MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.get_pod_url,
        method: 'POST',
        // headers: {'WEB-USER': store.getState().auth.user.username},
        data: {
            // 'file': file_data,
            // 'file_name': file_name,
            'MID': store.getState().auth.user.UID,
            'Supplier': Supplier,
            TrackingNumbers: TrackingNumbers,
            // 'warehouse': warehouse
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: SPOD_SUCCESS, payload: result})
            } else {
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: SPOD_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: SPOD_ERR, payload: result})
        }
    })
}

export const batchQueryTraceNumbers = (TrackingNumbers) => (dispatch, getState) => {
    dispatch({type: USER_MODAL_LOADING})
    AxiosDefaultRequest({
        url: server.batch_query_trace_numbers_list,
        method: 'POST',
        data: {
            // 'file': file_data,
            // 'file_name': file_name,
            'MID': store.getState().auth.user.UID,
            // 'Supplier': Supplier,
            TrackingNumbers,
        }, success: res => {
            let result = res.data;
            if (result.status) {
                result['level'] = 'success'
                result['title'] = 'SUCCESS'
                result['message'] = result['message']
                dispatch({type: SPOD_SUCCESS, payload: result})
            } else {
                result['level'] = 'error'
                result['title'] = 'FAILED'
                result['message'] = result['message']
                dispatch({type: SPOD_ERR, payload: result})
            }

        }, err: res => {
            let result = {
                'level': 'error',
                'title': 'FAILED',
                'message': 'Failed to upload the bill, please check the data for errors and resubmit!'
            }
            dispatch({type: SPOD_ERR, payload: result})
        }
    })
}