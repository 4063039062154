import {
    DELETE_RECORD_SUCCESS,
    DELETE_RECORD_ERR,
    LOAD_OFFER_SHEET,
    OFFER_SHEET_LOADING,
    OFFER_SHEET_TABLE_LOADING,
    OFFER_SHEET_MODAL_LOADING,
    OFFER_SHEET_CLOSE_ALERT,
    OFFER_SHEET_STOP,
    OFFER_SHEET_ORDERS,
    FEDEX_GRD_AD,
    FEDEX_HOME_AD,
    FEDEX_MWT_AD,
    FEDEX_SMART_AD,
    UPS_GRD_AD, UPDATE_SURCHARGE_SUCCESS, UPDATE_SURCHARGE_ERR
} from "../type";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";

const initialState = {
    isLoading: false,
    TableLoading: false,
    ModalLoading: false,
    pageLoading: false,
    alert: false,
    pageSize: 30,
    page: 0,
    rowCount: 0,
    request: false,
    search: null,
    searchClick: false,
    stop: true,

    content: null,
    data: [],
    orderClick: false,
    orders: [],
    MODAL_STATUS: 'FEDEX_GRD_AD'
};


export default function (preState = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        // case LOAD_OFFER_SHEET:
        //     const rows : GridRowsProp = payload.data
        //     return {
        //         ...preState,
        //         data: rows,
        //         rowCount: payload.rowCount,
        //         TableLoading: false,
        //         page: payload.page || preState.page,
        //         pageSize: payload.pageSize || preState.pageSize,
        //         request: false,
        //     }
        // case OFFER_SHEET_LOADING:
        //     return {
        //         ...preState,
        //         pageLoading: true,
        //         request: false,
        //         content: null,
        //     }
        // case OFFER_SHEET_TABLE_LOADING:
        //     return {
        //         ...preState,
        //         TableLoading: true,
        //         request: false,
        //     }
        // case OFFER_SHEET_MODAL_LOADING:
        //     return {
        //         ...preState,
        //         ModalLoading: true,
        //         request: false,
        //         content: null,
        //     }
        // case OFFER_SHEET_CLOSE_ALERT:
        //     return {
        //         ...preState,
        //         alert: false
        //     }
        // case OFFER_SHEET_STOP:
        //     return {
        //         ...preState,
        //         stop: false,
        //         searchClick: false,
        //         orderClick: false
        //     }
        // case OFFER_SHEET_ORDERS:
        //     return {
        //         ...preState,
        //         orderClick: true,
        //         orders: payload
        //     }
        // case UPDATE_SURCHARGE_SUCCESS:
        // case UPDATE_SURCHARGE_ERR:
        //     return {
        //         ...preState,
        //         pageLoading: false,
        //         request: true,
        //         alert: true,
        //         content: payload
        //     }
        // case FEDEX_GRD_AD:
        // case FEDEX_HOME_AD:
        // case FEDEX_MWT_AD:
        // case FEDEX_SMART_AD:
        // case UPS_GRD_AD:
        //     return {
        //         ...preState,
        //         MODAL_STATUS: type
        //
        //     }
        default:
            return preState

    }
}