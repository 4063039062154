import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import {connect} from "react-redux";
import {replenish} from "../../redux/actions/modal";
import store from "../../redux/store";
import {MODAL_CLOSE_ALERT} from "../../redux/type";
import InputAdornment from "@mui/material/InputAdornment";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

class Replenish extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            model: 1,
            replenish_file: null
        }
    }

    Close = () => {
        this.setState({open: false})
    }
    Open = () => {
        this.setState({open: true})
    }
    handleSubmit = e => {
        e.preventDefault()
        const data = new FormData(e.currentTarget);
        this.props.replenish(this.state.replenish_file, data.get('replenish_file').name, this.state.model)
    }
    closeAlert = () => {
        store.dispatch({type: MODAL_CLOSE_ALERT})
    }
    ChangeFile = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            this.setState({replenish_file: event.target.result})
        };
    }

    render() {
        return (
            <span>
                <Button sx={{textTransform: 'none'}} startIcon={<ArrowDownwardIcon/>} onClick={() => {
                    this.Open()
                }}>补充SP追踪号</Button>
                <Dialog disableEscapeKeyDown open={this.state.open} onClose={() => {
                    this.Close()
                }}>
                        <DialogTitle>Replenish FEDEX</DialogTitle>
                        <DialogContent>
                            <Box component="form" onSubmit={this.handleSubmit} sx={{display: 'flex', flexWrap: 'wrap'}}>
                                <FormControl variant="standard" sx={{m: 1, minWidth: 150}}>
                                        <InputLabel id="model">查询方式</InputLabel>
                                        <Select
                                            labelId="model"
                                            id="select_ser"
                                            value={this.state.model}
                                            onChange={(e) => {
                                                this.setState({model: e.target.value})
                                            }}
                                            label="logisticService"
                                        >
                                            <MenuItem key={1} value={1}>通过 FEDEX 查询 USPS </MenuItem>
                                            <MenuItem key={2} value={2}>通过 USPS 查询 FEDEX </MenuItem>
                                        </Select>
                                    </FormControl>
                                <FormControl sx={{m: 1, minWidth: 500, minHeight: 500}}>
                                    <TextField
                                        id="replenish_file"
                                        name="replenish_file"
                                        type='file'
                                        label="Please upload the txt file that needs to get fedex tracking number"
                                        onChange={(e) => {
                                            this.ChangeFile(e)
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <FileUploadIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                    />
                                </FormControl>

                                <DialogActions>
                                    <Button fullWidth variant="contained" sx={{mt: 3, mb: 2, ml: 45}} onClick={() => {
                                        this.Close()
                                    }}>Cancel</Button>
                                    <Button fullWidth type='submit' variant="contained"
                                            sx={{mt: 3, mb: 2}}>Submit</Button>
                                </DialogActions>
                            </Box>
                        </DialogContent>
                    </Dialog>
            </span>
        );
    }
}

// 更新就渲染
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, {replenish})(Replenish);