import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

/*
'inherit'
'primary'
'secondary'
'error'
'info'
'success'
'warning'
string
* */
export default (color) => {
    // request mask
    return (
        <Box sx={{
            paddingLeft: window.innerWidth / 2 - 20 + "px",
            paddingTop: window.innerHeight / 2 - 150 + "px",
            backgroundColor: `rgba(173, 173, 173, 0.5)`,
            top: 0,
            left: 0,
            position: "absolute",
            zIndex: 9999,
            width: `100%`,
            height: `100%`

        }}>
            {/*<LinearProgress color="success"/>*/}

            <CircularProgress sx={{left: 100}} color={color}/>
            <Box
                sx={{
                    top: 0,
                    left: 10,
                    bottom: 170,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '16px'
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary" fontSize={16}>
                    Loading...
                </Typography>
            </Box>
        </Box>
    );
}