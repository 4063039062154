import {
    LOAD_SHIPMENT,
    SHIPMENT_LOADING,
    SHIPMENT_MODAL_LOADING,
    SHIPMENT_ORDERS,
    SHIPMENT_SEARCH,
    SHIPMENT_STOP,
    SHIPMENT_SEARCH_UPDATE,
    SHIPMENT_PREVIEW_SUCCESS,
    SHIPMENT_PREVIEW_ERR,
    PREVIEW_SHIPMENT_OPEN,
    SHIPMENT_EXPORT,
    SHIPMENT_EXPORT_SUCCESS,
    SHIPMENT_CLOSE_ALERT,
    SHIPMENT_SEARCH_CLEAR,
} from "../type";
import {GridRowsProp} from "@mui/x-data-grid/models/gridRows";

const initialState = {
    isLoading: false,  // table use
    TableLoading: false,  // 页面表格加载
    ModalLoading: false,  // modal use
    pageSize: 30,
    page: 0,
    rowCount: 0,
    display: true,
    search: null,
    searchClick: false,
    data: [],
    stop: true,
    orderClick: false,
    orders: [],
    alert: false,

    // 功能性模块参数
    preview_pdf_url: "",
    preview_modal_open: false,


};

export default function (preState=initialState, action) {
    const {type, payload} = action
    switch (type) {
        case LOAD_SHIPMENT:
            const rows : GridRowsProp = payload.data
            return {
                ...preState,
                data: rows,
                rowCount: payload.rowCount,
                TableLoading: false,
                page: payload.page,
                pageSize: payload.pageSize,
                with_holding: payload.with_holding,
                refund: payload.refund,
                lump_sum: payload.lump_sum
            }
        case SHIPMENT_LOADING:
            return {
                ...preState,
                TableLoading: true,
                data: []
            }
        case SHIPMENT_MODAL_LOADING:
            return {
                ...preState,
                ModalLoading: true,
                // request: false,
                content: null,
            }
        case SHIPMENT_STOP:
            return {
                ...preState,
                stop: false,
                searchClick: false,
                orderClick: false
            }
        case SHIPMENT_ORDERS:
            return {
                ...preState,
                orderClick: true,
                orders: payload
            }
        case SHIPMENT_SEARCH:
            return {
                ...preState,
                page: 0,
                pageSize: 30,
                stop: true,
                searchClick: true
            }
        case SHIPMENT_SEARCH_CLEAR:
            return {
                ...preState,
                search: []
            }
        case SHIPMENT_SEARCH_UPDATE:
            let search;
            if (!preState.search) {
                search = payload;
            } else {
                search = preState.search;
                for (let i in payload){
                    search[i] = payload[i]
                }
            }
            return {
                ...preState,
                search: search,
                searchClick: false
            }
        case SHIPMENT_PREVIEW_SUCCESS:
            return {
                ...preState,
                ModalLoading: false,
                preview_pdf_url: payload['preview_pdf_url'],
                preview_modal_open: !preState.preview_modal_open
            }
        case SHIPMENT_PREVIEW_ERR:
            return {
                ...preState,
                isLoading: false,
                preview_pdf_url: null,
            }
        case PREVIEW_SHIPMENT_OPEN:
            return {
                ...preState,
                preview_modal_open: !preState.preview_modal_open
            }
        case SHIPMENT_EXPORT:
            return {
                ...preState,
                isLoading: true
            }
        case SHIPMENT_EXPORT_SUCCESS:
            return {
                ...preState,
                isLoading: false,
                alert: true,
                content: payload,
                search: {
                    ...preState.search,
                    submitType: ""
                }
            }
        case SHIPMENT_CLOSE_ALERT:
            return {
                ...preState,
                alert: false
            }
        default:
            return preState
    }
}