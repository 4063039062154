import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Snackbar} from '@mui/material';
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import {useDispatch} from "react-redux";
import {RenderSelectField} from "../../../../components/renderField";

const EditFormDialog = ({ open, onClose, rowData, onSave }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        user: 2,
        service: '',
        is_permit: false,
        id: 0,
        name: '',
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });
    const [user_options, setUserOptions] = useState([])
    const dispatch = useDispatch();
    const [service_options, setServiceOptions] = useState([])
    // 当rowData改变时，更新表单数据
    useEffect(() => {
        if (rowData) {
            let row_data = {}
            for (let key in formData) {
                let value = rowData[key];

                if (key === "service" || key === "user"){
                    row_data[key] = value["id"]
                }

                else if(key === "is_permit" && typeof value !== 'boolean'){
                    row_data[key] = Boolean(value)
                }
                else {

                    row_data[key] = value
                }


            }
            setFormData(row_data);
        }
    }, [rowData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if(name === "is_permit" && typeof value !== 'boolean'){
            setFormData((prev) => ({
                ...prev,
                [name]: Boolean(value),
            }));
        }
        else {

            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));


            console.log(formData.user, typeof formData.user)
        }

    };
    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.account}?offset=0&limit=100&all=true`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, username: rows[i].username})
                        }
                        setUserOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchServiceData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_service_list}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setServiceOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        fetchData();
        fetchServiceData();
    }, [dispatch]);
    const handleSave = async () => {
        setLoading(true);
        try {
            let form_data = JSON.parse(JSON.stringify(formData));
            delete form_data.id;
            const result = await onSave(form_data, formData.id, 'PUT');
            if (result.status === true) {
                // 操作成功，显示成功消息
                // setSnackbar({ open: true, message: '保存成功！', severity: 'success' });
            }
        } catch (error) {
            console.error("保存失败", error);
            // 操作失败，显示错误消息
            // setSnackbar({ open: true, message: '保存失败，请重试。', severity: 'error' });
        } finally {
            // setLoading(false);
            onClose(); // 保存操作完成后关闭对话框
        }
    };
    const handleFieldChange = (event, value, key, value_key) => {
        setFormData((prev) => ({
            ...prev,
            [key]: value?value[value_key]:'',
        }));

    };
    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>编辑信息</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="name"
                                label="服务名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                idAname="user"
                                label="用户"
                                value={formData.user}
                                select_options={user_options}
                                v="id"
                                value_name="username"
                                onChangeHandler={(e, value) => handleFieldChange(e, value, "user", "id")}
                                sx={{width: 360}}
                                required
                            />

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                idAname="service"
                                label="服务"
                                value={formData.service}
                                select_options={service_options}
                                v="id"
                                value_name="name"
                                onChangeHandler={(e, value) => handleFieldChange(e, value, "service", "id")}
                                sx={{width: 360}}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="username"
                                label="用户名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.username}
                                onChange={handleChange}
                                required
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="is_permit"
                                label="是否填入发运仓"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.is_permit}
                                onChange={handleChange}
                            >
                                <MenuItem key="is_specific_true" value={true}>
                                    是
                                </MenuItem>
                                <MenuItem key="is_specific_false" value={false}>
                                    否
                                </MenuItem>

                            </TextField>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>
                    <Button loading={loading} onClick={handleSave} variant={"contained"}>保存</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default EditFormDialog;
