import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar} from '@mui/material';

import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Alert from "@mui/material/Alert";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import {useDispatch} from "react-redux";
import {RenderSelectField} from "../../../../components/renderField";

const CreateServerAndUser = ({onSave}) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [user_options, setUserOptions] = useState([])
    const [service_options, setServiceOptions] = useState([])
    const [formData, setFormData] = useState({
        user: '',
        service: '',
        name: '',

    });
    const [error, SetError] = useState({
        user: false,
        name: false,
    })
    const onClose = () => setOpenCreateDialog(false);
    const onOpen = () => setOpenCreateDialog(true);
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.user_url}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, username: rows[i].username})
                        }
                        setUserOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        const fetchServiceData = async () => {
            try {
                await AxiosSyncDefaultRequest({
                    url: `${server.logistics_service_list}?page=0&size=100`,
                    method: 'GET',
                    success: (res) => {
                        let options = []
                        let rows = res.data.rows
                        for (const i in rows) {
                            options.push({id: rows[i].id, name: rows[i].name})
                        }
                        setServiceOptions(options)
                    },
                    // fail: (error) => {
                    //     console.error('请求失败', error);
                    //     setData([])
                    //     setLoading(false)
                    // }
                });
            } catch (error) {
                console.error('请求异常', error);
            }
        };
        fetchData();
        fetchServiceData();
    }, [dispatch]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

    };

    const handleSave = async () => {
        // Object.entries(formData).forEach(([key, value]) => {
        //     if (value === false || value === '') {
        //         delete data[key]
        //     }
        // });
        SetError(
            {
                user: false,
                name: false,
            }
        )
        if (formData.user && formData.name) {
            setLoading(true);
            try {
                const result = await onSave(formData, formData.id, 'POST');
                if (result.status === true) {
                    // 操作成功，显示成功消息
                    // setSnackbar({open: true, message: '保存成功！', severity: 'success'});
                    setFormData({
                        user: '',
                        service: '',
                        name: '',
                    })
                }
            } catch (error) {
                console.error("保存失败", error);
                // 操作失败，显示错误消息
                // setSnackbar({ open: true, message: '保存失败,请重试.', severity: 'error' });
            } finally {
                setLoading(false);
                onClose(); // 保存操作完成后关闭对话框
            }
        } else {
            for(let key in error){
                if(!formData[key]){
                    SetError((prev) => ({
                        ...prev,
                        [key]: true,
                    }));
                }
            }
        }

    };
    const handleFieldChange = (e, value, key, value_name) => {
        setFormData({...formData, [key]: value?value[value_name]:''})
    }
    return (
        <>

            <Button sx={{textTransform: 'none'}} startIcon={<ManageAccountsIcon/>} onClick={() => {
                onOpen()
            }}>
                新增服务和用户管理
            </Button>
            <Dialog open={openCreateDialog} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle> 新增服务和用户管理</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="name"
                                label="服务名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                error={error.name}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                idAname="user"
                                label="用户"
                                value={formData.user}
                                onChangeHandler={(e, value) => handleFieldChange(e, value, "user", "id")}
                                select_options={user_options}
                                v={"id"}
                                value_name={"username"}
                                sx={{width: 365}}
                                required
                                error={error.user}
                            />

                        </Grid>
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                idAname="service"
                                label="服务"
                                value={formData.service}
                                onChangeHandler={(e, value) => handleFieldChange(e, value, "service", "id")}
                                select_options={service_options}
                                v={"id"}
                                value_name={"name"}
                                sx={{width: 365}}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant={"contained"}>取消</Button>
                    <Button loading={loading} onClick={handleSave}>保存</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({...snackbar, open: false})}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                       sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>

    );
};

export default CreateServerAndUser;
