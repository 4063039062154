import React, {useState, useEffect} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar} from '@mui/material';

import Grid from "@mui/material/Grid";
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";

import {useDispatch} from "react-redux";
import {node} from "prop-types";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';


import Input from '@mui/material/Input';
import {
    RenderButtonField
} from "../../../../components/renderField";
import Box from "@mui/material/Box";

const CreateAccount = ({onSave}) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        APIkey: '',
        UID: '',
        password: ''


    });
    const [username_error, SetUserError] = useState(false);
    const [api_key_error, SetKeyError] = useState(false);
    const [uid_error, SetUidError] = useState(false)
    const [pwd_error, SetPwdError] = useState(false)
    const onClose = () => setOpenCreateDialog(false);
    const onOpen = () => setOpenCreateDialog(true);
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const handleClickShowPassword = () => setShow((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    useEffect(() => {
        // setLoading(true)

    }, [dispatch]);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success', // 可以是 'error', 'warning', 'info', 'success'
    });

    const handleChange = (event) => {
        const {name, value} = event.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSave = async () => {

        // let data = formData
        // Object.entries(formData).forEach(([key, value]) => {
        //     if (value === false || value === '') {
        //         delete data[key]
        //     }
        // });
        let data = JSON.parse(JSON.stringify(formData));
        SetPwdError(false)
        SetUidError(false)
        SetKeyError(false)
        SetUserError(false)
        if (formData.UID && formData.APIkey && formData.username && formData.password) {

            setLoading(true)
            if((data["APIkey"]) === ''){
                delete data["APIkey"]
            }
            try {
                const result = await onSave(data, formData.id, 'POST');
                if (result.status === true) {
                    // 操作成功，显示成功消息
                    setLoading(false)
                    onClose()
                }
            } catch (error) {
                console.error("保存失败", error);
                setLoading(false)
                onClose()
                // 操作失败，显示错误消息
                // setSnackbar({ open: true, message: '保存失败,请重试.', severity: 'error' });
            } finally {
                setLoading(false);
                // onClose(); // 保存操作完成后关闭对话框
            }
        } else {
            if (!formData.password || formData.password.length === 0){
                SetPwdError(true)
            }
            if(!formData.UID || formData.UID.length === 0){
                SetUidError(true)
            }
            if(formData.APIkey.length === 0){
                SetKeyError(true)
            }
            if (!formData.username || formData.username.length === 0){
                SetUserError(true)
            }
        }

    };
    return (
        <>

            <Button sx={{textTransform: 'none'}} startIcon={<AddCircleOutlineIcon/>} onClick={() => {
                onOpen()
            }}>
                新增账户
            </Button>
            <Dialog open={openCreateDialog} onClose={onClose} maxWidth="sm">
                <DialogTitle>新建账户</DialogTitle>
                <DialogContent >
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>

                            <TextField
                                margin="dense"
                                name="username"
                                id="filled-adornment-password"
                                label="username"
                                // type={show ? 'text' : 'password'}
                                fullWidth
                                variant="standard"
                                value={formData.username}
                                onChange={handleChange}
                                error={username_error}
                                // sx={{width: '40ch' }}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>

                            <TextField
                                margin="dense"
                                name="APIkey"
                                id="filled-adornment-password"
                                label="APIkey"
                                // type={show ? 'text' : 'password'}
                                fullWidth
                                variant="standard"
                                value={formData.APIkey}
                                onChange={handleChange}
                                error={api_key_error}

                                helperText="请输入！"
                                // sx={{width: '40ch' }}
                                required
                            />

                        </Grid>
                        <Grid item xs={12} md={8}>

                            <TextField
                                margin="dense"
                                name="UID"
                                id="filled-adornment-password"
                                label="UID"
                                type="number"
                                // type={show ? 'text' : 'password'}
                                fullWidth
                                variant="standard"
                                value={formData.UID}
                                helperText="请填写一个UID！"
                                onChange={handleChange}
                                error={uid_error}
                                // sx={{width: '40ch' }}
                                required
                            />

                        </Grid>
                        <Grid item xs={12} md={8}>

                            {/*<TextField*/}
                            {/*    margin="dense"*/}
                            {/*    name="password"*/}
                            {/*    id="filled-adornment-password"*/}
                            {/*    label="密码"*/}
                            {/*    // type={show ? 'text' : 'password'}*/}
                            {/*    fullWidth*/}
                            {/*    variant="standard"*/}
                            {/*    value={formData.password}*/}
                            {/*    onChange={handleChange}*/}
                            {/*    // sx={{width: '40ch' }}*/}
                            {/*    required*/}
                            {/*/>*/}

                            <FormControl md={8} sx={{width: "41ch"}} variant="standard">
                                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type={show ? 'text' : 'password'}
                                    // value={formData.password}
                                    name="password"
                                    onChange={handleChange}
                                    onInput={handleChange}
                                    required
                                    error={pwd_error}
                                    value={formData.password}

                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {show ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>

                            {/*<IconButton*/}
                            {/*    aria-label="toggle password visibility"*/}
                            {/*    onClick={handleClickShowPassword}*/}
                            {/*    onMouseDown={handleMouseDownPassword}*/}
                            {/*    edge="end"*/}
                            {/*    sx={{top: '1ch'}}*/}
                            {/*>*/}
                            {/*    {show ? <VisibilityOff /> : <Visibility />}*/}
                            {/*</IconButton>*/}

                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>
                    <RenderButtonField loading={loading} onClick={handleSave} text="保存" variant="contained"/>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar({...snackbar, open: false})}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}
                       sx={{width: '100%'}}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>

    );
};

export default CreateAccount;
