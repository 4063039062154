// GridActionsCellItem.js
import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import Tooltip from "@mui/material/Tooltip";
import {DataGridPro} from "@mui/x-data-grid-pro";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


function RateDetails({isOpen, setOpen, details, disabled, is_freight=false}) {
    const [data, setData] = useState(JSON.parse(details));
    const [pageSize, setPageSize] = useState(30);
    const parseDataForTable = () => {
        const tableData = [];
        let total_ship_cost = 0.0;
        let total_surcharge_amount = 0.0;
        let total_no_surcharge_amount = 0.0;
        Object.entries(data).forEach(([key, value]) => {
            if (key === "mtw_dx"){
                if (data['is_mwt'] === true) {
                    tableData.push({ id: key, key: "mwt 低消", subKey: null, value: `${value}` });
                }
            }
            else if (key === "is_mwt" ){
            }
            else if (key === 'zone') {
                // 当key为fuel时，在value后添加"%"
                tableData.push({ id: key, key: "分区", subKey: null, value: `${value}` });
            }
            else if (key === 'BW') {
                // 当key为fuel时，在value后添加"%"
                tableData.push({ id: key, key: "总计费重", subKey: null, value: `${value}` });
            }
            else if (key === 'jp') {
                // 当key为fuel时，在value后添加"%"
                tableData.push({ id: key, key: "计抛", subKey: null, value: `${value}` });
            }
            else if (key === 'fuel') {
                // 当key为fuel时，在value后添加"%"
                tableData.push({ id: key, key: "燃油(%)", subKey: null, value: `${value}%` });
            } else if (typeof value === 'object' && (key === 'surcharges' || key === 'no_fuel_surcharges')) {
                let isFirstSurcharge = true; // 用来标记是否为第一个surcharge条目
                tableData.push({ id: `${key}`, key: key === "surcharges" ? "附加费" : "不计燃油附加费", subKey: null, value: null });
                if (key === "no_fuel_surcharges") {
                    let hasDeclaredValue = Object.keys(data['surcharges']).includes("Declared Value");
                    if (hasDeclaredValue) {
                        tableData.push({ id: `${key}-Declared Value`, key: '', subKey: "Declared Value", value: data['surcharges']['Declared Value'] });
                        total_no_surcharge_amount += Number(data['surcharges']['Declared Value']);
                    }else {
                        if(is_freight) {
                            Object.entries(data["no_fuel_surcharges"]).forEach(([k, value]) => {
                                if (k !== "shipping_fee") {
                                    tableData.push({id: `${key}-${k}`, key: '', subKey: k, value: value})
                                    total_no_surcharge_amount += Number(value)
                                }

                            })
                        }
                    }
                }else{
                    Object.entries(value).forEach(([subKey, subValue]) => {
                        // 对每个surcharge条目，新加一行给subKey
                        if (subKey === "Fuel Surcharge") {

                        } else if (subKey === "Declared Value") {

                        }
                        else{
                            tableData.push({ id: `${key}-${subKey}`, key: '', subKey, value: subValue });
                            total_surcharge_amount += Number(subValue);
                        }
                        if (isFirstSurcharge) {
                            isFirstSurcharge = false;
                        }
                    });
                }

            } else if (key === "total_amount") {
                tableData.push({
                    id: key, key: "总预扣",
                    subKey: `公式: (${total_ship_cost}+${total_surcharge_amount.toFixed(2)})*(${data['fuel']}/100+1)+${total_no_surcharge_amount.toFixed(2)}`, value });
            } else if (key === "ship_cost") {
                // 这里需要补充一条公式展示 公式为 (ship_cost+surcharges sum)*fuel + no_fuel_surcharges sum
                tableData.push({ id: key, key: "总运费", subKey: null, value: parseFloat(Number(value).toFixed(2)) });
                total_ship_cost = parseFloat(Number(value).toFixed(2));
            } else {
                tableData.push({ id: key, key, subKey: null, value });
            }
        });
        return tableData;
    };
    const rows = parseDataForTable();
    const columns = [
        { field: 'key', headerName: '详细信息', flex: 20, resizable: true },
        { field: 'subKey', headerName: '附加费条目', flex: 35, resizable: true },
        { field: 'value', headerName: '值(USD)', flex: 50, resizable: true },
    ];
    return (
        <div>
            <Tooltip title={"rate details"}>
                <IconButton
                    onClick={() => setOpen(!isOpen)}
                    aria-label="rateDetails"
                    disabled={disabled}
                    color="primary"
                >
                    <FormatAlignJustifyIcon />
                </IconButton>
            </Tooltip>

            <Dialog
                fullWidth={true}
                maxWidth={'lg'} // 保持这个设置以确保内容宽度的弹性
                style={{ width: '80vw', margin: '0 auto' }} // 设置宽度为视窗宽度的80%，并居中显示
                disableEscapeKeyDown
                open={isOpen}
                onClose={() => {setOpen(false)}}
            >
                <Container style={{ height: '70vh' }}> {/* 使用视窗高度的百分比 */}
                    <Grid container spacing={3} style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ height: '100%' }}>
                            <DataGridPro
                                rows={rows}
                                density="compact"
                                columns={columns}
                                pageSize={30}
                                rowsPerPageOptions={[30, 60, 100, 200]}
                                checkboxSelection={false}
                                style={{ height: '100%', width: '100%' }} // 确保DataGrid充满容器
                                onCellClick={(row, e) => e.stopPropagation()}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        </div>
    );
}

export default RateDetails