import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import '../styles.scss'
import Paper from "@mui/material/Paper";
import {RenderButtonField, RenderCloseField, RenderTextField} from "../../components/renderField";
import {useState} from "react";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";

export default function ExportWarehouse({handleSearch, searchData, SetSearchData}) {
    // const [searchObj, setSearchObj] = useState({name: '', address1: '', address2: '', city: '', postal_code: '', state: ''
    // })
    const [searchOpen, setSearchOpen] = useState(true);
    const handleChange = (e, key, value) => {
        SetSearchData(prevState => ({
            ...prevState,
            [key]: value
        }));
    }

    // function handleSubmit() {
    //     if (searchObj.user || searchObj.supplier || searchObj.account_user || searchObj.region) {
    //         console.log("aaa")
    //     } else {
    //         console.log("bbb")
    //     }
    // }
    function Reset(e) {
        SetSearchData(prevState => ({
            ...prevState,
            name: '', address1: '', address2: '', city: '', postal_code: '', state: ''
        }));
    }
    const toggleSearchOpen = () => {
        setSearchOpen(!searchOpen);
    };
    return (
        <span>
            {!searchOpen && (
                <Button
                    sx={{ textTransform: 'none' }}
                    startIcon={<SearchIcon />}
                    onClick={() => toggleSearchOpen()}
                    variant="text">
                    搜索
                </Button>
            )}
            {searchOpen && (
            <Box component="form" noValidate>
                <Paper sx={{p: 1}}>
                <Grid container style={{marginBottom: "1%"}}>
            {/*{Object.entries(searchObj).map(([key, value]) => (*/}

                    {RenderTextField({
                        idAname: "name", label: "仓库名",
                        value: searchData.name,
                        onChangeHandler: (e) => {
                            handleChange(e, "name", e.target.value)
                        }
                    })}

                    {RenderTextField({
                        idAname: "address1", label: "地址1",
                        value: searchData.address1,
                        onChangeHandler: (e) => {
                            handleChange(e, "address1", e.target.value)
                        }
                    })}
                    {RenderTextField({
                        idAname: "address2", label: "地址2",
                        value: searchData.address2,
                        onChangeHandler: (e) => {
                            handleChange(e, "address2", e.target.value)
                        }
                    })}

                    {RenderTextField({
                        idAname: "city", label: "城市",
                        value: searchData.city,
                        onChangeHandler: (e) => {
                            handleChange(e, "city", e.target.value)
                        }
                    })}
                    {RenderTextField({
                        idAname: "postal_code", label: "邮编",
                        value: searchData.postal_code,
                        onChangeHandler: (e) => {
                            handleChange(e, "postal_code", e.target.value)
                        }
                    })}
                    {RenderTextField({
                        idAname: "state", label: "州/省简称",
                        value: searchData.state,
                        onChangeHandler: (e) => {
                            handleChange(e, "state", e.target.value)
                        }
                    })}

                    <Grid container style={{paddingTop: "1%"}}>
                    {RenderButtonField({
                        idAname: "Search",
                        variant: "contained",
                        text: "查询",
                        // buttonType: "submit",
                        tip: "条件查询",
                        onClickHandler: (e) => {
                            handleSearch(e)
                        }
                    })}
                        {RenderButtonField({
                            idAname: "Reset",
                            variant: "outlined",
                            text: "清空",
                            // buttonType: "submit",
                            tip: "Reset",
                            onClickHandler: (e) => {
                                Reset(e)
                            }
                        })}
                        <RenderCloseField onClickHandler={() => setSearchOpen(false)} />
                    </Grid>
                    {/*</>*/}

                    {/*))}*/}
                    </Grid>
                    </Paper>
            </Box>
            )}
        </span>
    );
}
